<script>
const bookingQuoteFlights = importVueComp('components/booking', 'BookingQuoteFlights', 'custom');
export default {
    name: 'custom-booking-quote-flights',
    mixins: [bookingQuoteFlights],
    methods: {
        getFlightRoute (flight) {
            return flight.Output;
        },
    }
}
</script>

