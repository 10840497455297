<template>
    <div>
        <booking-quote-config></booking-quote-config>
        <div class="form-row form-material ml-2">
            <div class="form-row col-md-2">
                <div class="col-md-12 px-4">
                    <number-input
                        :v.sync="recordStore.Tax"
                        label="Tax"
                        d="0"
                        :disabled="!salesActions || confirmed"
                        @change="changeTax"
                    ></number-input>
                </div>
            </div>
            <div class="form-check col-md-2 px-4 mt-3 checkbox-group pl-2">
                <div class="col-12">
                    <input v-model="record.HideRoomType" class="form-check-input" type="checkbox"
                    @change="change('HideRoomType')"
                     id="HideRoomType">
                    <label for="HideRoomType" class="smaller form-check-label">{{tr('Hide Room Type')}}</label>
                </div>
            </div>
            <div class="form-row col-md-2">
                <button type="button" class="btn btn-primary btn-sm my-3" @click="changeMarkup">{{tr('Change Markup')}}</button>
            </div>
            <div class="form-row col-md-2">
                <button type="button" class="btn btn-primary btn-sm my-3" @click="setFinalTotal">{{tr('Set Price')}}</button>
            </div>
            <!--div class="form-row col-md-2">
                <button type="button" class="btn btn-primary btn-sm my-3" @click="setFinalTotal2">{{tr('Set Price')}}</button>
            </div-->
            <!--div class="form-row col-md-2">
                <number-input
                    :v.sync="recordStore.FullRound"
                    label="Full Number Order"
                    d="0"
                    @change="setFullNumber"
                ></number-input>
            </div-->

        </div>
        <change-markup
            v-if="showMkp"
            :show.sync="showMkp"
            @save="updateMarkup"
        ></change-markup>
        <change-total-mkp
            v-if="setTotal"
            :show.sync="setTotal"
        ></change-total-mkp>
        <change-total-value
            v-if="setTotal2"
            :show.sync="setTotal2"
        ></change-total-value>
    </div>
</template>

<script>
const bookingQuoteConfig = importVueComp('components/booking', 'BookingQuoteConfig', 'custom');
const changeMkp = importVueComp('components/tools', 'ChangeMarkup');
const changeTotalMkp = importVueComp('components/tools', 'ChangeTotalMkp');
const changeTotalValue = importVueComp('components/tools', 'ChangeTotalValue');
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'custom-booking-quote-config',
    mixins: [bookingQuoteConfig],
    data () {
        return {
            showMkp: false,
            setTotal: false,
            setTotal2: false,
        }
    },
    components: {
        'booking-quote-config': bookingQuoteConfig,
        'change-markup': changeMkp,
        'change-total-mkp': changeTotalMkp,
        'change-total-value': changeTotalValue,
    },
    methods: {
        changeMarkup () {
            this.showMkp = true;
        },
        changeTax () {
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        async setFullNumber () {
            await this.$nextTick();
            this.recordStore.setExpenses();
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        setFinalTotal () {
            this.setTotal = true;
        },
        setFinalTotal2 () {
            this.setTotal2 = true;
        },
        async updateMarkup (fromValue, toValue) {
            if (!fromValue) return;
            if (!toValue) return;
            for (let day of this.recordStore.BookingDays) {
                for (let s of day.BookingDayServices) {
                    if (s.Quote && s.Quote.QuoteList) {
                        for (let base in s.Quote.QuoteList.bases){
                            for (let pType of s.Quote.QuoteList[base].paxTypes) {
                                if (!s.Quote.QuoteList[base]) continue;
                                if (!s.Quote.QuoteList[base][pType]) continue;
                                if (s.Quote.QuoteList[base][pType].Markup != fromValue) continue;
                                await s.Quote.QuoteList[base][pType].setValue({fieldName: 'Markup', value: toValue});
                            }
                        }
                    }
                }
                if (day.BookingDayHotels[0]) {
                    for (let room of day.BookingDayHotels[0].BookingDayRooms) {
                        if (!room.QuoteList) continue;
                        for (let baseId in room.QuoteList.bases) {
                            if (!room.QuoteList[baseId]) continue;
                            if (room.QuoteList[baseId].Markup != fromValue) continue;
                            await room.QuoteList[baseId].setValue({fieldName: 'Markup', value: toValue});;
                        }
                    }
                }
            }
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        }
    }

}
</script>

