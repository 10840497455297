<template>
  <h2 class="entry-title mt-1 not-break-inside">
    <span v-if="recordStore.ShowDates">
        <span v-if="!day.GroupDays">{{getDatesText()}} - </span>
        <span v-else class="multi-dates">{{getDatesText()}} - </span>
    </span>
    <span> {{day.dayTitle}}</span>
  </h2>
</template>

<script>
const itineraryBodyDayTitle = importVueComp('components/itinerary', 'ItineraryBodyDayTitle', 'custom');
import { mapState } from "vuex";
export default {
    name: 'custom-itinerary-body-day-title',
    mixins: [itineraryBodyDayTitle]
}
</script>


