const modules = vueTools.importModule('modules', 'tools', 'arg').default;

if (modules.adminMenu) {
    modules.adminMenu.endpoints.push('province');
    modules.adminMenu.endpoints.push('retencionsetting');
    modules.adminMenu.endpoints.push({endpoint: 'report', label: 'Libro IVA Compras', componentName: 'VATPurchaseReport', report: 'vatpurchase'});
    modules.adminMenu.endpoints.push({endpoint: 'report', label: 'Retention Report', componentName: 'RetencionReport', report: 'retencionlist'},);
}

export default modules;
