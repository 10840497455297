<template>
    <report-window endpoint="/custom/reports/destination_list"
        v-if="ready"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
        :current.sync="current"
    ></report-window>
</template>


<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'destination-list-report',
    props: ['options'],
    components: {
        ReportWindow,
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            ready: false,
            current: {},
            fields: [
                { name: 'FromDate', editor: 'date', label: 'From', required: true},
                { name: 'ToDate', editor: 'date', label: 'To', required: true},
                { name: 'FilterDate', label: 'Filter Date by', editor: 'select', addBlank: true, required: true, defValue: '1',
                    options: [
                          {value: '0', label: 'Sales Date'},
                          {value: '1', label: 'Date Inn'},
                          {value: '2', label: 'Date Out'},
                      ]
                },
            ],
            title: 'Destination List',
            headers: {},
            templates: [
                {
                    name: "StartDate",
                    callback: (row) => {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "EndDate",
                    callback: (row) => {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                },
            ],
        }
    },
    methods: {
    }
}
</script>

