<script>
const bookingDayService = importVueComp('components/booking', 'DayService', 'custom');
export default {
    name: 'custom-day-service',
    mixins: [bookingDayService],
    mounted () {
        this.checkOptions.ExtraSeat = 'No Guide';
        this.checkOptions = Object.assign({}, this.checkOptions);
    }
}
</script>



