<template>
    <div class="modal fade bd-example-modal-lg" id="template-selector-modal" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="template-selector-modal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-large" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="form-row">
                <div class="form-group col-md-12 section-title mb-0">
                    <label class="ml-4">{{tr('Add Template')}}</label>
                </div>
            </div>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <template-selector @add="add" :language="language"></template-selector>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const templateSelector = importVueComp('components/service', 'TemplateSelector');
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'template-selector-modal',
    mixins: [escape],
    props: ['language'],
    components: {
      'template-selector': templateSelector
    },
    mounted () {
        $('#template-selector-modal').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        add (records){
            this.$emit('add', records)
            $('#template-selector-modal').modal('hide');
        },
        close () {
            $('#template-selector-modal').modal('hide');
            this.$emit('close');
        },
    },
}
</script>

