<script>
const bookingQuote = importVueComp('components/booking', 'BookingQuote', 'custom');
export default {
    name: 'custom-booking-quote',
    mixins: [bookingQuote],
    computed: {
        canRefreshQuote () {
            return tools.canAccess(this.user, "api", "GET", 'can_refresh_quote', false);
        },
    },
}
</script>
