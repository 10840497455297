<template>
  <div class="modal fade" id="change-total-mkp" tabindex="-1" role="dialog" aria-labelledby="change-total-mkp"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content form-material">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Set Price')}}</h5>
        </div>
        <div class="modal-body form-group form-default form-row px-4">
            <table class="table table-striped  table-sm table-bordered" v-if="ready">
                <thead>
                    <tr class="table-secondary">
                        <th><span>{{tr('Quotation')}}</span></th>
                        <th><span>{{tr('Current Price')}}</span></th>
                        <th><span>{{tr('New Price')}}</span></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row of values">
                        <td v-if="row.room">{{row.room.pax}} {{row.pType}} {{row.room.Room.Name}}</td>
                        <td v-else>{{row.pType}}</td>
                        <td v-if="row.room">{{getTotalInCurrency(row.room.Price) | formatNumber({c: recordStore.PriceCurrency})}}</td>
                        <td v-else>{{getTotalInCurrency(row.Price) | formatNumber({c: recordStore.PriceCurrency})}}</td>
                        <td>
                          <input type="number" v-model="row.newPrice" class="form-control text-center"
                          :class="{'fill': row.newPrice}">
                            <span class="form-bar"></span>
                          </input>
                        </td>
                    </tr>
                </tbody>
                <button type="button" class="btn btn-primary mb-2 " @click="setNewPrice">
                    <span>{{tr('Apply')}}</span>
                </button>
            </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" :disabled="processing != null" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'change-total-value',
    mixins: [escape],
    props: ['show'],
    data () {
        return {
            iterations: 0,
            processing: null,
            values: {},
            ready: false
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    mounted () {
        this.values = this.getValues();
        this.ready = true;
        $('#change-total-mkp').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        getValues () {
            let rows = [];
            let totalsPerPerson = this.recordStore.totalsPerPerson;
            let totals = this.recordStore.totals;
            for (let baseId in this.recordStore.Totals.bases) {
                if (this.recordStore.isVariant(baseId)) continue;
                let total = this.recordStore.Totals[baseId];
                let quote = this.recordStore.BaseQuotes[baseId];
                if (!quote) continue;
                if (!total) continue;
                for (let pType of quote.paxTypes) {
                    let personTotal = total[pType]
                    if (personTotal && quote.SingleSupplement) {
                        if (totalsPerPerson[baseId] && totalsPerPerson[baseId][pType] &&
                            totalsPerPerson[baseId][pType].Rooms) {
                            for (let roomId in totalsPerPerson[baseId][pType].Rooms) {
                                let room = totalsPerPerson[baseId][pType].Rooms[roomId];
                                if (!this.recordStore.Totals[baseId][pType].SetPrice) {
                                    this.recordStore.Totals[baseId][pType].SetPrice = {};
                                }
                                if (!this.recordStore.Totals[baseId][pType].SetPrice[room.Room.id]) {
                                    this.recordStore.Totals[baseId][pType].SetPrice[room.Room.id] = {pax: 0, priceDiff: 0};
                                }
                                rows.push({room, baseId, pType, newPrice: null})
                            }
                        }
                    } else if (personTotal) {
                        if (!this.recordStore.Totals[baseId][pType].SetPrice) {
                            this.recordStore.Totals[baseId][pType].SetPrice = 0;
                        }
                        rows.push({baseId, pType, newPrice: null, Price: totalsPerPerson[baseId][pType].Price})
                    }
                }
            }
            return rows;
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        close () {
            this.$emit('update:show', false);
            $('#change-total-mkp').modal('hide');
        },
        async setNewPrice (row) {
            await this.$nextTick();
            let bases = this.recordStore.BaseQuotes.bases;
            for (let baseId in bases) {
                let singSupDif = 0;
                for (let row of this.values) {
                    if (row.baseId != baseId) continue;
                    if (!row.newPrice) continue;
                    let oldPrice;
                    if (row.room) oldPrice = this.getTotalInCurrency(parseFloat(row.room.CalculatedPrice));
                    if (!row.room) oldPrice = this.getTotalInCurrency(parseFloat(row.Price));
                    let diff = parseFloat(row.newPrice) - oldPrice;
                    let newPrice = this.recordStore.currencyConvert(this.recordStore.PriceCurrencyId, this.recordStore.CurrencyId, row.newPrice);
                    diff = this.recordStore.currencyConvert(this.recordStore.PriceCurrencyId, this.recordStore.CurrencyId, diff);
                    if (singSupDif) singSupDif = Math.abs(singSupDif - newPrice)
                    if (!singSupDif) singSupDif = newPrice;
                    if (row.room) {
                        this.recordStore.Totals[row.baseId][row.pType].SetPrice[row.room.Room.id] = {pax: row.room.pax, priceDiff: diff};
                        this.recordStore.Totals[row.baseId][row.pType].SetPriceSingleSup = singSupDif;
                    } else {
                        this.recordStore.Totals[row.baseId][row.pType].SetPrice = diff;
                    }
                }
            }
            EventBus.$emit('update-totals');
            this.values = this.getValues();
        }
    },
}
</script>
