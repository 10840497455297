<template>
    <div class="row mb-2 mt-5">
        <div class="col-12 mb-5 px-4">
          <div class="row border">
                <div class="col-12">
                    PAYMENT DUE
                </div>
                <div class="col-5">
                    TO CONFIRMATION ({{flights}}land {{landPercent}}%)
                </div>
                <div class="col-7">
                    {{downPayment | formatNumber({c: receipts[0].Currency, s: 4, d: 0})}}
                </div>
                <div class="col-5">
                    {{days}} DAYS BEFORE DEPARTURE ({{getPayDate(recordStore.StartDate) }})
                </div>
                <div class="col-7">
                    {{balance | formatNumber({c: receipts[0].Currency, s: 4, d: 0})}}
                </div>
            </div>
        </div>

        <div class="col-12 mb-3">
        ** All bank charges are borne directly by the sender
        </div>
        <div class="col-12 mb-3 mt-3">
        COORDINATE PER I PAGAMENTI DEI SERVIZI DI VIAGGIO BANCA SELLA
        </div>
        <div class="col-12">
        Intestatario del conto Travelab Srl Semplificata
        </div>
        <div class="col-12">
        Conto N° > A652810288543
        </div>
        <div class="col-12">
        IBAN > IT78J0326803201052810288543
        </div>
        <div class="col-12 mb-5">
        Nella causale scrivere: {{typeText}} viaggio {{recordStore.PaxName}}
        </div>
        <div class="col-12">

        </div>
        <div class="col-12">
        A seguito dell'accettazione del preventivo, il contratto di viaggio è concluso secondo il diritto indonesiano
        </div>
        <div class="col-12">
        con l’organizzatore tecnica responsabile da individuarsi in: RAJA PARIWISATA P.T. – con sede in Jalan
        </div>
        <div class="col-12">
        Kragilan-potronanggan. Tamanan. Banguntapan. Bantul, Daerah Istimewa Yogyakarta 55191 INDONESIA. Al
        </div>
        <div class="col-12">
        fine di evitare ai viaggiatori gli altissimi costi di pagamento internazionale, RAJATOURS si serve, in Italia,
        </div>
        <div class="col-12">
        della mandataria TRAVELAB srls per l’incasso delle somme dovute dal viaggiatore all’organizzatore a seguito
        </div>
        <div class="col-12">
        della stipula del contratto di viaggio. Rimane inteso fra le parti che Travelab Srls non è parte del contratto di
        </div>
        <div class="col-12">
        viaggio ma fornisce esclusivamente un servizio alle parti dello stesso
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-invoice-payment',
    props: ['ready', 'receipts', 'payment'],
    data () {
        return {
            receipt: null,
        }
    },
    async mounted () {
        this.receipt = _.find(this.receipts, (r) => r.id == this.payment);
    },
    methods: {
        getPayDate (d) {
            return moment(d).add(-this.days, 'days').format('DD/MM/YYYY')
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        typeText () {
            if (this.receipt && this.receipt.ReceiptType == 'DEPOSIT') return 'Acconto';
            if (this.receipt && this.receipt.ReceiptType == 'BALANCE') return 'Saldo';
            if (this.receipt && this.receipt.ReceiptType == 'REFUND') return 'Rimborso';
        },
        days () {
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.DaysBefore) {
                return this.recordStore.Agency.DaysBefore;
            }
            return 45;
        },
        flights () {
            if (this.recordStore.BookingFlights.length>0) return 'flights 100% + ';
            return '';
        },
        landPercent () {
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.LandPercent) {
                return this.recordStore.Agency.LandPercent;
            }
            return 40;
        },
        downPayment () {
            let res = 0;
            for (let row of this.receipts) {
                if (row.ReceiptType == 'DEPOSIT') {
                    res += row.Amount;
                }
            }
            return res;
        },
        balance () {
            let res = 0;
            for (let row of this.receipts) {
                if (row.ReceiptType == 'BALANCE') {
                    res += row.Amount;
                }
            }
            return res;
        }

    },
}
</script>

<style scoped>
  .table-bordered {
      border: 2px solid #000;
  }
  .table-bordered td {
      border: 2px solid #000;
  }
</style>