<template>
    <div class="modal fade bd-example-modal-lg" id="hotel-cost-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-large" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="card-body pt-3" v-if="ready">
                <div class="form-material form-row">
                    <div class="col-md-4 row">
                        <div class="col-6">
                            <date-picker id="HotelCost-StartDate"
                                :label="'Period'"
                                fieldName="StartDate"
                                :def.sync="period.StartDate"></date-picker>
                        </div>
                        <div class="col-6" style="padding-left: 0px">
                            <date-picker id="HotelCost-EndDate"
                                fieldName="EndDate"
                                :startDate="period.StartDate"
                                :def.sync="period.EndDate"></date-picker>
                        </div>
                    </div>
                    <div class="col-md-4">
                      <input-field
                        @change="setModified"
                        :defValue.sync="period.Comment"
                        :field="{name: 'Comment', label: 'Comment', editor: 'text'}"
                      ></input-field>
                    </div>
                    <div class="col-md-2">
                        <div class="form-check mt-3 ml-3">
                            <input v-model="period.BlackOut" name="BlackOut" class="form-check-input"
                            type="checkbox" @change="setModified">
                            <label class="smaller form-check-label">{{tr('Black Out')}}</label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-check mt-3 ml-3">
                            <input v-model="period.Promotion" name="Promotion" class="form-check-input" type="checkbox">
                            <label class="smaller form-check-label">Promotion</label>
                        </div>
                    </div>
                </div>
                <div class="form-material form-row" v-if="period.Promotion">
                    <div class="col-md-4 row">
                        <div class="col-6">
                            <date-picker id="Promo-StartDate"
                                label="Booking Period"
                                :def.sync="period.PromoStartDate"></date-picker>
                        </div>
                        <div class="col-6" style="padding-left: 0px">
                            <date-picker id="Promo-EndDate"
                                :def.sync="period.PromoEndDate"></date-picker>
                        </div>
                    </div>
                </div>
                <record-week-days
                    :record.sync="period"
                    :emits="false"
                    @change="setModified"
                ></record-week-days>
                <div class="form-material form-row" v-if="!period.BlackOut">
                    <div class="col-md-1">
                        <select-input
                            fieldName="CurrencyId"
                            :fieldOptions="fieldOptions.CurrencyId"
                            label="Currency"
                            @change="setModified"
                            :currentValue.sync="period.CurrencyId"
                            :required="true">
                        </select-input>
                    </div>
                    <div class="col-md-4">
                        <select-input fieldName="RoomCostBy" :fieldOptions="fieldOptions.RoomCostBy"
                        label="Cost Per"
                        :currentValue.sync="period.RoomCostBy" required="true" @change="clearPeriod">
                        </select-input>
                    </div>
                    <div class="col-md-2">
                        <select-input fieldName="UnitCostBy" :fieldOptions="fieldOptions.UnitCostBy"
                        label=" " :currentValue.sync="period.UnitCostBy" required="true">
                        </select-input>
                    </div>
                    <div class="col-md-2">
                        <select-input fieldName="PeriodCostBy" :fieldOptions="fieldOptions.PeriodCostBy"
                        label=" " :currentValue.sync="period.PeriodCostBy" required="true">
                        </select-input>
                    </div>
                    <div class="col-md-2">
                      <input-field
                        :defValue.sync="period.MinimumStay"
                        :field="{name: 'Comment', label: 'Minimum Stay', editor: 'number'}"
                      ></input-field>
                    </div>
                </div>
                <div class="form-material form-row" v-if="!period.BlackOut">
                    <div class="col-md-2">
                      <input-field
                        :defValue.sync="period.Commission"
                        :field="{name: 'Commission', label: 'Commission', editor: 'number', d: 0}"
                      ></input-field>
                    </div>
                    <div class="col-md-3">
                        <div>
                        <select-input fieldName="DiscountType" :fieldOptions="fieldOptions.DiscountType"
                        label="Discount" :currentValue.sync="period.DiscountType">
                        </select-input>
                        </div>
                    </div>
                    <div class="col-md-2">
                      <input-field
                        v-if="period.DiscountType=='CONSECUTIVE' || period.DiscountType=='TOTAL'"
                        :defValue.sync="period.Discount"
                        :field="{name: 'Discount', label: 'Discount Percentage', editor: 'number', decimal: 2}"
                        :readonlyIf="period.DiscountType=='NONE' || !period.DiscountType"
                      ></input-field>
                    </div>
                    <div class="col-md-2">
                      <input-field
                        v-if="period.DiscountType=='CONSECUTIVE' || period.DiscountType=='TOTAL'"
                        :defValue.sync="period.DiscountDays"
                        :field="{name: 'DiscountDays', label: 'Nights from', editor: 'number'}"
                        :readonlyIf="period.DiscountType=='NONE' || !period.DiscountType"
                      ></input-field>
                    </div>

                </div>
                <div class="dropdown-divider"></div>
                <div v-if="!period.BlackOut">
                    <room-type-category
                        v-if="period.RoomCostBy=='ROOM_TYPE_CATEGORY'"
                        :period.sync="period"
                        :rooms="notProgramRooms"
                        :roomTypes="roomTypes"
                        @setModified="setModified"
                    ></room-type-category>
                    <nights-room-type
                        v-if="period.RoomCostBy=='NIGHT_ROOM_TYPE'"
                        :period.sync="period"
                        :nights="nights"
                        :roomTypes="roomTypes"
                        @setModified="setModified"
                    ></nights-room-type>
                    <nights-room-category
                        v-if="period.RoomCostBy=='NIGHT_ROOM_CATEGORY'"
                        :period.sync="period"
                        :nights="nights"
                        :rooms="programRooms"
                        @setModified="setModified"
                    ></nights-room-category>
                    <nights-room-type-category
                        v-if="period.RoomCostBy=='ROOM_TYPE_CATEGORY_NIGHT'"
                        :period.sync="period"
                        :nights="nights"
                        :rooms="programRooms"
                        :roomTypes="roomTypes"
                        @setModified="setModified"
                    ></nights-room-type-category>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-2">
                        <div class="form-group">
                            <div class="form-check">
                                <input v-model="period.Closed" name="Closed"
                                class="form-check-input" type="checkbox" id="Closed">
                                <label class="smaller form-check-label">{{tr('Archive')}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <edited-by :record="period"></edited-by>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const parentHotelCost = importVueComp('components/hotel', 'HotelCost', 'custom');
export default {
    name: 'custom-hotel-cost',
    mixins: [parentHotelCost],
}
</script>
