<template>
  <div class="modal fade" id="change-total-mkp" tabindex="-1" role="dialog" aria-labelledby="change-total-mkp"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content form-material">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Set Price')}}</h5>
        </div>
        <div class="modal-body form-group form-default form-row px-4">
            <table class="table table-striped  table-sm table-bordered">
                <thead>
                    <tr class="table-secondary">
                        <th><span>{{tr('Quotation')}}</span></th>
                        <th><span>{{tr('Current Price')}}</span></th>
                        <th><span>{{tr('Current Markup')}}</span></th>
                        <th><span>{{tr('New Price')}}</span></th>
                        <th><span>{{tr('New Markup')}}</span></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row of values" >
                        <td>{{row.name}} {{row.pType}}
                                <span class="text-sm" v-if="row.variantRow">*Apply changes to hotels only</span>
                        </td>
                        <td>{{row.oldTotal | formatNumber({c: recordStore.PriceCurrency})}}</td>
                        <td>{{parseFloat(row.oldMkp).toFixed(2)}}</td>
                        <td v-if="!row.overPrice">
                          <input type="number" v-model="row.newTotal" class="form-control text-center"
                          :class="{'fill': row.newTotal}" @change="setNewMkp(row)">
                            <span class="form-bar"></span>
                          </input>
                        </td>
                        <td v-if="!row.overPrice">
                            <span v-if="row.newMkp">{{row.newMkp.toFixed(2)}}</span>
                        </td>
                        <td v-if="row.overPrice" colspan="2">
                            {{ tr('Price overwritten') }}
                        </td>
                        <td>
                            <button :disabled="!row.newMkp || processing" type="button" class="btn btn-primary"
                                @click="runApply(row)">
                                <img class="loading-gif" src="@/img/loading.gif" v-if="processing == row.rowNr">
                                <span v-else>{{tr('Apply')}}</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" :disabled="processing != null" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'change-total-mkp',
    mixins: [escape],
    props: ['show'],
    data () {
        return {
            values: [],
            iterations: 0,
            processing: null,
            totalByPaxVariant: null,
            totalsPerPerson: null,
            paxBaseByPaxType: null,
            singleSupplementValue: null,
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        markups () {
            let res = {};
            for (let baseId in this.recordStore.BaseQuotes.bases) {
                let values = {};
                for (let day of this.recordStore.BookingDays) {
                    for (let s of day.BookingDayServices) {
                        if (this.recordStore.isVariant(baseId)) continue;
                        if (!s.Quote) continue;
                        if (!s.Quote.QuoteList) continue;
                        if (!s.Quote.QuoteList[baseId]) continue;
                        for (let pType of this.recordStore.BaseQuotes[baseId].paxTypes) {
                            if (!s.Quote.QuoteList[baseId][pType]) continue;
                            if (!s.Quote.QuoteList[baseId][pType].Include) continue;
                            if (!s.Quote.QuoteList[baseId][pType].Cost) continue;
                            let mkp = this.recordStore.Markup;
                            if (s.Quote.QuoteList[baseId][pType].Markup) mkp = s.Quote.QuoteList[baseId][pType].Markup;
                            //if (!s.Quote.QuoteList[baseId][pType].Markup) continue;
                            if (!values[pType]) {
                                values[pType] = {};
                            }
                            if (!values[pType][mkp]) {
                                values[pType][mkp] = {cnt: 0, cost: 0, price: 0};
                            }
                            values[pType][mkp].cnt += 1;
                            if (this.recordStore.QuoteBy == 'QUANTITY') {
                                values[pType][mkp].cost += s.Quote.QuoteList[baseId][pType].TotalCost;
                                values[pType][mkp].price += s.Quote.QuoteList[baseId][pType].TotalPrice;
                            } else {
                                values[pType][mkp].cost += s.Quote.QuoteList[baseId][pType].Cost;
                                values[pType][mkp].price += s.Quote.QuoteList[baseId][pType].Price;
                            }
                        }
                    }
                    for (let hotel of day.BookingDayHotels) {
                        for (let room of hotel.BookingDayRooms) {
                            if (!room.QuoteList) continue;
                            if (!room.QuoteList[baseId]) continue;
                            if (!room.QuoteList[baseId].Include && !room.SingleSupplement) continue;
                            let mkp = this.recordStore.Markup;
                            if (room.QuoteList[baseId].Markup) mkp = room.QuoteList[baseId].Markup;
                            let pType = 'ADT';
                            if (room.SingleSupplement) {
                                pType = 'SSUP';
                            }
                            if (mkp) {
                                if (!values[pType]) {
                                    values[pType] = {};
                                }
                                if (!values[pType][mkp]) {
                                    values[pType][mkp] = {cnt: 0, cost: 0, price: 0};
                                }
                                values[pType][mkp].cnt += 1;
                                if (this.recordStore.QuoteBy == 'QUANTITY') {
                                    values[pType][mkp].cost += room.QuoteList[baseId].TotalCost;
                                    values[pType][mkp].price += room.QuoteList[baseId].TotalPrice;
                                } else {
                                    values[pType][mkp].cost += room.QuoteList[baseId].Cost;
                                    values[pType][mkp].price += room.QuoteList[baseId].Price;
                                }
                            }
                        }
                    }
                }
                //let sorted = Object.keys(values).sort(function(a,b){return values[b].cnt - values[a].cnt})
                res[baseId] = values;
            }
            return res;
        },
        maxCnt () {
            let res = {};
            for (let baseId in this.markups) {
                //if (this.recordStore.isVariant(baseId)) continue;
                if (!res[baseId]) res[baseId] = {};
                for (let pType in this.markups[baseId]) {
                    let values = this.markups[baseId][pType];
                    let sorted = Object.keys(values).sort(function(a,b){return values[b].cnt - values[a].cnt})
                    res[baseId][pType] = sorted[0];
                }
            }
            return res;
        },
        maxFlightCnt () {
            let res = {};
            for (let flight of this.recordStore.BookingFlights) {
                if (flight.QuoteType != 'NOT_INCLUDED') continue;
                if (!res[flight.PaxType]) res[flight.PaxType] = {};
                if (!res[flight.PaxType][flight.Markup]) res[flight.PaxType][flight.Markup] = {cnt: 0, cost: 0, price: 0};
                res[flight.PaxType][flight.Markup].cnt += 1;
                res[flight.PaxType][flight.Markup].cost += flight.FlightCost;
                res[flight.PaxType][flight.Markup].price += flight.FlightPrice;
            }
            let res2 = {};
            for (let pType in res) {
                let sorted = Object.keys(res[pType]).sort(function(a,b){return res[pType][b].cnt - res[pType][a].cnt})
                res2[pType] = sorted[0]
            }
            return res2;
        }

    },
    mounted () {
        $('#change-total-mkp').modal({backdrop: 'static', keyboard: false}, 'show');
        this.totalByPaxVariant = this.recordStore.totalByPaxVariant;
        this.totalsPerPerson = this.recordStore.totalsPerPerson;
        this.paxBaseByPaxType = this.recordStore.paxBaseByPaxType;
        this.singleSupplementValue = this.recordStore.singleSupplementValue;
        this.setRows();
    },
    methods: {
        setRows () {
            let oldRows = _.cloneDeep(this.values);
            let k = 0;
            this.values = [];
            for (let baseId in this.recordStore.BaseQuotes.bases) {
                //if (this.recordStore.isVariant(baseId)) continue;
                let quote =  this.recordStore.BaseQuotes[baseId];
                for (let pType of this.recordStore.BaseQuotes[baseId].paxTypes) {
                    if (this.recordStore.isVariant(baseId)) {
                        for (let variantId in this.recordStore.QuoteVariants[baseId]) {
                            let oldRow = oldRows[k];
                            let newTotal;
                            let newMkp;
                            let oldMkp = this.maxCnt[baseId][pType];
                            let oldTotal = this.getTotalInCurrency(this.totalByPaxVariant[baseId][variantId][pType].Price);
                            let currentTotal = oldTotal;
                            if (oldRow && oldRow.oldTotal) oldTotal = oldRow.oldTotal;
                            if (oldRow && oldRow.newTotal) newTotal = oldRow.newTotal;
                            if (oldRow && oldRow.newMkp) newMkp = oldRow.newMkp;
                            if (oldRow && oldRow.oldMkp) oldMkp = oldRow.oldMkp;
                            this.values.push({
                                rowNr: k,
                                price: this.totalByPaxVariant[baseId][variantId][pType].Price,
                                oldTotal,
                                currentTotal,
                                pType,
                                baseId,
                                newTotal,
                                oldMkp,
                                newMkp,
                                mkp: this.maxCnt[baseId][pType],
                                name: this.recordStore.QuoteVariants[baseId][variantId].Name,
                                variantId,
                                variantRow: true,
                                hotelCategoryId: this.recordStore.QuoteVariants[baseId][variantId].HotelCategoryId,
                            })
                            k += 1;

                        }
                    } else if (this.recordStore.QuoteBy == 'QUANTITY') {
                        let oldRow = oldRows[k];
                        let newTotal;
                        let newMkp;
                        let oldMkp = this.maxCnt[baseId][pType];
                        let oldTotal = this.getTotalInCurrency(this.totalsPerPerson[baseId][pType].Price);
                        let currentTotal = oldTotal;
                        if (oldRow && oldRow.oldTotal) oldTotal = oldRow.oldTotal;
                        if (oldRow && oldRow.newTotal) newTotal = oldRow.newTotal;
                        if (oldRow && oldRow.newMkp) newMkp = oldRow.newMkp;
                        if (oldRow && oldRow.oldMkp) oldMkp = oldRow.oldMkp;
                        this.values.push({
                            rowNr: k,
                            price: this.totalsPerPerson[baseId][pType].Price,
                            oldTotal,
                            currentTotal,
                            pType,
                            baseId,
                            newTotal,
                            oldMkp,
                            newMkp,
                            mkp: this.maxCnt[baseId][pType],
                            name: this.recordStore.getBasePaxTypes(quote, baseId),
                            overPrice: this.recordStore.Totals[baseId][pType].OverPrice
                        })
                        k += 1;
                        if (quote.SingleSupplement) {
                            let oldRow = oldRows[k];
                            let newTotal;
                            let newMkp;
                            let oldMkp = this.maxCnt[baseId]['SSUP'];
                            let oldTotal = this.getTotalInCurrency(this.singleSupplementValue[baseId]);
                            let currentTotal = oldTotal;
                            if (oldRow && oldRow.oldTotal) oldTotal = oldRow.oldTotal;
                            if (oldRow && oldRow.newTotal) newTotal = oldRow.newTotal;
                            if (oldRow && oldRow.newMkp) newMkp = oldRow.newMkp;
                            if (oldRow && oldRow.oldMkp) oldMkp = oldRow.oldMkp;
                            this.values.push({
                                rowNr: k,
                                price: this.singleSupplementValue[baseId],
                                oldTotal,
                                currentTotal,
                                pType: 'SSUP',
                                baseId,
                                newTotal,
                                oldMkp,
                                newMkp,
                                mkp: this.maxCnt[baseId]['SSUP'],
                                name: 'SGL SUP',
                                overPrice: null,
                                sglSup: true,
                            })
                            k += 1;
                        }
                    } else {
                        let oldRow = oldRows[k];
                        let newTotal;
                        let newMkp;
                        let oldMkp = this.maxCnt[baseId][pType];
                        let oldTotal = this.getTotalInCurrency(this.recordStore.totals[baseId][pType].Price);
                        let currentTotal = oldTotal;
                        if (oldRow && oldRow.oldTotal) oldTotal = oldRow.oldTotal;
                        if (oldRow && oldRow.newTotal) newTotal = oldRow.newTotal;
                        if (oldRow && oldRow.newMkp) newMkp = oldRow.newMkp;
                        if (oldRow && oldRow.oldMkp) oldMkp = oldRow.oldMkp;
                        this.values.push({
                            rowNr: k,
                            price: this.recordStore.totals[baseId][pType].Price,
                            oldTotal,
                            currentTotal,
                            pType,
                            baseId,
                            newTotal,
                            oldMkp,
                            newMkp,
                            mkp: this.maxCnt[baseId][pType],
                            name: this.recordStore.getBasePaxTypes(quote, baseId),
                            overPrice: this.recordStore.Totals[baseId][pType].OverPrice
                        })
                        k += 1;
                        if (quote.SingleSupplement) {
                            let oldRow = oldRows[k];
                            let newTotal;
                            let newMkp;
                            let oldMkp = this.maxCnt[baseId]['SSUP'];
                            let oldTotal = this.getTotalInCurrency(this.singleSupplementValue[baseId]);
                            let currentTotal = oldTotal;
                            if (oldRow && oldRow.oldTotal) oldTotal = oldRow.oldTotal;
                            if (oldRow && oldRow.newTotal) newTotal = oldRow.newTotal;
                            if (oldRow && oldRow.newMkp) newMkp = oldRow.newMkp;
                            if (oldRow && oldRow.oldMkp) oldMkp = oldRow.oldMkp;
                            this.values.push({
                                rowNr: k,
                                price: this.singleSupplementValue[baseId],
                                oldTotal,
                                currentTotal,
                                pType: 'SSUP',
                                baseId,
                                newTotal,
                                oldMkp,
                                newMkp,
                                mkp: this.maxCnt[baseId]['SSUP'],
                                name: 'SGL SUP',
                                overPrice: null,
                                sglSup: true,
                            })
                            k += 1;
                        }
                    }

                }
            }
            for (let flightId in this.recordStore.flightsNotIncluded) {
                let flight = this.recordStore.flightsNotIncluded[flightId];
                let oldRow = oldRows[k];
                let newTotal;
                let newMkp;
                let oldMkp = this.maxFlightCnt[flight.pType];
                let oldTotal = this.getTotalInCurrency(flight.Price);
                if (oldRow && oldRow.oldTotal) oldTotal = oldRow.oldTotal;
                if (oldRow && oldRow.newTotal) newTotal = oldRow.newTotal;
                if (oldRow && oldRow.newMkp) newMkp = oldRow.newMkp;
                if (oldRow && oldRow.oldMkp) oldMkp = oldRow.oldMkp;
                this.values.push({
                    rowNr: k,
                    price: flight.Price,
                    oldTotal,
                    currentTotal: this.getTotalInCurrency(flight.Price),
                    pType: flight.pType,
                    baseId: null,
                    newTotal,
                    oldMkp,
                    newMkp,
                    mkp: this.maxFlightCnt[flight.pType],
                    name: 'Flight',
                    isFlight: true,
                })
                k += 1;

            }
            this.values = Object.assign([], this.values);
        },
        save () {
            this.$emit('save', this.values);
            this.close();
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        close () {
            this.$emit('update:show', false);
            $('#change-total-mkp').modal('hide');
        },
        setNewMkp (row) {
            if (row.isFlight) {
                this.setNewFlightMkp(row);
                return;
            }
            if (!row.newTotal) return;
            let f = row.price / row.currentTotal;
            let newTotalPrice = row.newTotal * f;
            if (this.recordStore.QuoteBy == 'QUANTITY' && !row.sglSup) {
                newTotalPrice = newTotalPrice * this.paxBaseByPaxType[row.baseId][row.pType];
            }
            /*if (this.recordStore.QuoteBy == 'QUANTITY') {
                newTotalPrice = newTotalPrice * this.paxBaseByPaxType[row.baseId][row.pType];
            }*/
            let totalPrice;
            if (row.variantRow) {
                totalPrice = this.recordStore.totalByPaxVariant[row.baseId][row.variantId][row.pType].Price;
            } else if (row.sglSup) {
                totalPrice = this.recordStore.singleSupplementValue[row.baseId];
            } else {
                totalPrice = this.recordStore.totals[row.baseId][row.pType].Price;
            }
            let rowCost;
            let rowPrice;
            if (this.markups[row.baseId] && this.markups[row.baseId][row.pType] && this.markups[row.baseId][row.pType][row.mkp]) {
                rowCost = this.markups[row.baseId][row.pType][row.mkp].cost;
                rowPrice = this.markups[row.baseId][row.pType][row.mkp].price
            }
            let priceDiff = newTotalPrice - totalPrice;
            let newPrice = rowPrice + priceDiff;
            let newMkp = tools.getMarkup(newPrice, rowCost);
            row.newMkp = newMkp;
            this.values = Object.assign([], this.values);
        },
        setNewFlightMkp (row) {
            if (!row.newTotal) return;
            let f = row.price / row.currentTotal;
            let newTotalPrice = row.newTotal * f;
            let cost = tools.getCost(row.price, row.oldMkp);
            let totalPrice = row.price;
            let totalCost = tools.getCost(row.price, row.oldMkp);
            //let rowCost = this.markups[row.baseId][row.pType][row.mkp].cost;
            //let rowPrice = this.markups[row.baseId][row.pType][row.mkp].price
            //let priceDiff = newTotalPrice - totalPrice;
            //let newPrice = rowPrice + priceDiff;
            let newMkp = tools.getMarkup(newTotalPrice, totalCost);
            row.newMkp = newMkp;
            this.values = Object.assign([], this.values);
        },

        async runApply (row) {
            this.processing = row.rowNr;
            setTimeout(async () => {
                this.iterations = 0;
                await this.apply(row);
                this.setRows();
                this.processing = null;
                EventBus.$emit('update-quotes');
                EventBus.$emit('update-totals');
            }, 100);
        },
        async apply (row) {
            this.iterations += 1;
            if (this.iterations >= 20) {
                this.processing = null;
                return;
            }
            if (!row.isFlight) {
                for (let baseId in this.recordStore.BaseQuotes.bases) {
                    if (baseId != row.baseId) continue;
                    for (let day of this.recordStore.BookingDays) {
                        for (let s of day.BookingDayServices) {
                            if (row.variantRow) continue;
                            if (!s.Quote) continue;
                            if (!s.Quote.QuoteList) continue;
                            if (!s.Quote.QuoteList[baseId]) continue;
                            for (let pType of this.recordStore.BaseQuotes[baseId].paxTypes) {
                                if (pType != row.pType) continue;
                                if (!s.Quote.QuoteList[baseId][pType]) continue;
                                if (!s.Quote.QuoteList[baseId][pType].Include) continue;
                                if (!s.Quote.QuoteList[baseId][pType].Cost) continue;
                                //if (!s.Quote.QuoteList[baseId][pType].Markup) continue;
                                let mkp = this.recordStore.Markup;
                                if (s.Quote.QuoteList[baseId][pType].Markup) mkp = s.Quote.QuoteList[baseId][pType].Markup;
                                if (mkp != row.mkp) continue;
                                await s.Quote.QuoteList[baseId][pType].setValue({fieldName: 'Markup', value: row.newMkp});
                            }
                        }
                        if (row.pType=='ADT' || row.pType == 'SSUP' ) {
                            for (let hotel of day.BookingDayHotels) {
                                for (let room of hotel.BookingDayRooms) {
                                    if (row.variantRow && row.hotelCategoryId != room.HotelCategoryId) continue;
                                    if (!room.QuoteList) continue;
                                    if (!room.QuoteList[baseId]) continue;
                                    if (!room.QuoteList[baseId].Include && row.pType=='ADT') continue;
                                    if (room.SingleSupplement && row.pType!='SSUP') continue;
                                    let mkp = this.recordStore.Markup;
                                    if (room.QuoteList[baseId].Markup) mkp = room.QuoteList[baseId].Markup;
                                    //if (!room.QuoteList[baseId].Markup) continue;
                                    if (mkp != row.mkp) continue;
                                    await room.QuoteList[baseId].setValue({fieldName: 'Markup', value: row.newMkp});
                                }
                            }
                        }
                    }
                }
                let price;
                if (row.variantRow) {
                } else if (this.recordStore.QuoteBy == 'QUANTITY' && row.sglSup) {
                    price = this.getTotalInCurrency(this.recordStore.singleSupplementValue[row.baseId])
                } else if (this.recordStore.QuoteBy == 'PERSON' && row.sglSup) {
                    price = this.getTotalInCurrency(this.recordStore.singleSupplementValue[row.baseId])
                } else if (this.recordStore.QuoteBy == 'QUANTITY') {
                    if (!this.recordStore.bookingConfirmed) {
                        this.recordStore.Totals[row.baseId][row.pType].SetPrice = null;
                        this.recordStore.Totals[row.baseId][row.pType].SetPriceSingleSup = null;
                    }
                    price = this.getTotalInCurrency(this.recordStore.totalsPerPerson[row.baseId][row.pType].Price);
                } else {
                    price = this.getTotalInCurrency(this.recordStore.totals[row.baseId][row.pType].Price);
                }
                if (Math.abs(price - row.newTotal) > 0.001) {
                    row.mkp = row.newMkp;
                    this.setNewMkp(row);
                    await this.apply(row);
                }
            } else {
                for (let flight of this.recordStore.BookingFlights) {
                    if (flight.QuoteType != 'NOT_INCLUDED') continue;
                    if (row.pType != flight.PaxType) continue;
                    if (flight.Markup != row.mkp) continue;
                    await flight.setValue({fieldName: 'Markup', value: row.newMkp});
                }

                let flight = _.find(this.recordStore.flightsNotIncluded, (r) => {
                    return r.pType == row.pType;
                })
                let price = this.getTotalInCurrency(flight.Price);
                if (Math.abs(price - row.newTotal) > 0.001) {
                    row.mkp = row.newMkp;
                    this.setNewFlightMkp(row);
                    await this.apply(row);
                }

            }
        }
    },
}
</script>
