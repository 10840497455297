<template>
    <div class="form-row d-flex align-items-start">
        <div class="col-7 pr-4 form-row days-drag" v-dragula :bag="'detailbag'" id="detailed-days"
          :class="{'col-7': compressView, 'col-12': !compressView}"
          >
            <div class="col-md-12 card-row day-card my-1" v-for="(day, idx) in recordStore.BookingDays"
                :class="getBGClass(idx)" :dayNr="idx">
                <div class="card day-card my-1" :dayNr="idx">
                    <a data-toggle="collapse" :data-target="'#day' + idx" aria-expanded="false" :aria-controls="'#day' + idx"
                        class="day-card-div border-bottom">
                        <booking-day-header
                            :day="day"
                            :dayId="idx"
                        ></booking-day-header>
                    </a>
                    <div class="collapse" :id="'day' + idx" >
                        <div class="card-body p-0 mt-2">
                            <div class="form-row">
                                <div class="px-3"
                                  :class="{'col-md-12': compressView, 'col-md-3': !compressView}">
                                    <booking-day-options
                                        :compressView="compressView"
                                        :dayId="idx"
                                        :dayNr="day.DayNr"
                                    ></booking-day-options>
                                </div>
                                <div class="day-services"
                                  :class="{'col-md-12': compressView, 'col-md-9': !compressView}">
                                    <div class="form-row services-drag px-1 mx-1" v-dragula :bag="'detailbag'"
                                        :class="{'border-dashed': day.BookingDayServices.length==0}"
                                        :day="idx">
                                        <div v-if="day.BookingDayServices.length==0"
                                            class="col-12 d-flex justify-content-center drag-here">
                                            <i class="drag-here">{{tr('Drag services here')}}</i>
                                        </div>
                                        <booking-day-service
                                            v-for="(row, rowIdx) of day.BookingDayServices"
                                            :compressView="compressView"
                                            :key="day.DayNr + '-' + rowIdx"
                                            :day="idx"
                                            :dayId="idx"
                                            :dayNr="day.DayNr"
                                            :rowIdx="rowIdx"
                                        >
                                        </booking-day-service>
                                    </div>
                                    <booking-day-hotel
                                        v-if="day.DayNr<recordStore.daysLength-1"
                                        :dayId="idx"
                                        :dayNr="day.DayNr"
                                        :servicesLength="day.BookingDayServices.length"
                                    ></booking-day-hotel>
                                </div>
                            </div>
                        </div>
                        <booking-day-buttons
                            :compressView="compressView"
                            :dayId="idx"
                            :dayNr="day.DayNr"
                        ></booking-day-buttons>
                    </div>
                </div>
            </div>

            <div class="col-12 form-row mt-2" v-if="sales">
                <div class="col-md-12 d-flex justify-content-center mb-2 form-row">
                    <button type="button" class="btn btn-outline-success btn-action-day ml-2 mt-1"
                        :class="{'col-3': !compressView, 'col-5': compressView}"
                        @click="compressView = !compressView">
                        <i class="mr-2" aria-hidden="true">
                        </i>{{tr('Change View')}}
                    </button>
                    <button type="button" id="add-new-day" class="btn btn-outline-success btn-action-day ml-2 mt-1"
                        :class="{'col-3': !compressView, 'col-5': compressView}"
                        @click="addNewDay(true)">
                        <i class="mr-2" aria-hidden="true">
                            <font-awesome-icon icon="plus"/>
                        </i>{{tr('Day')}}
                    </button>
                    <button type="button" id="add-template" class="btn btn-outline-success btn-action-day ml-2 mt-1"
                        :class="{'col-3': !compressView, 'col-5': compressView}"
                        :disabled="!templates || !canAddTemplate" @click="showTemplateSelector(false)">
                          <i class="mr-2" aria-hidden="true">
                              <font-awesome-icon icon="plus"/>
                          </i>
                          {{tr('Template')}}
                    </button>
                    <button type="button" id="add-new-day"
                        :class="{'col-3': !compressView, 'col-5': compressView}"
                        class="btn btn-outline-success btn-action-day ml-2 mt-1 d-flex align-items-center justify-content-center"
                        @click="insertDayAtBeginning()">
                        <font-awesome-icon icon="plus" size="xs" class="mr-1"/>
                        <day-one class="day-one-svg"></day-one>
                    </button>
                    <button type="button" class="btn btn-outline-success btn-group ml-2 mt-1" @click="$emit('groupBy')"
                        :class="{'col-3': !compressView, 'col-6': compressView}"
                        v-if="!recordStore.SkipDays"
                        data-toggle="tooltip" data-placement="top"
                        :title="recordStore.cityServicePending? tr('Fill City in all Free Services'): ''"
                        :disabled="recordStore.cityServicePending">
                        <font-awesome-icon icon="exclamation" v-if="recordStore.cityServicePending" class="exclamation-alert"/>
                         {{tr('Group by City')}}
                    </button>
                </div>
            </div>
            <div class="col-12 form-row mt-2 form-material" v-if="sales && !recordStore.SkipDays && recordStore.notTemplate">
                <div class="col-md-12 d-flex justify-content-center mb-2">
                    <date-picker :def.sync="recordStore.StartDate" id="StartDate"
                        label="Start Date" class="col-md-3 px-3" labelClass="px-3"
                        :disabled="!salesActions || recordStore.tripEnded"
                        :addClass="recordStore.invalid.StartDate? 'border-danger': ''"
                    ></date-picker>
                    <date-picker :def.sync="recordStore.EndDate" id="EndDate"
                        label="End Date" class="col-md-3 px-3" labelClass="px-3"
                        :disabled="!salesActions"
                        :startDate="recordStore.StartDate"
                    ></date-picker>
                    <div class="col-2 ml-3 mb-2" v-if="recordStore.notTemplate">
                        <div class="form-group form-default">
                            <input v-model="days" @change="setDays"
                            class="form-control text-right" type="number" step="1">
                            <span class="form-bar">
                            </span>
                            <label class="float-label">{{tr('Days')}}</label>
                        </div>
                    </div>
                    <div class="py-3">
                      <button type="button" class="btn btn-outline-success btn-action-day ml-2" @click="completeDays">
                          <i class="mr-2" aria-hidden="true">
                          </i>{{tr('Complete Days')}}
                      </button>
                    </div>
                </div>
            </div>
            <div class="col-12 form-material ml-3 mb-2" v-if="sales">
                <div class="form-check text-left ml-5"
                    :class="{'col-4': !recordStore.SkipDays, 'col-9': recordStore.SkipDays}">
                    <input v-model="recordStore.SkipDays"
                    class="form-check-input" type="checkbox" id="SkipDays">
                    <label class="smaller form-check-label">{{tr('Skipped Days')}}</label>
                </div>
            </div>

        </div>
        <div class="col-5 form-row px-2 d-flex justify-content-center" v-if="compressView" id="add-services">
            <service-selector
                :language="language"
                :city="city"
            ></service-selector>
        </div>
        <template-selector-modal
            v-if="templateSelector"
            @add="addTemplate"
            @close="closeTemplateSelector"
            :language="recordStore.languageName"
        ></template-selector-modal>
    </div>

</template>

<script>
const templateSelectorModal = importVueComp('components/service', 'TemplateSelectorModal');
const serviceSelector = importVueComp('components/service', 'ServiceSelector');
const templateSelector = importVueComp('components/service', 'TemplateSelector');
const bookingCover = importVueComp('components/booking', 'BookingCover');
const bookingDayHeader = importVueComp('components/booking', 'BookingDayHeader');
const bookingDayHotel = importVueComp('components/booking', 'BookingDayHotel');
const bookingDayService = importVueComp('components/booking', 'BookingDayService');
const bookingDayOptions = importVueComp('components/booking', 'BookingDayOptions');
const bookingDayButtons = importVueComp('components/booking', 'BookingDayButtons');
const bookingQuoteList = importVueComp('components/booking', 'BookingQuoteList');
import dayOne from '@/components/svg/DayOne';
var EventBus = require('@/tools/event-bus').default;

import { mapState } from 'vuex';
import Vue from 'vue';
var VueDragula = require('vue-dragula');
Vue.use(VueDragula);

export default {
    name: 'booking-detail',
    components: {
        'template-selector-modal': templateSelectorModal,
        'booking-day-header': bookingDayHeader,
        'booking-day-hotel': bookingDayHotel,
        'booking-day-service': bookingDayService,
        'booking-day-options': bookingDayOptions,
        'booking-day-buttons': bookingDayButtons,
        'service-selector': serviceSelector,
        'template-selector': templateSelector,
        'booking-quote-list': bookingQuoteList,
        'day-one': dayOne
    },
    data () {
        return {
            currentDay: null,
            isOver: false,
            templateSelector: false,
            onlyServices: null,
            showTemplates: false,
            language: null,
            city: null,
            compressView: true,
            templates: null,
            days: null,
        }
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            operActions: state => state.main.operActions,
            quillFocus: state => state.quill.quillFocus,
            quillStatus: state => state.quill.quillStatus,
            recordStore: state => state.main.record,
        }),
        sales () {
            return this.salesActions || this.recordStore.isTemplate;
        },
        canAddTemplate () {
            return true;
        }
    },
    created (){
        let self = this;
        let bagOptions = {
            copy (el, source) {
                if (source.classList.contains('services-table')) return true;
                return false;
            },
            accepts (el, target) {
                if (self.quillFocus) {
                    self.$store.commit('setQuillFocus', false);
                    self.$store.commit('setQuillStatus', false);
                }
                if (el.classList.contains('day-card') && self.recordStore.SkipDays) return false;
                if (el.classList.contains('drag-here')) return false;
                if (target.classList.contains('services-table')) return false;
                if (el.classList.contains('service-drag-card') && !target.classList.contains('services-drag')) return false;
                if (el.classList.contains('day-card') && !target.classList.contains('days-drag')) return false;
                return self.canAccepts(el, target);
            },
            moves (el, container, handle) {
                if (handle.classList.contains('drag-here')) return false;
                if (handle.classList.contains('card-body')) return false;
                if (handle.classList.contains('text-edit-show')) return false;
                if (handle.classList.contains('ql-toolbar')) return false;
                if (handle.classList.length==0) return false;
                self.isOver = true;
                return self.canMove(el, container, handle) || (el.classList.contains('service-drag-card') && self.operActions);
            },
            revertOnSpill: true,
        }
        Vue.vueDragula.options('detailbag', bagOptions);
    },
    async mounted () {
        this.templates = api.myTemplates;
        if (!api.myTemplates) {
            await api.setMyTemplates();
            this.templates = api.myTemplates;
        }
        if (this.recordStore.StartDate && this.recordStore.EndDate) {
            this.days = moment(this.recordStore.EndDate).diff(moment(this.recordStore.StartDate), 'days') + 1;
        }
        let compressView = localStorage.getItem('activities-view');
        if (compressView == 'EXPANDED') this.compressView = false;
        let self = this;
        Vue.vueDragula.eventBus.$on('drop', async function ([bag, el, target, source]) {
            //console.log(10, el)
            if ($(el).hasClass('day-card')==true) {
                let div = $('#detailed-days')[0].childNodes;
                let daysNrs = [];
                for (let i = 0; i < div.length; i++) {
                    let node = div[i];
                    if (!node) continue;
                    if (!node.getAttribute) continue;
                    let dayNr = node.getAttribute('dayNr');
                    if (!dayNr) continue;
                    if (!self.recordStore) continue;
                    if (!self.recordStore.BookingDays) continue;
                    if (!self.recordStore.BookingDays[dayNr]) continue;
                    if (i!=self.recordStore.BookingDays[dayNr].DayNr) {
                        daysNrs.push(i);
                    }
                    if (dayNr==i) continue;
                    self.recordStore.BookingDays[dayNr].setDayNr(i, i>0);
                }
                if (self.recordStore.StartDate) {
                    await self.recordStore.changeDate(null, false);
                    self.recordStore.changeQuoteDates();
                }
                let dayBag = $(el).parent();
                let bagId = dayBag.attr('bag');
                let vueEvent = Vue.vueDragula.find(bagId);
                if (vueEvent && vueEvent.drake) vueEvent.drake.cancel(true);
                self.recordStore.sortDays();
                await self.recordStore.daysByHotel();
                self.isOver = false;
                self.recordStore.setCostByDate({update: false, daysNrs: daysNrs});
                EventBus.$emit('set-record');
            }
            if ($(el).hasClass('service-drag-card')==true) {
                let oldDayIdx = el.getAttribute('day');
                let newDayBag = $(el).parent();
                let newDayIdx = newDayBag.attr('day');
                let serviceId = el.children[0].getAttribute('serviceId')
                let categoryId = el.children[0].getAttribute('serviceCategoryId')
                let newBagId = newDayBag.attr('bag');
                    //console.log(1, oldDayIdx, newDayIdx)
                if (!oldDayIdx && newDayIdx) {
                    let indexInsert = 0;
                    let services = newDayBag.children();
                    for (let i=0; i<services.length; i++){
                        let service = services[i];
                        if (serviceId==service.children[0].getAttribute('serviceId')) {
                            indexInsert = i;
                        }
                    }
                    let service = _.find(api.tables.service, (r) => r.id == serviceId);
                    if (categoryId) service.ServiceCategoryId = categoryId;
                    let vueEvent = Vue.vueDragula.find(newBagId);
                    if (vueEvent && vueEvent.drake) vueEvent.drake.cancel(true);
                    await self.recordStore.insertService(indexInsert, newDayIdx, service);
                    await self.$nextTick();
                    self.isOver = false;
                    self.recordStore.BookingDays[newDayIdx].setTitle();
                }
                if (oldDayIdx && oldDayIdx!=newDayIdx) {
                    //console.log(2, oldDayIdx, newDayIdx)
                    let indexInsert = 0;
                    let services = newDayBag.children();
                    for (let i=0; i<services.length; i++){
                        let service = services[i];
                        if (serviceId==service.children[0].getAttribute('serviceId')) {
                            indexInsert = i;
                        }
                    }
                    let vueEvent = Vue.vueDragula.find(newBagId);
                    if (vueEvent && vueEvent.drake) vueEvent.drake.cancel(true);
                    await self.recordStore.switchServiceDay(indexInsert, oldDayIdx, newDayIdx, serviceId);
                    await self.$nextTick();
                    self.fixTextAreaStyle(oldDayIdx);
                    self.fixTextAreaStyle(newDayIdx);
                    self.isOver = false;
                }
                if (oldDayIdx==newDayIdx) {
                    let serviceOrders = {};
                    let services = $(el).parent().children();
                    for (let i=0; i<services.length; i++){
                        let service = services[i];
                        let serviceId = service.children[0].getAttribute('serviceId');
                        serviceOrders[serviceId] = i;
                        let s = _.find(self.recordStore.BookingDays[oldDayIdx].BookingDayServices, function(c) {
                            return c.id==serviceId || c.tempId==serviceId;
                        });
                    }

                    let vueEvent = Vue.vueDragula.find(newBagId);
                    if (vueEvent && vueEvent.drake) vueEvent.drake.cancel(true);

                    if (!self.recordStore.BookingDays[oldDayIdx]) return;
                    for (let s of self.recordStore.BookingDays[oldDayIdx].BookingDayServices) {
                        if (serviceOrders[s.id]!=undefined) {
                            s.setOrderNr(serviceOrders[s.id]);
                        } else if (serviceOrders[s.tempId]!=undefined) {
                            s.setOrderNr(serviceOrders[s.tempId]);
                        }
                    }
                    self.isOver = false;
                    await self.$nextTick();
                    self.recordStore.BookingDays[newDayIdx].sortServices();
                    self.recordStore.BookingDays[newDayIdx].setTitle();
                }
            }
        })
        Vue.vueDragula.eventBus.$on('cancel',function() {
            self.isOver = false;
            return true;
        })
        window.addEventListener('scroll', this.onScroll);

    },
    methods: {
        onScroll () {
            var myDiv = document.getElementById('service-selector');
            var myDiv2 = document.getElementById('add-services');
            var scrollPosition = window.scrollY; // La posición de desplazamiento vertical
            var documentHeight = document.documentElement.scrollHeight - window.innerHeight; // Altura del documento menos la altura de la ventana
            
            var scrollPercent = 50; 
            
            var changePoint = (scrollPercent / 100) * documentHeight;

            if (scrollPosition >= changePoint) {
                if (myDiv) myDiv.classList.add('service-selector');
                if (myDiv2) myDiv2.classList.remove('add-services');
            } else {
                if (myDiv) myDiv.classList.remove('service-selector');
                if (myDiv2) myDiv2.classList.add('add-services');
            }
        },
        canMove (el, container, handle) {
            return this.sales;
        },
        canAccepts (el, target) {
            return true;
        },
        async addNewDay (collapse) {
            await this.recordStore.addNewDay()
            if (collapse) {
                await this.$nextTick();
                $('#day' + (this.recordStore.BookingDays.length-1).toString())
                .on('show.bs.collapse', function () {})
                .on('hide.bs.collapse', function () {})
            }
        },
        async insertDayAtBeginning () {
            await this.recordStore.insertDayAtBeginning()
        },
        showTemplateSelector (onlyServices) {
            this.templateSelector = true;
            this.onlyServices = onlyServices;
        },
        async addTemplate (records) {
            api.setProcessing(true);
            let templates = [];
            for (let record of records) {
                let model = await api.importMixinModule('booking', 'model');
                record = await new model({record, isTemplate: true});
                templates.push(record)
            }
            await this.recordStore.addTemplate(templates);
            this.templateSelector = false;
            api.setProcessing(false);
        },
        closeTemplateSelector () {
            this.templateSelector = false;
            this.onlyServices = null;
        },
        fixTextAreaStyle (dayIdx) {
            let areas = $('.services-drag[day=' + dayIdx +'] .ql-container .ql-editor');
            for (let i=0; i<areas.length; i++){
                let area = areas[i];
                let service = _.find(this.recordStore.BookingDays[dayIdx].BookingDayServices, (c) => c.OrderNr==i)
                if (service) {
                    area.innerHTML = service.Description;
                }

            }

        },
        getBGClass (dayId) {
            return '';
            if (dayId % 2 == 0) return 'bg-silver';
            return '';
        },
        async completeDays () {
            api.setProcessing(true);
            await this.recordStore.completeDays();
            api.setProcessing(false);
        },
        async setDays () {
            if (!this.days) return;
            let endDate = moment(this.recordStore.StartDate).add(this.days - 1, 'days').format('YYYY-MM-DD');
            await this.recordStore.setValue({fieldName: 'EndDate', value: endDate});
        }
    },
    watch: {
    },
    beforeDestroy() {
        Vue.vueDragula.eventBus._events = {}
        window.removeEventListener('scroll', this.onScroll);
    },
}
</script>



