<template>
    <div v-if="ready" class="card px-5 py-3 no-border-top">
        <div class="row">
        <div class="tab-summary col-8">
            <div class="form-row">
                <div class="form-group form-material col-md-1">
                    <select-input
                        v-if="options"
                        fieldName="CurrencyId"
                        :fieldOptions="options"
                        label="Currency"
                        :currentValue.sync="recordStore.SummaryCurrencyId"
                        @change="changeCurrency()"
                    ></select-input>
                </div>
            </div>
            <div class="form-row font-weight-bold">
                <label class="col-6">{{tr('Expenses')}}</label>
                <label class="col-2 text-right">{{tr('Quoted')}}</label>
                <label class="col-2 text-right">{{tr('To Pay')}}</label>
                <label class="col-2 text-right">{{(tr('Paid'))}}</label>
            </div>
            <hr class="my-1">
            <div v-for="(supplier, id) of getters.allSuppliers" class="form-row border-bottom" :class="getBGClass(id)">
                <label class="col-6">{{supplier.Name}}</label>
                <label class="col-2 text-right" :style="getColor(id, 'cost')">
                    <span v-if="getters.quoted[id]">{{getters.quoted[id].Cost | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}</span>
                </label>
                <label class="col-2 text-right" :style="getColor(id, 'toPay')" v-if="recordStore.bookingConfirmed">
                    {{getters.totalToPay[id] | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-2 text-right" :style="getColor(id, 'toPay')" v-else>
                </label>
                <label class="col-2 text-right" :style="getColor(id, 'paid')" v-if="recordStore.bookingConfirmed">
                    {{getters.totalPaid[id] | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-2 text-right" :style="getColor(id, 'toPay')" v-else>
                </label>
                <label class="col-12 text-danger" v-if="getters.paidErrors[id] && getters.paidErrors[id].length>0">
                    {{getErrorText(getters.paidErrors[id])}}
                </label>
            </div>
            <div class="form-row font-weight-bold">
                <label class="col-8">{{tr('Total Cost')}}</label>
                <label class="col-4 text-right">
                    {{grossCost | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1" v-if="getters.bookingReceipts.length>0">
            <div class="form-row font-weight-bold" v-if="getters.bookingReceipts.length>0 && recordStore.bookingConfirmed">
                <label class="col-4">{{tr('Incomes')}}</label>
                <label class="col-4 text-right">{{tr('To Receive')}}</label>
                <label class="col-4 text-right">{{tr('Received')}}</label>
            </div>
            <hr class="my-1" v-if="getters.bookingReceipts.length>0 && recordStore.bookingConfirmed">
            <div v-for="(receipt, id) in getters.bookingReceipts" class="form-row" v-if="recordStore.bookingConfirmed">
                <label class="col-4">{{receipt.PaymentDate | toDate}}</label>
                <label class="col-4 text-right" :style="getColor(id,  'AmountStyle')">
                    {{receipt.Amount | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-4 text-right" :style="getColor(id, 'RecAmountStyle')">
                    {{receipt.ReceivedAmount | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-8">{{tr('Total Incomes')}}</label>
                <label class="col-4 text-right">
                    {{getters.finalIncomes | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-8">{{tr('Gross Profit')}}</label>
                <label class="col-4 text-right">
                    {{grossProfit | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-8">{{tr('Other Expenses')}}</label>
                <label class="col-4 text-right">
                    {{getters.totalExpenses | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="spacer"></div>
            <div class="form-row font-weight-bold">
                <label class="col-4">{{tr('File Profit')}}</label>
                <label class="col-4 text-right">{{((getters.profit / getters.finalCost) * 100).toFixed(1)}} %</label>
                <label class="col-4 text-right">{{getters.profit | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}</label>
            </div>
            <div class="form-row font-weight-bold">
                <label class="col-10"></label>
                <div class="col-2" v-if="managerActions">
                    <button type="button" class="btn btn-outline-secondary close-booking-button"
                        @click="closeBooking">
                        {{tr('Close File')}}
                    </button>
                </div>
            </div>
            <hr class="my-1">

            <div class="spacer" v-if="recordStore.ProfitSummary && Object.keys(recordStore.ProfitSummary).length>0">
                <b>
                <div class="form-row bold-row">
                    <label class="col-6 border-bottom">{{tr('Summary')}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Cost">
                    <label class="col-2">{{tr('Outcomes')}}</label>
                    <label class="col-2 text-right">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Cost | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-2">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Incomes">
                    <label class="col-2">{{tr('Incomes')}}</label>
                    <label class="col-2 text-right">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Incomes | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-2">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Profit">
                    <label class="col-2 border-bottom">{{tr('Profit')}}</label>
                    <label class="col-2 text-right border-bottom">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Profit | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-2 border-bottom">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                </b>
            </div>
        </div>
        <div class="col-4 pl-4 pr-0 mt-5">
            <div class="form-row font-weight-bold">
                <label class="col-12">{{tr('Quotations')}}</label>
            </div>
            <div class="ml-4" v-for="(quote, baseId) in recordStore.bases">
                <div v-for="pType of getPaxTypesByBase(baseId)">
                    <div class="form-row" v-if="recordStore.totals && recordStore.totals[baseId] && recordStore.totals[baseId][pType]">
                        <div class="col ">
                            <input v-model="recordStore.Totals[baseId][pType].Selected" type="checkbox"
                                class="form-check-input" @change="setSelected"/>
                            <label v-if="recordStore.totals[baseId][pType].Rooms">
                                {{pType}} {{tr('Room')}} {{getRoomsNames(recordStore.totals[baseId][pType].Rooms)}}
                            </label>
                            <label v-else>{{pType}}</label>
                            <label>
                                : {{getTotalInCurrency(recordStore.totals[baseId][pType].Price) | formatNumber({c: recordStore.PriceCurrencyId})}}
                                {{recordStore.PriceCurrencyId}}
                                {{recordStore.Totals[baseId][pType].Comment}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>


<script>
var EventBus = require('@/tools/event-bus').default;
const bookingSummary = importVueComp('components/booking', 'BookingSummary', 'custom');
export default {
    name: 'custom-booking-summary',
    mixins: [bookingSummary],
    data () {
        return {
            grossProfit: null,
            grossCost: null,
        }
    },
    methods: {
        setGetters () {
            api.setProcessing(true);
            for (let id in this.getters) {
                this.getters[id] = this.recordStore[id];
            }
            this.grossProfit = this.recordStore.grossProfit;
            this.grossCost = this.recordStore.grossCost;
            api.setProcessing(false);
        },
        getPaxTypesByBase (baseId) {
            return this.recordStore.getPaxTypesByBase(baseId);
        },
        setSelected () {
            this.recordStore.setBookingTotals();
            setTimeout(() => {
                this.setGetters();
            }, 550);
        },
        getRoomsNames (rooms) {
            let r = [];
            for (let roomId in rooms) {
                if (rooms[roomId].Checkin) continue;
                if (r.indexOf(rooms[roomId].Name)==-1) {
                    r.push(rooms[roomId].Name);
                }
            }
            return r.join(' / ');
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },

    }
};
</script>
