<template>
    <div v-if="ready" class="app-index">
        <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
            <h3>{{this.recordStore.Title}}</h3>
        </div>

        <div class="container container-body">
            <table class="table table-striped  table-sm table-bordered">
              <thead>
                <tr class="">
                    <td class="text-center" colspan="4">
                        {{tr('Services')}}
                    </td>
                    <td class="text-center" :colspan="bases.length" v-if="!recordStore.HidePriceBreakdown">
                        {{tr('Bases (price per person)')}}
                    </td>
                </tr>
                <tr class="">
                    <td class="col-date text-center">
                        {{tr('Date')}}
                    </td>
                    <td class="px-2 col-city">
                        {{tr('Location')}}
                    </td>
                    <td>
                    </td>
                    <td class="px-2 col-name">
                        {{tr('Description')}}
                    </td>
                    <td class="text-right px-1" v-for="base in bases" v-if="!recordStore.HidePriceBreakdown">
                        {{base}}
                    </td>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="s of services">
                    <td class="col-date text-center bg-white" :rowspan="s.rowsCnt" v-if="s.dayCnt==0">
                        {{getDate(s.TransDate)}}
                    </td>
                    <td class="px-2 col-city bg-white" :rowspan="s.cityRowsCnt" v-if="s.cityCnt==0">
                        <span>{{getCity(s)}}</span>
                    </td>
                    <td>
                        <input type="checkbox" class=""
                            v-model="s.Include"
                            @change="setTotals"
                            :disabled="serviceDisabled[s.id]">
                    </td>
                    <td class="px-2 col-name cursor-pointer" @click="showService(s)">
                        {{s.serviceName }}
                    </td>
                    <td class="text-right px-1" v-for="base in bases" v-if="!recordStore.HidePriceBreakdown">
                        <span v-if="s.bases[base]">{{s.bases[base] | formatNumber({c: recordStore.PriceCurrency, s:3, d: 0})}}</span>
                    </td>
                </tr>
              </tbody>
            </table>
            <table class="mt-3 table table-striped  table-sm table-bordered" v-if="rooms.length > 0">
              <thead>
                <tr class="" >
                    <td class="text-center" colspan="6">
                        {{tr('Hotels')}}
                    </td>
                </tr>
                <tr class="">
                    <td class="col-date text-center">
                        {{tr('Date In')}}
                    </td>
                    <td class="col-date text-center">
                        {{tr('Date Out')}}
                    </td>
                    <td class="px-2">
                        {{tr('Location')}}
                    </td>
                    <td>
                    </td>
                    <td class="px-2">
                        {{tr('Description')}}
                    </td>
                    <td class="px-2" v-if="!recordStore.HidePriceBreakdown">
                        {{('Price per person, per stay')}}
                    </td>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="room of rooms">
                    <td class="col-date text-center bg-white" v-if="room.dayCntHtl == 0" :rowspan="room.rowsCnt">
                        {{getDate(room.TransDate)}}
                    </td>
                    <td class="col-date text-center bg-white"  v-if="room.dayCntHtl == 0" :rowspan="room.rowsCnt">
                        {{getDateOut(room)}}
                    </td>
                    <td class="px-2 bg-white" v-if="room.cityCntHtl == 0" :rowspan="room.cityRowsCnt" >
                        <span>{{room.Hotel.City.Name}}</span>
                    </td>
                    <td>
                        <input type="checkbox" class=""
                            v-model="room.Include"
                            :disabled="roomType && room.Room.id != roomType"
                            @change="setRoom(room)">
                    </td>
                    <td class="px-2 cursor-pointer" @click="showHotel(room.Hotel)">
                        <span v-if="room.Hotel">{{room.Hotel.Name}}</span>
                        <span v-if="room.Hotel && room.HotelCategory"> ({{room.Hotel.HotelCategory.Name}})</span>
                        <span v-if="room.Room"> {{room.Room.Name}} </span>
                        <span v-if="room.Category"> {{room.Category.Name}}</span>
                    </td>
                    <td class="text-right px-2" v-if="!recordStore.HidePriceBreakdown">
                        <span v-if="roomPrices[room.id]">{{roomPrices[room.id] | formatNumber({c: recordStore.PriceCurrency, s:3, d: 0})}}</span>
                    </td>
                </tr>
              </tbody>
            </table>


            <table class="mt-3 table table-striped  table-sm table-bordered" v-if="flights.length > 0">
              <thead>
                <tr class="" >
                    <td class="text-center" colspan="6">
                        {{tr('Flights')}}
                    </td>
                </tr>
                <tr class="">
                    <td>
                    </td>
                    <td class="col-date text-center">
                        {{tr('Itinerary')}}
                    </td>
                    <td class="px-2" v-if="!recordStore.HidePriceBreakdown">
                        {{('Price per person')}}
                    </td>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="flight of flights">
                    <td>
                        <input type="checkbox" class=""
                            v-model="flight.Include"
                            @change="setTotals">
                    </td>
                    <td class="col-date text-center">
                        Suggested Domestical Flight:
                        <div v-for="f of getFlightsList(flight)">
                            {{f}}
                        </div>
                        Non reimbursable rates. Prices can change till the moment of issuing, rates are
                        always subject to availability at the time of the booking request.
                        Aerolineas Argentinas flights include: One up to 15k checked suitcase + one carry on up to 8k +
                        one personal item. (15k is the maximum weight that Aerolineas Airline allows to purchase
                        in advance, if clients have a 23k suitcase they will have to pay the extra weight at the check
                        in desk at the airport the day of the flight, this is Aerolineas Argentinas policy,
                        check more about it on this
                        <a href="https://www.aerolineas.com.ar/en-us/baggage-allowance-usa?tab=excess-baggage" target="_blank"> link </a>).
                        Latam flights include: One up to 23k checked suitcase + one carry on up to 8k + one personal item.
                    </td>
                    <td class="text-right px-2" v-if="!recordStore.HidePriceBreakdown">
                        <span>{{currencyConvert(flight.CurrencyId, flight.FlightPrice) | formatNumber({c: recordStore.PriceCurrency, s:3, d: 0})}}</span>
                    </td>
                </tr>
              </tbody>
            </table>

            <div class="row form-material d-flex align-items-center"  v-if="!recordStore.HideTotalPrice">
                <div class="col-4">
                    <select-input
                        label="Select Base"
                        :currentValue.sync="base"
                        :fieldOptions="baseOptions"
                        :sortInteger="true"
                        @change="setBase"
                    ></select-input>
                </div>
                <div class="col-4" >
                    <select-input
                        v-if="roomsOptions.length > 0"
                        label="Select Room Type"
                        :currentValue.sync="roomType"
                        :fieldOptions="roomsOptions"
                        @change="setRoomType(true)"
                    ></select-input>
                </div>
                <label class="col-4">
                    {{tr('Total per Person')}}:
                    <span v-if="total">{{total | formatNumber({c: recordStore.PriceCurrency, s:3, d: 0})}}</span>
                </label>
            </div>
            <div class="container mt-2 d-flex justify-content-center form-material align-items-baseline">
                <div class="form-default col-3">
                    <input type="text" class="form-control" v-model="email" :class="{'text-danger': email && !mailOk, 'fill': email}">
                        <span class="form-bar"></span>
                        <label class="float-label ml-3">Email (Required)</label>
                    </input>
                </div>
                <button type="button" class="btn btn-outline-primary" @click="requestBooking"
                    :disabled="processing || !mailOk">
                    <span v-if="!processing">{{tr('Request Reservation')}}</span>
                    <i v-else>{{tr('Sending')}}...</i>
                </button>
            </div>
            <div class="container mt-2 d-flex justify-content-center form-material align-items-baseline" v-if="message">
                <h6 class="alert alert-success"><i>{{tr(message)}}</i></h6>
            </div>
            <div class="container mt-2 d-flex justify-content-center form-material align-items-baseline" v-if="error">
                <h6 class="alert alert-danger"><i>{{tr(error)}}</i></h6>
            </div>
            <price-breakdown-service
                v-if="service"
                :s.sync="service"
                :title="serviceTitle"
            ></price-breakdown-service>
            <price-breakdown-hotel
                v-if="hotel"
                :hotel.sync="hotel"
                :title="hotelTitle"
                :languageId="recordStore.LanguageId"
            ></price-breakdown-hotel>
        </div>
    </div>
</template>

<script>
const itineraryFooter = importVueComp('components/itinerary', 'ItineraryFooter');
const priceBreakdownService = importVueComp('components/booking', 'PriceBreakdownService');
const priceBreakdownHotel = importVueComp('components/booking', 'PriceBreakdownHotel');
import { mapState } from 'vuex';
const uuidv1 = require('uuid/v1');
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'price-breakdown',
    props: ['id', 'hash'],
    components: {
        'itinerary-footer': itineraryFooter,
        'price-breakdown-service': priceBreakdownService,
        'price-breakdown-hotel': priceBreakdownHotel,
    },
    data () {
        return {
            ready: false,
            services: [],
            rooms: [],
            roomPrices: {},
            bases: [],
            base: null,
            cnt: 0,
            cnt2: 0,
            baseOptions: [],
            roomType: null,
            roomsOptions: [],
            total: null,
            service: null,
            hotel: null,
            serviceTitle: null,
            hotelTitle: null,
            flights: [],
            message: null,
            processing: false,
            email: null,
            error: null,
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        serviceDisabled () {
            let res = {};
            for (let s of this.services) {
                if (!s.Optional) {
                    //res[s.id] = true;
                }
                if (this.base && !s.bases[this.base]) {
                    res[s.id] = true;
                }
            }
            return res;
        },
        mailOk () {
            if (!this.email) return;
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(this.email.trim()).toLowerCase());
        }
    },
    async mounted (){
        /*api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r;
        if (this.version) {
            r = await api.get('/public/api/bookingversion/' + this.hash + '/' + this.id);
        } else {
            r = await api.get('/public/api/booking/' + this.hash + '/' + this.id);
        }
        if (r) {
            r._public = true;
            let record = await new model({record: r});
            this.$store.commit('initRecord', {record});
        }*/
        for (let i=this.recordStore.PaxFrom;i<=this.recordStore.PaxTo;i++) {
            if (!i) continue;
            this.bases.push(i);
            this.baseOptions.push({value: i, label: i.toString()});
        }
        let services = [];
        let dayCnt = 0;
        let cityCnt = 0;
        let lastCity;
        let lastDate;

        let dayCntHtl = 0;
        let cityCntHtl = 0;
        let lastCityHtl;
        let lastDateHtl;


        this.request = 0;
        for (let day of this.recordStore.BookingDays) {
            for (let s of day.BookingDayServices) {
                s.TransDate = day.TransDate;
                s.Include = true;
                if (s.Optional) s.Include = false;
                if (day.TransDate != lastDate) {
                    dayCnt = 0;
                }
                let city = this.getCity(s);
                if (city != lastCity || day.TransDate != lastDate) {
                    cityCnt = 0;
                }
                s._cityOrder = this.getCityOrder(city, s.TransDate, services);
                s.dayCnt = dayCnt;
                s._city = city;
                s.cityCnt = cityCnt;
                services.push(s);
                dayCnt += 1;
                lastDate = day.TransDate;
                lastCity = city;
                cityCnt += 1;
                this.cnt += 1;
                let quoteDate = day.TransDate;
                if (this.recordStore.SplitQuoteDate && this.recordStore.QuoteDate) {
                    quoteDate = day.QuoteDate;
                }
                this.request += 1;
                this.getServiceCost(s, quoteDate);
            }
            for (let hotel of day.BookingDayHotels) {
                for (let room of hotel.BookingDayRooms) {
                    if (!room.HotelId) continue;
                    this.cnt += 1;
                    room.Include = false;
                    if (room.roomQuoteSelected) room.Include = true;
                    room.TransDate = day.TransDate;
                    room.Days = hotel.Days

                    if (day.TransDate != lastDateHtl) {
                        dayCntHtl = 0;
                    }
                    let cityHtl = room.Hotel.City.Name;
                    if (cityHtl != lastCityHtl || day.TransDate != lastDateHtl) {
                        cityCntHtl = 0;
                    }
                    room.dayCntHtl = dayCntHtl;
                    room._cityHtl = cityHtl;
                    room.cityCntHtl = cityCntHtl;

                    this.rooms.push(room);

                    dayCntHtl += 1;
                    lastDateHtl = day.TransDate;
                    lastCityHtl = cityHtl;
                    cityCntHtl += 1;

                    let quoteDate = day.TransDate;
                    if (this.recordStore.SplitQuoteDate && this.recordStore.QuoteDate) {
                        quoteDate = day.QuoteDate;
                    }
                    this.getHotelCost(hotel.Days, room, quoteDate);
                    if (!room.Room) continue;
                    if (_.find(this.roomsOptions, (r) => r.value == room.Room.id)) continue;
                    this.roomsOptions.push({value: room.Room.id, label: this.tr(room.Room.Name), roomType: room.Room})
                }
            }
            if (this.roomsOptions.length==1) {
                this.roomType = this.roomsOptions[0].value;
            }
        }
        let flights = [];
        for (let flight of this.recordStore.BookingFlights) {
            if (flight.QuoteType == 'INCLUDED') {
                flight.Include = true;
            } else {
                flight.Include = false;
            };
            flights.push(flight)
        }
        this.flights = flights;
        for (let s of services) {
            s.rowsCnt = _.filter(services, (r) => s.TransDate == r.TransDate).length;
            s.cityRowsCnt = _.filter(services, (r) => {
                return s.TransDate == r.TransDate && s._cityOrder == r._cityOrder;
            }).length;
        }

        for (let room of this.rooms) {
            room.rowsCnt = _.filter(this.rooms, (r) => room.TransDate == r.TransDate).length;
            room.cityRowsCnt = _.filter(this.rooms, (r) => {
                return room.TransDate == r.TransDate && room._city == r._city;
            }).length;
        }

        this.services = services;
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getDate (d) {
            if (!d) return '';
            if (!this.recordStore.Language) return;
            let r = moment(d).local().locale(this.recordStore.Language.Code).format('ddd DD MMM YY')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        getDateOut (room) {
            if (!room) return '';
            if (!room.TransDate) return '';
            let d = room.TransDate;
            d = moment(d).add(room.Days, "days");
            if (!this.recordStore.Language) return;
            let r = moment(d).local().locale(this.recordStore.Language.Code).format('ddd DD MMM YY')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        print () {
            print()
        },
        async getServiceCost (service, quoteDate) {
            let cost = await this.recordStore.getServicePriceFromState(service.Service.id, quoteDate, service.ServiceCategoryId, this.recordStore.id);
            service.bases = {};
            let basePending;
            if (cost) {
                let commission;
                if (service.Service.Supplier && service.Service.Supplier.CommisionPrice && service.Service.Supplier.Commision) {
                    commission = service.Service.Supplier.Commision;
                }
                if (service.Service.CommisionPrice && service.Service.Commision) {
                    commission = service.Service.Commision;
                }

                for (let base of this.bases) {
                    let costForBase = travelTools.getCostForBase(cost, base, 'ADT', commission, this.recordStore.QuoteBy);
                    let price = costForBase.cost;
                    if (!price) {
                        basePending = true;
                        continue;
                    };
                    price = this.recordStore.currencyConvert(cost.CurrencyId, this.recordStore.PriceCurrencyId, price);
                    service.bases[base] = price;
                }
            }
            if (!service.Quote) return;
            if (!service.Quote.QuoteList) return;
            for (let baseId in service.Quote.QuoteList.bases) {
                if (!service.Quote.QuoteList[baseId]) continue;
                if (!service.Quote.QuoteList[baseId].ADT) continue;
                let price = service.Quote.QuoteList[baseId].ADT.Price;
                if (!price) continue;
                price = this.recordStore.currencyConvert(this.recordStore.CurrencyId, this.recordStore.PriceCurrencyId, price);
                if (price) {
                    for (let base of this.bases) {
                        if (!service.bases[base]) service.bases[base] = price;
                        if (service.Quote.QuoteList[baseId].ADT.Modified) service.bases[base] = price;
                    }
                }
            }
            this.cnt2 += 1;
        },
        async getHotelCost (days, room, quoteDate) {
            let cost = await this.recordStore.getHotelPriceToApply(days, quoteDate, false, false, room.HotelId, this.recordStore.CurrencyId, this.recordStore.id);
            let roomtypes = await api.getTable('roomtype');
            let roomCost = this.recordStore.getRoomCost(cost, room, room.RoomCategory, days,
                this.recordStore.CurrencyId, this.recordStore.currencyRates,
                roomtypes, days, this.recordStore.QuoteBy);
            let price = roomCost.Cost;
            if (price) {
                price = this.recordStore.currencyConvert(this.recordStore.CurrencyId, this.recordStore.PriceCurrencyId, price);
                this.roomPrices[room.id] = price
            }
            if (!room.QuoteList) return;
            for (let baseId in room.QuoteList.bases) {
                if (!room.QuoteList[baseId]) continue;
                let price = room.QuoteList[baseId].Price;
                if (!price) continue;
                price = this.recordStore.currencyConvert(this.recordStore.CurrencyId, this.recordStore.PriceCurrencyId, price);
                if (price) {
                    if (!this.roomPrices[room.id]) this.roomPrices[room.id] = price
                    if (room.QuoteList[baseId].Modified) this.roomPrices[room.id] = price
                }
            }
            this.cnt2 += 1;
        },
        setRoom (room) {
            this.rooms = Object.assign([], this.rooms)
            this.roomType = room.Room.id;
            this.setRoomType();
        },
        setRoomType (all) {
            for (let row of this.rooms) {
                if (row.Room.id != this.roomType) {
                    row.Include = false;
                } else if (all) {
                    row.Include = true;
                }
            }
            this.setTotals();

        },
        setTotals () {
            if (!this.base) return;
            if (!this.roomType && this.roomsOptions.length > 0) return;
            let total = 0;
            for (let s of this.services) {
                if (!s.Include) continue;
                if (!s.bases[this.base]) continue;
                total += s.bases[this.base];
            }
            for (let room of this.rooms) {
                if (!room.Include) continue;
                if (!room.Room) continue;
                if (room.Room.id != this.roomType) continue;
                if (!this.roomPrices[room.id]) continue;
                total += this.roomPrices[room.id];
            }
            for (let flight of this.flights) {
                if (!flight.Include) continue;
                if (!flight.FlightPrice) continue;
                if (!flight.CurrencyId) continue;
                let price = this.recordStore.currencyConvert(flight.CurrencyId, this.recordStore.PriceCurrencyId, flight.FlightPrice);
                total += price;
            }
            this.total = total;
        },
        async setBase () {
            if (this.base == 1) {
                this.setSingleRoom();
            }
            await this.$nextTick();
            this.setTotals();
        },
        setSingleRoom () {
            let sgl = _.find(this.roomsOptions, (r) => {
                return r.roomType.Pax == 1;
            });
            if (sgl) {
                this.roomType = sgl.value;
                this.setRoomType(true);
            }
        },
        handleClick (event) {
            this.$root.$children[0].handleClick(event);
        },
        getCity (s) {
            if (s.CityId) return s.City.Name;
            if (s.Service && s.Service.City) return s.Service.City.Name;
            return '-';
        },
        getCityOrder (city, transDate, services) {
            let a = [];
            let rows = _.filter(services, (r) => r.TransDate == transDate);
            let lastCity;
            for (let row of rows) {
                if (lastCity != row._city) {
                    a.push(row._city);
                }
                lastCity = row._city;
            }
            if (a[a.length - 1] != city) {
                a.push(city);
            }
            let c = _.filter(a, (r) => r == city).length;
            return city + c;
        },
        showService (s) {
            this.service = s;
            this.serviceTitle = s.serviceName;
        },
        showHotel (h) {
            this.hotel = h;
            this.hotelTitle = h.Name;
        },
        getFlightsList (f) {
            let res = [];
            if (f.Output) {
                let rows = f.Output.split('\n');
                for (let r of rows){
                    res.push(r);
                }
            }
            return res;
        },
        currencyConvert (currencyId, v) {
            return this.recordStore.currencyConvert(currencyId, this.recordStore.PriceCurrencyId, v);
        },
        async requestBooking () {
            let n = {services: [], hotels: [], flights: [], email: this.email};
            if (this.base) n.base = this.base;
            if (this.roomType && this.roomsOptions.length > 0) {
                let room = _.find(this.roomsOptions, (r) => r.value == this.roomType);
                if (room) n.room = room.label;
            }
            for (let s of this.services) {
                if (!s.Include) continue;
                if (!s.bases[this.base]) continue;
                n.services.push({
                    date: this.getDate(s.TransDate),
                    name: s.serviceName,
                    price: s.bases[this.base].toFixed(2),
                })
            }
            for (let room of this.rooms) {
                if (!room.Include) continue;
                if (!room.Room) continue;
                if (room.Room.id != this.roomType) continue;
                if (!this.roomPrices[room.id]) continue;
                n.hotels.push({
                    date: this.getDate(room.TransDate),
                    name: `${room.Hotel.Name} ${room.Room.Name} ${room.Category.Name}`,
                    price: this.roomPrices[room.id].toFixed(2),
                })
            }
            for (let flight of this.flights) {
                if (!flight.Include) continue;
                if (!flight.FlightPrice) continue;
                if (!flight.CurrencyId) continue;
                let price = this.recordStore.currencyConvert(flight.CurrencyId, this.recordStore.PriceCurrencyId, flight.FlightPrice);
                if (price) price = price.toFixed(2)
                n.flights.push({
                    name: flight.Output.split('\n').join(' /// '),
                    price
                });
            }
            if (this.total) n.total = this.total.toFixed(2);
            this.message = null;
            this.error = null;
            this.processing = true;
            let res = await api.post('/api/booking/breakdown_request/' + this.recordStore.id, JSON.stringify({values: n}), null, this.handleError);
            if (res) {
                this.message = 'Message Sent';
                this.processing = false;
            }
        },
        handleError (response) {
            this.error = response.detail;
            this.processing = false;
        },
    },
    watch: {
        cnt2 () {
            if (this.cnt2 > 0 && this.cnt2 == this.cnt) {
                this.ready = true;
                api.setProcessing(false)
            }
        },
        ready () {
            if (this.ready) {
                if (this.bases.length == 1) {
                    this.base = this.bases[0];
                    this.setTotals();
                };
            }
        }
    }

}
</script>

<style scoped>
    thead tr {
        background-color: #d6d8db;
        font-weight: 700;
    }
    .color-blue {
        color: blue;
    }
    a {
        color: blue !important;
    }
</style>