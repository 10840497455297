<template>
    <div class="form-row attach-list p-1" >
        <div class="section-title ml-4 mb-2">
            <label class="my-0 ">
                <span v-if="title">{{tr(title)}}</span>
                <span v-else>{{tr('Attachments')}}</span>
                <span class="attach-icon ml-2 cursor-pointer" v-if="dataList && canAttach">
                    <font-awesome-icon icon="paperclip" v-if="!processing"/>
                    <input type="file" style="display: none;" :disabled="processing" multiple
                    accept="*" @change="uploadFile($event)" id="input-file">
                </span>
            </label>
        </div>
        <div class="col-12">
            <div class="form-row attach-row" v-if="!processing && attached!=null">
                <div class="d-inline card-attach ml-2" v-for="(r, i) of filterList">
                    <label class="cursor-pointer-report">
                        <a @click="getAttach(r)">{{getName(r.name)}}</a>
                    </label>
                    <i class="remove-attach ml-1" @click="remove(r.url)" v-if="canDelete">
                        <font-awesome-icon icon="trash-alt"/>
                    </i>
                </div>

                <div class="card d-inline card-attach ml-2" v-for="(r, i) of list">
                    <div v-if="filter(r)">
                        <label v-if="!processing"><i>{{getName2(r)}}</i></label>
                        <i class="remove-attach ml-1" @click="removeList(i)">
                            <font-awesome-icon icon="trash-alt"/>
                        </i>
                    </div>
                </div>
            </div>
        </div>
        <confirm-delete
            @confirmRemove="removeAttached()"
            :toDelete.sync="toDelete"
            v-if="toDelete"
            label="Delete Attach?"
        ></confirm-delete>

    </div>
</template>

<script>
const confirmDelete = importVueComp('components/tools', 'ConfirmDelete');
import { mapState } from 'vuex';
export default {
    name: 'attach',
    props: ['dataList', 'attached', 'canAttach', 'processing', 'folder', 'id', 'title', 'canDelete'],
    components: {
        'confirm-delete': confirmDelete
    },
    data () {
        return {
            list: [],
            toDelete: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        filterList () {
            let res;
            if (!this.id) return this.attached;
            if (this.folder) {
                res = _.filter(this.attached, (r) => {
                    return r.name.includes(this.folder);
                });
            } else {
                res = _.filter(this.attached, (r) => {
                    let postF = r.name.split(`/${this.id}/`)[1];
                    if (!postF) return;
                    let paths = postF.split('/');
                    if (paths.length==1) return true;
                });
            }
            return res;
        }
    },
    mounted () {
        this.list = this.dataList;
    },
    methods: {
        remove (url) {
            this.toDelete = url;
        },
        closeRemove () {
            this.toDelete = false;
        },
        filter (r) {
            if (!this.id) return true;
            if (this.folder && r.folder && this.folder == r.folder) {
                return true;
            }
            if (!this.folder && !r.folder) {
                return true;
            }
        },
        removeList (i) {
            this.list.splice(i, 1);
            this.$emit('update:dataList', this.list);
        },
        async removeAttached () {
            let url = this.toDelete;
            let res = await api.post('/api/delete_file/', JSON.stringify({FileURL: url}))
            if (res) {
                let a = _.cloneDeep(this.attached);
                let i = _.findIndex(this.attached, r => r.url == url);
                a.splice(i, 1);
                this.$emit('update:attached', a);
                this.$emit('updateAttached', a);
            }
        },
        async uploadFile (event) {
            let input = event.target;
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    if (this.folder) {
                        this.list.push({file: input.files[i], folder: this.folder});
                    } else {
                        this.list.push(input.files[i]);
                    }
                }
                $('#input-file').val('');
                this.$emit('update:dataList', this.list);
            }
        },
        async getAttach (r) {
            let src;
            if (r.key) src = api.serverEndpoint + '/api/get_attach/?key=' + r.key;
            if (!src && r.url) src = api.serverEndpoint + '/api/get_attach/?url=' + r.url;
            window.open(src, '_blank');
            return;
            let win = window.open('');
            let html = "";
            if (appTools.endsWith(url, ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG'])) {
                html = '<html><head><link rel="stylesheet" type="text/css" href="styles.css"></head><body>';
                html += '<img src="' + src + '" style="position: absolute; display: block; margin: auto; left: 0; right: 0; top:0; bottom: 0">';
            } else if (appTools.endsWith(url, ['.PDF', '.pdf'])) {
                html = `<html><head><link rel="stylesheet" type="text/css" href="styles.css"></head><body>`;
                html += `<iframe src="http://docs.google.com/gview?url=`;
                html += src;
                html += `" style="width:718px; height:700px;" frameborder="0"></iframe>`;
            }
            html += '</body></html>';
            win.document.write(html);
        },
        getName (name) {
            if (!name) return 'file';
            let l = name.split('/');
            return l[l.length-1];
        },
        getName2 (r) {
            if (r.name) return r.name;
            if (r.file) return r.file.name;
            return 'file';
        }
    },
    watch: {
        dataList: {
            async handler () {
                this.list = this.dataList;
            },
            deep: true,
        },
    }
}
</script>
