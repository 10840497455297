<template>
    <div class="clearfix mx-3 mb-4 mt-2">
        <div class="float-left" v-if="canAddService">
            <button type="button" class="btn btn-outline-success btn-action-day mx-1"
                @click="showServiceSelector()">
                <i class="mr-2" aria-hidden="true">
                    <font-awesome-icon icon="plus"/>
                </i>{{tr('Add Service')}}
            </button>

            <button type="button" class="btn btn-outline-success btn-action-day mx-1" @click="addBlankService()">
                <i class="mr-2" aria-hidden="true">
                    <font-awesome-icon icon="plus"/>
                </i>{{tr('Add Free Service')}}
            </button>
        </div>
        <div class="float-right" v-if="canRemove">
            <i class="cursor-pointer mr-3" @click="removeDay">
                <font-awesome-icon icon="trash-alt"/>
            </i>
        </div>

        <service-selector-modal
            v-if="serviceSelector"
            :dayNr="dayNr"
            :language="recordStore.languageName"
            :city="getCityId"
            :date="1"
            @addServices="addServices"
            @addTemplate="addTemplateServices"
            @close="closeServiceSelector"
        ></service-selector-modal>
        <booking-day-settings
            v-if="daySettings"
            :compressView="compressView"
            :dayId="dayId"
            :dayNr="dayNr"
            @close="daySettings = false"
        ></booking-day-settings>

    </div>
</template>

<script>
const serviceSelectorModal = importVueComp('components/service', 'ServiceSelectorModal');
const bookingDaySettings = importVueComp('components/booking', 'BookingDaySettings');
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-day-buttons',
    props: ['dayId', 'dayNr', 'compressView'],
    components: {
        'service-selector-modal': serviceSelectorModal,
        'booking-day-settings': bookingDaySettings,
    },
    data () {
        return {
            serviceSelector: false,
            currentService: null,
            daySettings: false,
            currentServiceId: null,
        }
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
            pinText: state => state.booking.pinText,
        }),
        canRemove () {
            return this.canEdit;
        },
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        },
        canAddService () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        },
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        getCityId () {
            let day = this.recordStore.getDay(this.dayNr);
            if (!day) return null;
            let city;
            if (day.BookingDayServices.length>0 && day.BookingDayServices[day.BookingDayServices.length-1].Service &&
              day.BookingDayServices[day.BookingDayServices.length-1].Service.City) {
                return day.BookingDayServices[day.BookingDayServices.length-1].Service.City.id;
            }
            if (this.hotelsByDay[day.DayNr] && this.hotelsByDay[day.DayNr].BookingDayRooms[0]) {
                if (this.hotelsByDay[day.DayNr].BookingDayRooms[0].Hotel) {
                    if (this.hotelsByDay[day.DayNr].BookingDayRooms[0].Hotel.City) {
                        return this.hotelsByDay[day.DayNr].BookingDayRooms[0].Hotel.City.id;
                    }
                }
            }
        },
        changeService () {
            return (this.currentService!=null && this.currentService!=undefined);
        },
    },
    mounted () {
        EventBus.$on('replaceService', this.replaceService);
    },
    methods: {
        async removeDay () {
            $("#day" + this.dayId).collapse('hide');
            api.setProcessing(true);
            await this.recordStore.removeDay(this.dayId);
            api.setProcessing(false);
        },
        closeServiceSelector () {
            this.serviceSelector = false;
        },
        showServiceSelector (idx, serviceId) {
            if (!api.myTemplates) {
                api.setMyTemplates();
            }
            this.serviceSelector = true;
            this.currentService = idx;
            this.currentServiceId = serviceId;
        },
        replaceService (dayNr, idx, serviceId) {
            if (dayNr == this.dayNr) {
                setTimeout(() => {
                    this.showServiceSelector(idx, serviceId);
                }, 1000);
            }
        },
        async addBlankService () {
            await this.recordStore.BookingDays[this.dayId].addBlankService();
        },
        async addServices (records) {
            api.setProcessing(true);
            let list = await this.recordStore.addServices(records, this.changeService, this.dayNr, this.currentService);
            if (this.currentServiceId && this.pinText && this.pinText.id == this.currentServiceId) {
                this.recordStore.BookingDays[this.dayId].BookingDayServices[this.currentService].Description = this.pinText.text;
                this.$store.commit('setPinText', {});
            }
            this.serviceSelector = false;
            api.setProcessing(false);
        },
        async addTemplateServices (template, insert) {
            api.setProcessing(true);
            let model = await api.importMixinModule('booking', 'model');
            let record = await new model({record: template, isTemplate: true});
            await this.recordStore.addTemplateServices(record, this.dayNr, insert);
            this.serviceSelector = false;
            api.setProcessing(false);
        },
    },
    beforeDestroy() {
        EventBus.$off('replaceService', this.replaceService);
    },
}
</script>



