<template>
    <div class="modal fade bd-example-modal-lg" id="service-selector-modal" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="service-selector-modal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-large" role="document">
        <div class="modal-content">
          <div class="modal-header">
                <div class="col-6">
                    <div class="form-row">
                        <div class="form-group col-md-12 section-title mb-0">
                            <label v-if="showTemplates" class="ml-4">{{tr('Add Services from Template since day')}} {{dayNr+1}}</label>
                            <label class="ml-4" v-else>{{tr('Add Service')}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-6 d-flex text-right">
                    <button type="button" class="btn mx-2" :class="getButtonClass(false)"
                        @click="showTemplates = !showTemplates">Servicios</button>
                    <button type="button" class="btn mx-2" :class="getButtonClass(true)"
                        @click="showTemplates = !showTemplates">Templates</button>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>

          </div>
          <div class="modal-body">
            <div class="row w-100">
                <div class="col-12 d-flex justify-content-center">
                    <service-selector
                        v-if="!showTemplates"
                        :dayNr="dayNr"
                        :language="language"
                        :city="city"
                        :date="date"
                        :modal="true"
                        @add="addServices"
                        @close="close"
                    ></service-selector>
                    <template-selector
                        v-if="showTemplates"
                        @add="addTemplate"
                        @close="close"
                        :language="language"
                        :dayNr="parseInt(dayNr)+1"
                    ></template-selector>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
</template>


<script>
const serviceSelector = importVueComp('components/service', 'ServiceSelector');
const templateSelector = importVueComp('components/service', 'TemplateSelector');
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'service-selector-modal',
    mixins: [escape],
    props: ['dayNr','language', 'city', 'date'],
    components: {
        'service-selector': serviceSelector,
        'template-selector': templateSelector
    },
    data () {
        return {
            showTemplates: false,
        }
    },
    mounted () {
        $('#service-selector-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        $("#service-selector-modal").draggable({
            handle: ".modal-header"
        });
    },
    methods: {
        addServices (records){
            this.$emit('addServices', records);
            $('#service-selector-modal').modal('hide');
        },
        addTemplate (records, insertDays){
            this.$emit('addTemplate', records[0], insertDays)
            $('#service-selector-modal').modal('hide');
        },
        getButtonClass (f) {
            if (f==this.showTemplates) return 'btn-primary';
            return 'btn-secondary';
        },
        close () {
            $('#service-selector-modal').modal('hide');
            this.$emit('close');
        },
    },
}
</script>
