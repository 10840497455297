<template>
    <div class="w-100">
        <div class="form-row">
            <div class="form-group col-11">
                <search-box v-model="searchBox"></search-box>
            </div>
            <font-awesome-icon icon="sync" :spin="spin" size="lg" class="col-1" @click="refreshTemplates"/>

        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <button v-for="x in tags" type="button" class="btn btn-secondary btn-service-selected">{{x}}
                  <span aria-hidden="true" class="remove-item" @click="removeTag(x)">&times;</span>
                </button>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <button v-for="s in selected" type="button" class="btn btn-primary btn-service-selected">{{s.Title}}
                  <span aria-hidden="true" class="remove-item" @click="removeTemplate(s)">&times;</span>
                </button>
            </div>
        </div>
        <v-client-table :data="list" :columns="columnsNames" :options="options"
            v-if="dataList"  @row-click="onRowClick">
        </v-client-table>

        <div v-else>
            <img class="loading-gif" src="@/img/loading.gif">
        </div>
        <div class="form-row">
            <div class="form-group col-md-12 text-right">
                <button type="button" class="btn mr-3" :class="{'btn-primary': insertDays, 'btn-secondary': !insertDays}"
                    @click="insertDays = !insertDays">{{tr('Insert Days')}}</button>
                <button type="button" class="btn btn-primary" @click="add" :disabled="selected.length==0">
                    {{tr('Done')}}
                </button>
            </div>
        </div>
        </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm';
import {ClientTable } from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
export default {
    name: 'template-selector',
    props: ['dayNr', 'language'],
    components: {
        ClientTable,
    },
    data () {
        return {
            insertDays: false,
            searchBox: '',
            processing: false,
            spin: false,
            dataList: null,
            columnsNames: ['Title', 'Languages', 'TemplateType', 'SupplierName', 'ServiceType'],
            fields: ['Title', 'Languages',
                'id',
                {name: 'TemplateType', label: 'Template', editor: 'select', hidden: true,
                    options: [
                        {value: 'PUBLIC', label: 'Public', tr: true},
                        {value: 'PRIVATE', label: 'Private', tr: true},
                        {value: 'PACKAGE', label: 'Supplier', tr: true}
                    ]
                },
            ],
            options: {
                filterable: false,
                perPage: 10,
                headings: {
                    'Title': tr('Name'),
                    'TemplateType': tr('Template'),
                    'Languages': tr('Languages'),
                    'SupplierName': tr('Supplier'),
                    'ServiceType': tr('Services')
                },
                destroyEventBus: true,
                texts: vueTools.vueTableText(),
                templates: {},
                skin: 'table table-striped table-sm table-bordered table-bordered-hover',
            },
            fieldOptions: {},
            selected: [],
            tags: []
        }
    },
    computed: {
        search () {
            let res = this.searchBox;
            for (let tag of this.tags){
                res += ' ' + tag
            }
            return res;
        },
        abmFields () {
            return tools.getAbmFields(this.fields);
        },
        list () {
            return tools.abmList(this)
        },
        getTemplates () {
            let self = this;
            let res = {}
            for (let field of self.abmFields) {
                res[field.name] = function(t, row) {
                        return self.getDisplayValue(row, field);
                    }
            }
            return res;
        },
    },
    created () {
        this.selected = [];
    },
    async mounted () {
        this.selected = [];
        this.dataList = api.myTemplates;
        this.fieldOptions = await tools.calculateFieldOptions(this.fields);
        if (this.language) this.tags.push(this.language);
        this.options.templates = this.getTemplates;
    },
    methods: {
        getDisplayValue (record, field) {
            return tools.getDisplayValue(record, field, false, this.fieldOptions, false);
        },
        addTemplate (record) {
            this.selected = [record];
        },
        removeTemplate (record) {
            this.selected = [];
        },
        removeTag (record) {
            var i = this.tags.indexOf(record);
            if (i>-1) this.tags.splice(i, 1)
        },
        add () {
            this.$emit('add', this.selected, this.insertDays);
            this.selected = [];
        },
        async onRowClick (data) {
            let res = await api.get('/api/booking/' + data.row.id);
            if (res) this.selected = [res];

        },
        evenListener (e) {
            if (e.keyCode == 13) {
                let x = document.activeElement;
                if (x){
                    this.tags.push(x.value);
                    this.searchBox = '';
                }
            }
            if (e.keyCode == 27) {
                this.$emit('close');
            }
        },
        async refreshTemplates () {
            this.spin = true;
            setTimeout(async () => {
                await api.setMyTemplates();
                this.dataList = api.myTemplates;
                this.spin = false;

            }, 2000)
        }
    },
    beforeDestroy () {
      document.removeEventListener('keydown',this.evenListener);
    },
    created () {
        setTimeout(()=> document.addEventListener("keydown", this.evenListener), 1000);
    }
}
</script>


