<script>
import Vue from 'vue';
import { mapState } from 'vuex';

let bookingCoverMainExtras = Vue.component('booking-cover-main-extras', {
    props: [],
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    template: `
          <div class="px-3 col-md-2" v-if="recordStore.StartDate && recordStore.StartDate > '2024-31-12'">
              <select-input
                  fieldName="IslandType"
                  :field="recordStore.fieldsObject.IslandType"
                  label="Product Line"
                  :record="recordStore"
                  :addClass="recordStore.invalid.IslandType? 'border-danger': ''"
                  :addBlank="true"
                  :currentValue.sync="recordStore.IslandType"
              ></select-input>
          </div>
    `,
})

const bookingCoverMain = importVueComp('components/booking', 'BookingCoverMain', 'custom');
export default {
    name: 'custom-booking-cover-main',
    mixins: [bookingCoverMain],
    mounted () {
        //window._booking = this.recordStore
        this.extraComp.push({
            component: bookingCoverMainExtras,
            ref: 'bookingCoverMainExtras',
            change: () => {},
        })
    },
}
</script>

<style scoped>
.l-date{
    top: -42px;
    position: relative;
}
</style>

