<template>
    <div class="col-md-12 mb-3">
        <div class="form-row">
              <div class="section-title ml-4">
                  <label>{{tr('Notes')}}</label>
              </div>
        </div>
        <div class="form-row" v-for="(row, rowNr) of notes" :class="getBGClass(rowNr)" v-if="!row.Closed">
            <div class="form-group col-md-3">
                <span>
                {{row.CreatedUser.UserName}}<br>
                {{dateFormat(row.CreatedUTC)}}
                </span>
            </div>
            <div class="form-group col-md-8">
                <span>{{row.Note}}</span>
            </div>
            <div class="form-group col-md-1 text-center">
                <i class="add-item cursor-pointer" @click="deleteNote(rowNr)">
                   <font-awesome-icon icon="trash-alt""/>
                </i>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-operations-notes',
    props: ['supplier'],
    data () {
      return {
        notes: []
      }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    mounted () {
        this.getNotes();
    },
    methods: {
      async deleteNote (i) {
          let n = _.cloneDeep(this.notes[i]);
          n.Closed = true;
          n.original_id = n.id;
          let res = await api.post('/api/bookingnote/', JSON.stringify(n));
          if (res) {
              this.notes[i] = res;
              this.notes = Object.assign([], this.notes);
          }
      },
      async getNotes () {
        let notes = await api.get('/api/bookingnote/', {IncludeClosed: false
            , filters: JSON.stringify({
                BookingId: this.recordStore.id, 
                SupplierId: (this.supplier && this.supplier.Supplier)? this.supplier.Supplier.id: null
            })})
        if (notes) this.notes = notes;
      },
      getBGClass (idx) {
          if (idx % 2 == 0) return 'bg-silver';
          return '';
      },
      dateFormat (d) {
          return moment.utc(d).local().format("DD/MM/YYYY HH:mm");
      },
    },
    watch: {
        supplier: {
            handler () {
                        this.getNotes();
                    },
                    deep: true,
        },
    }
}
</script>
