<template>
    <table width="600" height="50" cellpadding="0" cellspacing="0" border="0" class="container">
        <tbody>
            <tr>
                <td align="center" height="20">
                    <table align="center" style="max-width: 300px; width: 100%" border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td width="100%" align="center">
                                    <a href="#">
                                        <img src="https://raja-app.s3.amazonaws.com/prod/documentation/logo-full.png" width="100%"/></img>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'table-header',
}
</script>
