<template>
  <div class="modal fade" id="input-note" tabindex="-1" role="dialog" aria-labelledby="input-value"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-large form-material">
        <div class="modal-header">
          <h5 class="modal-title">{{tr(text)}}</h5>
        </div>
        <div class="modal-body form-group form-default my-0">
          <textarea
            type="text"
            v-model="value"
            class="text-control"
            :class="addClass"
            rows="6"
            v-if="type=='memo'"
          >
            <span class="form-bar"></span>
          </textarea>
          <input type="text" v-model="value" class="form-control" :class="addClass" :maxlength="maxLength" v-else>
            <span class="form-bar"></span>
          </input>
          <select-input :fieldOptions="supplierList"
              label="Supplier"
              :currentValue.sync="supplierId"
              :addBlank="true"
          ></select-input>
          <select-input :fieldOptions="userList"
              label="User"
              :addBlank="true"
              :currentValue.sync="userId"
          ></select-input>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">{{tr('Done')}}</button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from 'vuex';
export default {
    name: 'input-note',
    mixins: [escape],
    props: ['def', 'showInputValue', 'options', 'text', 'addClass', 'type', 'maxLength', 'defSupplierId', 'defUserId', 'replyId'],
    data () {
        return {
            value: null,
            lastKeyCode: null,
            supplierId: null,
            userId: null,
        }
    },
    mounted () {
        if (this.def) {
            this.value = this.def;
        }
        if (this.defSupplierId) this.supplierId = this.defSupplierId;
        if (this.defUserId) this.userId = this.defUserId;
        $('#input-note').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        suppliers () {
            return this.recordStore.suppliers;
        },
        hotels  () {
            return this.recordStore.hotels;
        },
        flights () {
            return this.recordStore.flights;
        },
        supplierList () {
            let res = [];
            for (let id in this.suppliers) {
                res.push({label: this.suppliers[id].Supplier.Name, value: id})
            }
            for (let id in this.hotels) {
                res.push({label: this.hotels[id].Supplier.Name, value: id})
            }
            for (let id in this.flights) {
                res.push({label: this.flights[id].Supplier.Name, value: id})
            }
            return res;
        },
        userList () {
            let res = [];
            for (let user of api.tables.user) {
                if (user.Closed) continue;
                res.push({label: user.UserName, value: user.id})
            }
            return res;
        }
    },
    methods: {
        save (record) {
            this.$emit('save', this.value, this.supplierId, this.userId, this.replyId);
            $('#input-note').modal('hide');
        },
        close () {
            this.$emit('close');
            $('#input-note').modal('hide');
        },
    },
}
</script>
