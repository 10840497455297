<template>
    <section id="faq" class="faq">
      <div class="container" v-if="ready">
        <div class="row" v-if="!recordStore.ShowFormLink">

          <div class="col-lg-12 mb-5 mt-2 text-justify" v-if="langCode=='it'">
              <i>E’ fatto obbligo al viaggiatore di comunicare tempestivamente all’organizzatore, via mail e con annesso consenso al trattamento dei dati,  tutte le forme di patologia che possano in qualsiasi maniera rappresentare un rischio attuale e concreto per la salute del viaggiatore e che possano anche solo consigliare l’organizzatore stesso nel senso della modifica di una qualsiasi delle attività descritte nel day by day appena sopra accettato. In caso di mancata comunicazione in tal senso rimane inteso che nessuna condizione medica ostacoli lo sviluppo dell’itinerario di viaggio oggetto delle prestazioni di Raja Tours Indonesia.</i>
          </div>
          <div class="col-lg-12 mb-5 mt-2 text-justify" v-else-if="langCode=='es'">
              <i>Es obligación del viajero comunicar puntualmente al organizador, por correo electrónico y con el consentimiento adjunto al tratamiento de los datos, toda forma de patología que pueda representar de algún modo un riesgo actual y concreto para la salud del viajero y que incluso sólo pueda aconsejar al organizador la modificación de alguna de las actividades descritas en el día a día recién aceptado. En caso de no existir comunicación en este sentido, se entenderá que ninguna condición médica obstaculiza el desarrollo del itinerario de viaje sujeto a los servicios de Raja Tours Indonesia</i>
          </div>
          <div class="col-lg-12" id="accept">
              <div class="col-12">
                   <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="terms" :disabled="accepted">
                   <label class="custom-control-label ml-2" for="customCheck1">{{tr('I accept')}}
                      <a href="https://www.indonesiainfinita.com/condiciones-de-contratacion/" target="_blank"
                          v-if="recordStore.Agency && recordStore.Agency.Code == 'IND-INF'">
                          {{tr('the general terms and conditions of sale')}} ({{tr('travel contract')}})
                      </a>
                      <a href="https://www.rajatoursindonesia.com/condizioni-generali" target="_blank" v-else>
                          {{tr('the general terms and conditions of sale')}} ({{tr('travel contract')}})
                      </a>
                   </label>
              </div>
              <div class="col-12">
                   <input type="checkbox" class="custom-control-input" id="customCheck2" v-model="policy" :disabled="accepted">
                   <label class="custom-control-label ml-2" for="customCheck2">{{tr('I accept')}}
                      <a :href="policyLink" target="_blank">
                          {{tr('the privacy policy')}}
                      </a>
                   </label>
              </div>
              <div class="col-12">
                   <input type="checkbox" class="custom-control-input" id="customCheck3" v-model="itinerary" :disabled="accepted">
                   <label class="custom-control-label ml-2" for="customCheck3">{{tr('I accept')}} {{tr('the itinerary')}}
                   </label>
              </div>
              <div class="col-12 mt-2" v-if="!accepted">
                   <button type="button" class="btn btn-send w-100" @click="submit" :disabled="!terms || !policy || !itinerary || processing">
                      <span v-if="!processing">{{tr('Send')}}</span>
                      <img class="processing-submit" src="@/img/loading.gif" v-else>
                   </button>
              </div>
          </div>
          <div class="col-lg-12 alert alert-success mt-2" v-if="id && ready && accepted">
              <h4>{{accepted}}</h4>
          </div>
          <div class="col-lg-12 alert alert-success" v-if="message">
              <h4>{{message}}</h4>
          </div>

        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
                <a :href="recordStore.FormLink" target="_blank">
                    <h4>{{recordStore.FormLinkLabel}}</h4>
                </a>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
const itineraryConditions = importVueComp('components/itinerary', 'ItineraryConditions', 'custom');
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-conditions',
    mixins: [itineraryConditions],
    data () {
        return {
            policy: false,
            terms: false,
            itinerary: false,
            processing: false,
            ready: false,
            accepted: null,
            message: null,
        }
    },
    async mounted () {
        if (this.id && this.version) {
            let res = await api.get(`/public/api/accepted/${this.id}`);
            if (res && res.date) {
                let d = moment(res.date).local().format('DD MMM YYYY HH:mm');
                this.accepted = `${this.tr('Accepted on')} ${d} hs`;
                this.policy = true;
                this.terms = true;
                this.itinerary = true;
            }
        }
        this.ready = true;
    },
    computed:{
        policyLink () {
            if (this.langCode == 'it') return 'https://www.rajatoursindonesia.com/privacy-policy';
            if (this.langCode == 'es') return 'https://www.rajatoursindonesia.com/privacy-policy';
            return 'https://www.rajatoursindonesia.com/privacy-policy';
        }
    },
    methods: {
        async submit () {
            if (!this.id) return;
            this.response = null;
            this.responseError = null;
            this.processing = true;
            let res = await api.post(`/public/api/accept_version/${this.id}`, {})
            if (res && res.date) {
                let d = moment(res.date).local().format('DD MMM YYYY HH:mm');
                this.accepted = `${this.tr('Accepted on')} ${d} hs`;
                this.message = this.tr('Reservation made');
            }
        },

    },
    watch: {
    }
}
</script>

<style>
    a {
        color: #007bff;
    }
    input[type="checkbox"]:disabled + label::before {
        background: #007bff !important;
    }
</style>