<template>
  <div>
      <div v-if="field.editor=='checkbox'" class="form-check row pb-3" :class="{'mt-3': !report, 'pl-5': report}">
          <div class="col-12">
              <input
                v-model="value"
                :name="field.name"
                class="form-check-input"
                :class="classes"
                type="checkbox"
                :disabled="disabled"
                :readonly="disabled"
                :id="field.name"
                @change="afterEdit"
              >
              <label :for="field.name" class="smaller ml-1 form-check-label" v-if="!field.hideLabel">{{tr(field.label)}}</label>
          </div>
          <div class="invalid-feedback" v-if="invalidMessage">
            {{invalidMessage}}
          </div>
      </div>
      <div v-else-if="field.editor=='text-area'" class="form-group form-default">
          <textarea
            v-model="value"
            :name="field.name"
            class="text-control"
            :class="classes"
            :rows="field.rows || 3"
            :disabled="disabled"
            :readonly="disabled"
            :id="field.name"
            @blur="afterEdit"
          ></textarea>
          <span class="form-bar"></span>
          <label :for="field.name" v-if="!field.hideLabel" class="float-label">{{tr(field.label)}}</label>
      </div>
      <div v-else-if="field.type=='password' && !field.hidden"  class="form-group form-default" >
          <input
            v-model="value"
            class="form-control"
            type="password"
            :class="classes"
            :id="field.name"
            onfocus="this.removeAttribute('readonly');"
            readonly
            @blur="afterEdit">
                <span class="form-bar">
                </span>
                <label v-if="!field.hideLabel" class="float-label">{{tr(field.label)}}</label>
            </input>
            <div class="invalid-feedback" v-if="invalidMessage">
              {{invalidMessage}}
            </div>
      </div>
      <div v-else-if="field.editor=='number'">
          <number-input
            :class="addClass"
            class="pb-0"
            :v.sync="value"
            :d="field.decimal?field.decimal: 0"
            :addClass="classes"
            :disabled="disabled"
            :readonly="disabled"
            :currency="field.currency? record[field.currency]: null"
            :id="field.name"
            :label="!field.hideLabel? field.label: null"
            :hideLabel="field.hideLabel"
            @change="afterEdit"
          ></number-input>
          <div class="invalid-feedback" v-if="invalidMessage">
            {{invalidMessage}}
          </div>
      </div>
      <div v-else-if="field.editor=='vue-select' && options">
          <vue-select-input
            :fieldName="field.name"
            :fieldOptions="options"
            :label="!field.hideLabel? field.label: null"
            :class="classes"
            :currentValue.sync="value"
            :required="field.required"
            :disabled="disabled"
            :readonly="disabled"
            :field="field"
            :addBlank="field.addBlank"
            :record="field.endpointFilters? record: null"
            :sort="field.sort"
            @change="afterEdit"
          ></vue-select-input>
          <div class="invalid-feedback" v-if="invalidMessage">
            {{invalidMessage}}
          </div>
      </div>
      <div v-else-if="field.editor=='select' && options" >
          <select-input
              :fieldName="field.name"
              :fieldOptions="options"
              :label="!field.hideLabel? field.label: null"
              :addClass="classes"
              :currentValue.sync="value"
              :required="field.required"
              :disabled="disabled"
              :readonly="disabled"
              :notSort="field.notSort"
              :field="field"
              :addBlank="field.addBlank"
              :icons="field.icons"
              :record="field.endpointFilters? record: null"
              @change="afterEdit"
          ></select-input>
          <div class="invalid-feedback" v-if="invalidMessage">
            {{invalidMessage}}
          </div>
      </div>
      <div v-else-if="field.editor=='datetime' && loaded" >
          <label class="mb-0" :for="field.name" v-if="!field.hideLabel">{{tr(field.label)}}</label>
          <m-date-time
              type="datetime"
              :value.sync="value"
              format="YYYY-MM-DDTHH:mm:ss"
              :disabled="disabled"
              :disablePast="field.disablePast"
              @change="afterEdit"
          ></m-date-time>
          <div class="invalid-feedback" v-if="invalidMessage">
            {{invalidMessage}}
          </div>
      </div>
      <div v-else-if="field.editor=='date'" >
          <date-picker
            :label="!field.hideLabel? field.label: null"
            :id="field.name"
            :fieldName="field.name"
            :def.sync="value"
            :disabled="disabled"
            :disablePast="field.disablePast"
            :readonly="disabled"
            :addClass="classes"
            :record="record"
            @change="afterEdit"
          ></date-picker>
          <div class="invalid-feedback" v-if="invalidMessage">
            {{invalidMessage}}
          </div>
      </div>
      <div v-else-if="field.editor=='photo-div'" >
          <photo-div
            :showDelete="true"
            :label="field.label"
            :image.sync="image"
            :imageId.sync="value"
            @change="afterEdit"
          ></photo-div>
      </div>
      <div v-else-if="field.editor=='button'" class="d-flex align-items-center">
        <button type="button" class="btn btn-primary" :disabled="disabled" @click="buttonClick">
            <font-awesome-icon :icon="field.icon" v-if="field.icon"/>
            <span v-else>{{tr(field.label)}}</span>
        </button>
      </div>
      <div v-else-if="field.editor=='attach'" class="card">
          <attach :dataList.sync="attachList" :attached="attachedList" :canAttach="true"
              @updateAttached="updateAttached"
              :canDelete="canDeleteAttach"></attach>
      </div>
      <div v-else-if="field.editor=='html'">
          <html-view :value.sync="defValue"></html-view>
      </div>
      <div v-else-if="field.editor=='color'">
          <color-picker
            :def.sync="value"
            @change="afterEdit"
            :label="!field.hideLabel? field.label: null"
          ></color-picker>
      </div>
      <div v-else-if="field.editor=='text-area-style' && loaded" >
          <label :for="field.name" v-if="!field.hideLabel">{{tr(field.label)}}</label>
          <text-area-style
            @change="afterEdit"
            :text.sync="value"
          ></text-area-style>
      </div>
      <div v-else-if="field.editor=='i-frame'" >
          <i-frame
            :url="field.url"
          ></i-frame>
      </div>
      <div v-else-if="field.editor=='link'" class="pt-3 pl-5">
          <a :href="value" target="_blank" v-if="value" class="text-primary">{{tr(field.label)}}</a>
      </div>
      <div v-else-if="field.editor=='mask'"  class="form-group form-default" :class="addClass">
          <input
            v-model="value"
            :name="field.name"
            class="form-control"
            :class="classes"
            type="text"
            :disabled="disabled"
            :readonly="disabled"
            :id="field.name"
            v-mask="field.format"
            @blur="afterEdit">
              <label v-if="!field.hideLabel" class="float-label">{{tr(field.label)}}</label>
            </input>
            <div class="invalid-feedback" v-if="invalidMessage">
              {{invalidMessage}}
            </div>
      </div>
      <div v-else-if="field.editor=='time'" class="form-group form-default" :class="addClass">
          <input
            v-model="value"
            :name="field.name"
            class="form-control fill"
            :class="classes"
            type="time"
            :disabled="disabled"
            :readonly="disabled"
            :id="field.name"
            @blur="afterEdit">
              <label v-if="!field.hideLabel" class="float-label">{{tr(field.label)}}</label>
            </input>
            <div class="invalid-feedback" v-if="invalidMessage">
              {{invalidMessage}}
            </div>
      </div>
      <div v-else-if="field.editor=='label'" class="d-flex flex-row" :class="classes">
          <html-tag
            v-for="(t, i) of field.tags"
            :key="i"
            :tag="t.tag"
            :value="t.value || value[t.key]"
            :tr="t.tr"
            :class="t.class"
          ></html-tag>
      </div>
      <div class="form-group form-default" :class="addClass"
        v-else-if="(!options || !options[field.name]) && !field.hidden && !Array.isArray(field.editor) && field.editor!='component' && field.editor!='tab'" >
          <input
            class="form-control"
            v-model="value"
            :name="field.name"
            :class="classes"
            type="text"
            :disabled="disabled"
            :readonly="disabled"
            :id="field.name"
            :maxlength="field.length"
            @blur="afterEdit">
                <span class="form-bar"></span>
                <label v-if="!field.hideLabel" class="float-label">{{tr(field.label)}}</label>
            </input>
            <div class="invalid-feedback" v-if="invalidMessage">
              {{invalidMessage}}
            </div>
      </div>
      <div v-if="field.list && listValues && listValues.length > 0" class="form-row px-1 mb-3 pt-3 field-list d-flex align-items-center">
            <font-awesome-icon v-if="hideList" icon="chevron-right" class="list-values mr-2" @click="hideList = false"/>
            <font-awesome-icon v-else icon="chevron-down" class="list-values mr-2" @click="hideList = true"/>
            <div v-if="field.list && !hideList" v-for="v of listValues" class="mr-2 pr-1 field-value">
                <label class="list-values my-0 mr-1">{{getLabelValue(v)}}</label>
                <font-awesome-icon icon="trash-alt" class="list-values" @click="removeListValue(v)"/>
            </div>
            <div v-if="hideList">...</div>
        </div>
  </div>
</template>


<script>
const htmlView = importVueComp('components/tools', 'HtmlView')
const htmlTag = importVueComp('components/tools', 'HtmlTag')
import {mask} from 'vue-the-mask';
export default {
    name: 'input-field',
    props: [ 'field', 'defValue', 'fieldOptions', 'attachList', 'attached', 'imageObject', 'maxLength', 'report',
        'relationObject', 'invalid', 'record', 'readonly', 'addClass', 'canDeleteAttach', 'currency', 'defHideList'],
    components: {
        'html-view': htmlView,
        'html-tag': htmlTag
    },
    directives: {
        mask
    },
    data () {
        return {
            value: null,
            loaded: false,
            image: null,
            listValues: [],
            //options: null,
            hideList: this.defHideList,
        }
    },
    async mounted () {
        if (!this.field.list) {
            this.value = this.defValue;
        } else if (this.defValue) {
            this.listValues = this.defValue.split(',');
        }
        if (this.imageObject) this.image = this.imageObject;
        let self = this;
        this.$nextTick(() => {
            self.loaded = true;
        })
    },
    asyncComputed: {
        async options () {
            let options;
            if (this.fieldOptions && this.fieldOptions.length) {
                options = _.cloneDeep(this.fieldOptions);
            } else if (this.field) {
                let fieldOptions = await tools.calculateFieldOptions([this.field], null, this.record, this.field.showClosed, true);
                options = fieldOptions[this.field.name];
            }
            if (!options) return;
            let res = options;
            if (this.field.setRelationTo && this.defValue && this.relationObject) {
                let p = _.find(options, (r) => r.value == this.defValue);
                if (!p) {
                    let label;
                    if (typeof(this.field.optionLabels) === 'function') {
                        label =  this.field.optionLabels(this.relationObject);
                    } else {
                        label = this.relationObject[this.field.optionLabels];
                    }
                    res.push({value: this.defValue, label: label});
                    res = Object.assign([], res);
                }
            }
            return res;
        },
        invalidMessage () {
            if (this.invalid && typeof this.invalid === 'string') {
                return this.invalid;
            }
        }

    },
    computed: {
        classes () {
            let r = [];
            if (this.field.classes) r.push(this.field.classes);
            if (this.invalid) r.push('border-danger');
            if (this.invalidMessage) r.push('is-invalid');
            if (this.disabled) r.push('disabled');
            if (this.readonly) r.push('disabled');
            if (this.value) r.push('fill');
            if (this.field.list && this.listValues.length>0) r.push('mb-0');
            return r.join(' ');
        },
        step () {
            let d = this.field.decimal? this.field.decimal: 0;
            return 1 / Math.pow(10, parseInt(d));
        },
        disabled () {
            if (this.field.disabled) return true;
            if (this.field.disabledIf) return this.field.disabledIf(this.record);
            if (this.readonly) return true;
            if (this.field.readonly) return true;
            if (this.field.readonlyIf) return this.field.readonlyIf(this.record);
            return false;
        },
        attachedList () {
            return this.attached;
        }
    },
    watch: {
        defValue () {
            this.value = this.defValue;
        },
        image () {
            this.$emit('update:imageObject', this.image);
        },
    },
    methods: {
        async getOptions () {
            let options;
            if (this.fieldOptions && this.fieldOptions.length) {
                options = _.cloneDeep(this.fieldOptions);
            } else if (this.field) {
                let fieldOptions = await tools.calculateFieldOptions([this.field], null, this.record, null, true);
                options = fieldOptions[this.field.name];
            }
            if (!options) return;
            let res = options;
            if (this.field.setRelationTo && this.defValue && this.relationObject) {
                let p = _.find(options, (r) => r.value == this.defValue);
                if (!p) {
                    let label;
                    if (typeof(this.field.optionLabels) === 'function') {
                        label =  this.field.optionLabels(this.relationObject);
                    } else {
                        label = this.relationObject[this.field.optionLabels];
                    }
                    res.push({value: this.defValue, label: label});
                    res = Object.assign([], res);
                }
            }
            this.options = res;
        },
        async buttonClick () {
            await this.field.buttonClick(this.record);
        },
        async afterEdit () {
            if (this.field.list) {
                if (this.value && this.listValues.indexOf(this.value)==-1) {
                    this.listValues.push(this.value);
                }
                await this.$nextTick();
                this.$emit('update:defValue', this.listValues.join(','));
                this.$emit('change', null, this.field.name, this.listValues.join(','));
                setTimeout(()=> this.value = null, 1)
            } else {
                await this.$nextTick();
                this.$emit('update:defValue', this.value);
                this.$emit('change', null, this.field.name, this.value);
            }
        },
        getLabelValue (v) {
            if (this.field.editor=='select' || this.field.editor=='vue-select') {
                let option = _.find(this.options, (r) => r.value == v);
                if (option) {
                    return option.label;
                }
            }
            return v;
        },
        removeListValue (v) {
            this.listValues.splice(this.listValues.indexOf(v), 1 );
            this.$emit('update:defValue', this.listValues.join(','));
            this.$emit('change', null, this.field.name, this.listValues.join(','));
            //this.$emit('afterEdit', this.listValues.join(','), this.field.name, this.field.name);
        },
        updateAttached (a) {
            this.$emit('update:attached', a);
        }
    }
}
</script>

<style>
.list-values {
  font-size: 9pt;
}
.field-value {
    text-wrap: nowrap !important;
}
.field-list {
    margin-top: -10px;
}
</style>