
<script>
import '@/extra/custom/css/remixicon/remixicon.css';
import '@/extra/custom/css/style.scss';
const itinerary = importVueComp('components/itinerary', 'Itinerary', 'custom');
export default {
    name: 'custom-itinerary',
    mixins: [itinerary],
    mounted () {
        let dataTheme = 'root';
        let p = this.$router.currentRoute.meta.public;
        if (p && this.recordStore && this.recordStore.Agency && this.recordStore.Agency.IconId) {
            document.title = this.recordStore.Agency.Name;
            const link = document.querySelector("[rel='icon']")
            link.setAttribute('href', this.recordStore.Agency.Icon.url)
        }
        if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.Code) {
            dataTheme = this.recordStore.Agency.Code;
        }
        document.documentElement.setAttribute("data-theme", dataTheme);
    },

}
</script>
