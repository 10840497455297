<template>
    <div class="">
      <div class="form-row">
          <div class="col-12 form-row pt-1">
              <input-field
                  :class="{'col-12': compressView, 'col pl-3': !compressView}"
                  :field="service.fieldsObject.Name"
                  :defValue.sync="service.Name"
                  :readonly="!canEdit"
                  :maxLength="service.fieldsObject.Name.length"
              ></input-field>
              <div class="col">
                <vue-select-input
                  fieldName="SupplierId"
                  label="Supplier"
                  :field="service.fieldsObject.SupplierId"
                  :currentValue.sync="service.SupplierId"
                  :disabled="!!(!canEdit || service.Service.SupplierId)"
                ></vue-select-input>
              </div>
              <div class="col">
                <vue-select-input
                  fieldName="CityId"
                  label="City"
                  @change="setCity"
                  :field="service.fieldsObject.CityId"
                  :currentValue.sync="service.CityId"
                  :disabled="!!(!canEdit || service.Service.CityId)"
                ></vue-select-input>
              </div>
              <div class="col pt-2">
                  <i class="float-right cursor-pointer mx-2" @click="removeService"
                      data-toggle="tooltip" data-placement="top" :title="tr('Remove Service')"
                      v-if="canRemove">
                      <font-awesome-icon icon="trash-alt"/>
                  </i>
                  <i class="float-right cursor-pointer mx-2" @click="setImages"
                      :class="{'icon-active': service._images}"
                      data-toggle="tooltip" data-placement="top" :title="tr('Edit Images')"
                      v-if="canEdit && (!service.Service || !service.Service.NotDescription)">
                      <font-awesome-icon icon="image" class="service-actions"/>
                  </i>
                  <i class="float-right cursor-pointer mx-2" @click="setServiceVoucher(rowIdx, dayNr)"
                      data-toggle="tooltip" data-placement="top" :title="tr('Voucher')"
                      v-if="recordStore.notTemplate && recordStore.bookingConfirmed && canEdit">
                      <font-awesome-icon icon="ticket-alt" class="service-actions" v-if="!service.VoucherDisabled"/>
                      <svg-voucher-slash class="service-actions" v-else></svg-voucher-slash>
                  </i>
                  <i class="float-right cursor-pointer mx-2" @click="setOptional"
                      :class="{'icon-active': service.Optional}"
                      data-toggle="tooltip" data-placement="top" :title="tr('Additional')"
                      v-if="recordStore.notTemplate && canEdit">
                      <!--font-awesome-icon icon="ad" class="service-actions"/-->
                      <svg-optional class="service-actions"></svg-optional>
                  </i>
                  <i class="float-right cursor-pointer mx-2" @click="setEntranceIncluded"
                      :class="{'icon-active': service.EntranceIncluded}"
                      data-toggle="tooltip" data-placement="top" :title="tr('Entrance Included')"
                      v-if="canEdit">
                      <svg-ticket class="service-actions"></svg-ticket>
                  </i>
                  <i class="float-right cursor-pointer mx-2" @click="replaceService"
                      data-toggle="tooltip" data-placement="top" :title="tr('Replace Service')"
                      v-if="recordStore.notTemplate && recordStore.bookingConfirmed && canEdit" >
                      <font-awesome-icon icon="exchange-alt" class="service-actions"/>
                  </i>
              </div>
          </div>
      </div>
      <div v-if="!service.Service || !service.Service.NotDescription">
          <input-field
              id="serviceTitle"
              class="px-2"
              addClass="mb-1"
              :field="service.fieldsObject.Title"
              :defValue.sync="serviceTitle"
              :readonly="!canEdit"
              @change="setServiceTitle"
              :maxLength="service.fieldsObject.Title.length"
          ></input-field>
          <text-area-edit-style
              addClass="no-border"
              :disabled="!canEdit && !operActions"
              tag="p"
              :showPin="true"
              :extras="extraButtons"
              v-if="!compressView"
              :text.sync="service.Description"
              :pasteText="service._Description"
           ></text-area-edit-style>
          <text-area-style
              :disabled="!canEdit && !operActions"
              class="no-border"
              :showPin="true"
              :extras="extraButtons"
              v-else
              :text.sync="service.Description"
              :pasteText="service._Description"
          ></text-area-style>
      </div>
      <div class="form-row form-material">
          <div class="col-12 form-row ml-3 px-3">
              <div class="col form-check ml-1" v-for="(option, optionId) of checkOptions">
                  <input v-model="service[optionId]" :name="optionId"
                  :disabled="!canEditOptions"
                  class="form-check-input" type="checkbox" :id="optionId">
                  <label class="smaller form-check-label">{{tr(option)}}</label>
              </div>
          </div>
      </div>
      <div class="form-row mt-2 pt-2 border-top px-2" v-if="carRental && service.BookingDayServiceCar">
          <div class="col-3 pl-4 pt-2 d-flex align-items-center">
              <div class="section-title">
                  <label class="">
                    {{tr('Car Rental')}}
                  </label>
              </div>
          </div>
          <div class="col-3 pt-2">
              <vue-select-input
                fieldName="PickupAddressId"
                label="Pickup Address"
                ref="pickUpAddressId"
                :record="service"
                :field="service.BookingDayServiceCar.fieldsObject.PickupAddressId"
                :currentValue.sync="service.BookingDayServiceCar.PickupAddressId"
              ></vue-select-input>
          </div>
          <div class="col-3 pt-2">
              <div class="form-group form-default">
                  <input
                    v-model="service.BookingDayServiceCar.PickupTime"
                    class="form-control fill"
                    type="time">
                      <label class="float-label">{{tr('Pickup Time')}}</label>
                    </input>
              </div>
          </div>
          <div class="col-3 pt-2">
              <vue-select-input
                fieldName="DropOffCityId"
                label="Drop Off City"
                :field="service.BookingDayServiceCar.fieldsObject.DropOffCityId"
                :currentValue.sync="service.BookingDayServiceCar.DropOffCityId"
                @change="setDropCity"
              ></vue-select-input>
          </div>
          <div class="col-3">
              <vue-select-input
                fieldName="DropOffAddressId"
                label="Drop Off Address"
                ref="dropAddressId"
                :record="service"
                :field="service.BookingDayServiceCar.fieldsObject.DropOffAddressId"
                :currentValue.sync="service.BookingDayServiceCar.DropOffAddressId"
              ></vue-select-input>
          </div>
          <div class="col-3">
              <date-picker :def.sync="dropOffDate" id="dropOffDate"
                  :startDate="service.ServiceDate"
                  label="Drop Off Date" class="" labelClass=""
                  @change="setDropOffDate"
              ></date-picker>
          </div>
          <div class="col-3">
              <number-input
                  :v.sync="service.BookingDayServiceCar.Days"
                  label="Days"
                  :d="0"
                  @change="setDays"
              ></number-input>
          </div>
          <div class="col-3">
              <div class="form-group form-default">
                  <input
                    v-model="service.BookingDayServiceCar.DropOffTime"
                    class="form-control fill"
                    type="time">
                      <label class="float-label">{{tr('Drop Off Time')}}</label>
                    </input>
              </div>
          </div>
          <div class="col-3" v-for="field of carExtras">
              <div class="form-check">
                  <input v-model="service.BookingDayServiceCar[field.name]" :name="field.label"
                  class="form-check-input" type="checkbox" :id="field.name">
                  <label class="smaller form-check-label">{{tr(field.label)}}</label>
              </div>

          </div>
      </div>
      <div class="card-body images-container form-row card-header "
            v-if="service._images || (service.BookingDayServiceImages && service.BookingDayServiceImages.length>0)">
            <div class="form-row col-12 section-title ml-3">
                <label>{{tr('Images')}}</label>
            </div>
            <div class="form-row col-12">
                <div class="col-md-3" v-for="(image, imgId) of service.BookingDayServiceImages">
                    <photo-div
                      ref="photoDiv"
                      addClass="image-xs"
                      :showDelete="true"
                      :disabled="!canEdit"
                      :image.sync="service.BookingDayServiceImages[imgId].Image"
                      :imageId.sync="service.BookingDayServiceImages[imgId].ImageId"
                      @remove="service.BookingDayServiceImages.splice(imgId, 1)"
                      @newImage="newImage"
                    ></photo-div>
                </div>
                <div class="col-1 d-flex align-items-end add-image-plus cursor-pointer" v-if="canEdit">
                  <font-awesome-icon icon="plus-circle" @click="addNewServiceImage()"/>
                </div>
            </div>
      </div>
      <day-service-pickup
          v-if="recordStore.bookingConfirmed && !carRental"
          :service.sync="service"
          :canEdit="canEdit"
      ></day-service-pickup>
    </div>
</template>

<script>
const textAreaEditStyle = importVueComp('components/tools', 'TextAreaEditStyle');
const dayServicePickup = importVueComp('components/booking', 'DayServicePickup');
import syncIcon from '@/components/svg/Sync';
import svgTicket from '@/components/svg/Ticket';
import svgOptional from '@/components/svg/Optional';
import voucherSlash from '@/components/svg/VoucherSlash';
import svgPin from '@/components/svg/Pin';
import { mapState } from 'vuex';

export default {
    name: 'day-service',
//    mixins: [escape],
    props: ['service', 'rowIdx', 'dayId', 'dayNr', 'compressView'],
    components: {
      'text-area-edit-style': textAreaEditStyle,
      'svg-ticket': svgTicket,
      'svg-voucher-slash': voucherSlash,
      'day-service-pickup': dayServicePickup,
      'svg-optional': svgOptional,
    },
    data () {
        return {
            currentDay: null,
            serviceSelector: false,
            currentService: null,
            serviceTitle: null,
            dropOffDate: null,
            checkOptions: {
                Breakfast: 'Breakfast',
                Lunch: 'Lunch',
                Dinner: 'Dinner',
                Snack: 'Snack',
                BoxLunch: 'Box Lunch',
                EntranceIncluded: 'Entrance Included'
            },
            extraButtons: [
                {
                    comp: svgPin,
                    name: 'svg-pin',
                    callback: (self) => {
                        let pin = $('.ql-svg-pin');
                        if (pin[0]) pin[0].classList.toggle("text-danger");
                        if (this.service && this.service.id) {
                            this.$store.commit('setPinText', {
                                id: this.service.id,
                                text: self.getText(),
                            });
                        }

                    }
                },
                {
                    comp: syncIcon,
                    name: 'sync-icon',
                    callback: () => {
                        if (!this.canUpdateDescription) return;
                        if (this.service && this.service.Service && this.service.Service.ServiceDescriptions) {
                            let d = _.find(this.service.Service.ServiceDescriptions, (r) => r.LanguageId == this.recordStore.LanguageId);
                            if (d) {
                                this.service.Description = d.Description;
                                this.service._Description = d.Description;
                            }
                        }
                    }
                },

            ]

        }
    },
    mounted () {
        if (!this.service.Name) this.service.Name = this.service.serviceRowName;
        if (this.service.BookingDayServiceCar && this.service.BookingDayServiceCar.Days) {
            this.setDays();
        }
        this.serviceTitle = this.service.title;
        /*setTimeout(() => {
            this.$root.$children[0].$refs.inApp.push({
                divId: 'serviceTitle', pos: 'bottom', text: tr('Service title editable'), x: -250, validTo: '2024-01-31'
            })
        }, 500);*/
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            operActions: state => state.main.operActions,
            quillFocus: state => state.quill.quillFocus,
            quillStatus: state => state.quill.quillStatus,
            recordStore: state => state.main.record,
            managerActions: state => state.main.managerActions,
        }),
        canRemove () {
            return this.canEdit;
        },
        canUpdateDescription () {
            return this.canEdit;
        },

        canEdit () {
            if (this.recordStore.isTemplate) return true;
            if (!tools.canAccess(api.currentUser, 'api','GET', 'edit_booking_service', true)) return false;
            return this.salesActions || this.managerActions || this.operActions;
        },
        canEditOptions () {
            return this.canEdit;
        },
        carRental () {
            return this.service.Service && this.service.Service.CarRental;
        },
        carExtras () {
            if (!this.carRental) return [];
            return _.filter(this.service.BookingDayServiceCar.fields, (r) => r.editor == 'checkbox');
        }
    },
    methods: {
        async setImages () {
            await this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].setImages();
        },
        async addNewServiceImage () {
            //this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].addNewServiceImage()
            let rowNr = this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].BookingDayServiceImages.length;
            await this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].addNewServiceImage()
            if (this.$refs.photoDiv[rowNr]) {
                this.$refs.photoDiv[rowNr].showPhotoPicker();
            }
        },
        setServiceVoucher (i, dayNr) {
            let day = this.recordStore.getDay(dayNr);
            day.BookingDayServices[i].setServiceVoucher();
        },
        setOptional () {
            this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].Optional = !this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].Optional;
            if (this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].Optional) {
                this.recordStore.BookingDays[this.dayId].ShowOptionals = true;
            }
        },
        setEntranceIncluded () {
            this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].EntranceIncluded = !this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].EntranceIncluded;
        },
        removeService () {
            this.$emit('removeService');
        },
        replaceService () {
            this.$emit('replaceService');
        },
        async newImage (img) {
            let rowNr = this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].BookingDayServiceImages.length;
            await this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].addNewServiceImage();
            let row = this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].BookingDayServiceImages[rowNr];
            row.Image = img;
            row.ImageId = img.id;
        },
        async setDropOffDate () {
            if (!this.dropOffDate) {
                await this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].BookingDayServiceCar.setValue({fieldName: 'Days', value: null});
            } else {
                let days = 1 + (moment(this.dropOffDate).diff(moment(this.service.ServiceDate))/ (24 * 60 * 60 * 1000));
                await this.recordStore.BookingDays[this.dayId].BookingDayServices[this.rowIdx].BookingDayServiceCar.setValue({fieldName: 'Days', value: days});
            };
        },
        setDays () {
            this.dropOffDate = moment(this.service.ServiceDate).add(this.service.BookingDayServiceCar.Days - 1, 'days').format('YYYY-MM-DD');
        },
        setCity () {
            if (this.carRental) {
                this.service.BookingDayServiceCar.PickupAddressId = null;
                this.$refs.pickUpAddressId.refresh = !this.$refs.pickUpAddressId.refresh;
            }
        },
        setDropCity () {
            if (this.carRental) {
                this.service.BookingDayServiceCar.DropOffAddressId = null;
                this.$refs.dropAddressId.refresh = !this.$refs.dropAddressId.refresh;
            }
        },
        setServiceTitle () {
            this.service.Title = this.serviceTitle;
        }
    },
}
</script>



