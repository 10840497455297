<template>
  <div v-if="loaded">
      <div v-if="rowField.editor=='text-area'" >
          <label :for="rowField.name" v-if="rowNr==0 || labelAlways">{{tr(rowField.label)}}</label>
          <textarea
            v-model="value"
            :name="rowField.name"
            class="text-control"
            :class="classes"
            rows="3"
            :disabled="fieldDisabled"
            :readonly="rowField.readonly"
            :id="id"
            @blur="afterEdit"
          ></textarea>
      </div>
      <div v-else-if="rowField.editor=='checkbox'" class="form-check ml-3">
          <input
            v-model="value"
            :name="rowField.name"
            class="form-check-input"
            :class="classes"
            type="checkbox"
            :disabled="fieldDisabled"
            :readonly="rowField.readonly"
            :id="id" @focus="focusOn"
            @change="afterEdit">
          <label :for="rowField.name" class="smaller form-check-label" v-if="rowNr==0 || labelAlways">{{tr(rowField.label)}}</label>
      </div>
      <div v-else-if="rowField.editor=='vue-select'" >
          <label :for="rowField.name" v-if="rowNr==0 || labelAlways">{{tr(rowField.label)}}</label>
          <vue-select-input
              :fieldName="rowField.name"
              :fieldOptions="options"
              :currentValue.sync="value"
              :required="rowField.required"
              :disabled="fieldDisabled"
              :sort="rowField.sort"
              :addBlank="rowField.addBlank"
              :id="id"
              :class="classes"
              @focus="focusOn"
              @change="afterEdit"
          ></vue-select-input>
      </div>
      <div v-else-if="rowField.editor=='select'">
          <select-input
              :fieldName="rowField.name"
              :fieldOptions="options"
              :labelTop="(rowNr && !labelAlways)? '': rowField.label"
              :currentValue.sync="value"
              :required="rowField.required"
              :disabled="fieldDisabled"
              :id="id"
              :addBlank="rowField.addBlank"
              :class="classes"
              @focus="focusOn"
              @change="afterEdit"
          ></select-input>
      </div>
      <div v-else-if="rowField.editor=='datetime'" >
            <label :for="rowField.name" v-if="rowNr==0 || labelAlways">{{tr(rowField.label)}}</label>
            <m-date-time type="datetime" :value.sync="value" format="YYYY-MM-DDTHH:mm:ss" @change="afterEdit">
            </m-date-time>
      </div>
      <div v-else-if="rowField.editor=='date'" >
          <label :for="rowField.name" v-if="rowNr==0 || labelAlways">{{tr(rowField.label)}}</label>
          <date-picker
            :id="id"
            :fieldName="rowField.name"
            :record="row"
            :def.sync="value"
            :class="classes"
            :disabled="fieldDisabled"
            @focus="focusOn"
            @change="afterEdit"
          ></date-picker>
      </div>
      <div v-else-if="rowField.editor=='photo-div'" >
          <photo-div
            :showDelete="true"
            :image.sync="image"
            :imageId.sync="value"
            @change="afterEdit"
          ></photo-div>
      </div>
      <div v-else-if="rowField.editor=='icon'" class="d-flex align-items-center">
          <font-awesome-icon :icon="rowField.icon" size="lg" class="mr-1"/>
          <span style="color: black" v-if="rowField.label" class="">{{value}}</span>
      </div>
      <div v-else-if="rowField.editor=='label'" class="d-flex flex-row" :class="rowField.classes">
          <html-tag
            v-for="(t, i) of rowField.tags"
            :key="i"
            :tag="t.tag"
            :value="t.value || value[t.key]"
            :ttr="t.tr"
            :class="t.class"
          ></html-tag>
      </div>
      <div v-else-if="rowField.editor=='number'">
          <label :for="rowField.name" v-if="rowNr==0 || labelAlways">{{tr(rowField.label)}}</label>
          <number-input
            :v.sync="value"
            :d="rowField.decimal?rowField.decimal: 0"
            :disabled="fieldDisabled"
            :readonly="rowField.readonly"
            :id="id"
            :class="classes"
            :currency="currency"
            @focus="focusOn"
            @change="afterEdit"
            ></number-input>
      </div>
      <div v-else-if="rowField.editor=='button' && !showElse" class="text-center">
        <button type="button" class="btn btn-primary btn-sm template-button" :disabled="fieldDisabled"
            @click="afterEdit" data-toggle="tooltip" data-placement="top" :title="rowField.label" :class="rowField.classes">
            <font-awesome-icon :icon="rowField.icon" v-if="rowField.icon"/>
            <span v-else>{{tr(rowField.label)}}</span>
        </button>
      </div>
      <div v-else-if="rowField.editor=='component' && rowField.inline" class="form-group form-default text-center" :class="classes">
          <component
            v-if="componentField"
            :is="componentField"
            :extras="extras"
            :record.sync="row"
            :field="rowField"
            @change="afterEdit"
          ></component>
      </div>
      <div v-else-if="rowField.editor!='select'" class="form-group form-default text-center" :class="classes">
          <label :for="rowField.name" v-if="rowNr==0 || labelAlways">{{tr(rowField.label)}}</label>
          <input
            v-model="value"
            :name="rowField.name"
            class="form-control"
            :class="rowField.inputClass"
            type="text"
            :id="id"
            :disabled="fieldDisabled"
            @blur="afterEdit"
            @click="afterClick"
            :readonly="rowField.readonly || showElse">
                <span class="form-bar"></span>
            </input>
      </div>
  </div>
</template>


<script>
const htmlTag = importVueComp('components/tools', 'HtmlTag');
import { mapState } from 'vuex';
export default {
    name: 'row-input-field',
    props: [ 'rowField', 'arrayField', 'defValue', 'row', 'rowNr', 'getId', 'disabled', 'invalid',
        'labelAlways', 'Image', 'fieldOptions'],
    components: {
      'html-tag': htmlTag
    },
    data () {
        return {
            value: null,
            image: null,
            loaded: false,
        }
    },
    asyncComputed: {
        async options () {
            let options;
            if (this.fieldOptions && this.fieldOptions.length) {
                options = _.cloneDeep(this.fieldOptions);
            } else if (this.rowField) {
                let fieldOptions = await tools.calculateFieldOptions([this.rowField], null, this.row, null, true);
                options = fieldOptions[this.rowField.name];
            }
            let res = options;
            if (this.rowField.setRelationTo && this.row[this.rowField.name]) {
                let p = _.find(options, (r) => r.value == this.row[this.rowField.name]);
                if (!p) {
                    let label;
                    if (typeof(this.rowField.optionLabels) === 'function') {
                        if (this.row[this.rowField.setRelationTo]) {
                            label =  this.rowField.optionLabels(this.row[this.rowField.setRelationTo]);
                        }
                    } else {
                        label = this.row[this.rowField.setRelationTo][this.rowField.optionLabels];
                    }
                    res.push({value: this.row[this.rowField.name], label});
                    res = Object.assign([], res);
                }
            }
            return res;
        },
        async extras () {
            let res;
            if (this.rowField.extras) {
                res = await this.rowField.extras(this.row);
                if (res) return res;
            }
        }
    },
    computed: {
        componentField () {
            if (this.rowField.componentName) {
                let c = importVueComp(this.rowField.componentPath, this.rowField.componentName);
                return c;
            }
        },
        fieldDisabled () {
            if (this.disabled) return true;
            if (this.rowField.disabled) return true;
            if (this.rowField.readonly) return true;
            if (this.rowField.disabledIf) return this.rowField.disabledIf(this.row);
        },
        id () {
            if (this.getId) return this._uid;
            return (this.arrayField.name + '-' + this.rowField.name + '-' + this.rowNr);
        },
        showElse () {
            if (!this.rowField.showElse) return false;
            return this.rowField.showElse(this.row);
        },
        step () {
            let d = this.field.decimal? this.field.decimal: 0;
            return 1 / Math.pow(10, parseInt(d));
        },
        classes () {
            let res = ['mt-0'];
            if (this.rowField.classes) res.push(this.rowField.classes)
            if (this.invalid) res.push('border-danger');
            return res;
        },
        currency () {
            if (this.rowField.currencyField) {
                if (this.row[this.rowField.currencyField]) {
                    return _.find(api.tables.currency, (r) => r.id == this.row[this.rowField.currencyField]);
                }
            }
        }
    },
    async mounted () {
        this.value = this.defValue;
        if (this.Image) this.image = this.Image;
        this.loaded = true;
    },
    watch: {
        defValue () {
            this.value = this.defValue;
        },
        image () {
            this.$emit('update:Image', this.image);
        },
    },
    methods: {
        async afterClick () {
            if (this.showElse) {
                await this.afterEdit();
            }
        },
        async afterEdit () {
            if (this.rowField.action) {
                let fieldName =  this.arrayField.fieldName? this.arrayField.fieldName: this.arrayField.name;
                await vueTools.action(this, [fieldName, this.rowNr], this.rowField.action);
                //this.rowField.action(this.row, this)
                return;
            }
            await this.$nextTick();
            this.$emit('update:defValue', this.value);
            let fieldName =  this.arrayField.fieldName? this.arrayField.fieldName: this.arrayField.name;
            this.$emit('change', [fieldName, this.rowNr], this.rowField.name, this.value);
        },
        focusOn (value) {
            this.$emit('focus', value? value: this.value, this.id, this.arrayField.name, this.rowField.name, this.rowNr);
        }

    }
}
</script>
