<template>
    <div class="r-style sp-style">
      <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900" rel="stylesheet">
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      <header>
           <div class="container">
              <nav class="navbar navbar-expand-md navbar-dark">
                 <a class="navbar-brand" href="#">
                    <async-img path="extra/custom/img" width="150" img="logo-full.png"></async-img>
                 </a>
                 <div class="collapse navbar-collapse" id="navbarSupportedContent">
                 </div>
               </nav>
           </div>
      </header>
      <div class="container app-index" v-if="record && record.Survey">
          <section v-if="record.Survey.Intro" class="d-flex justify-content-center">
              <text-rows-style :text="record.Survey.Intro" :langCode="langCode"></text-rows-style>
          </section>
          <section v-for="row of record.SurveyPaxQuestions" class="my-5 form-material">
              <span class="mb-0">{{trl(row.SurveyQuestion.Label)}}</span>
              <survey-pax-question
                  class="form-group form-default my-0"
                  :record.sync="row"
                  :langCode="langCode"
              ></survey-pax-question>
          </section>
          <section v-if="record.Survey.Footer" class="d-flex justify-content-center">
              <text-rows-style :text="record.Survey.Footer" :langCode="langCode"></text-rows-style>
          </section>
          <section>
             <div class="container d-flex justify-content-center">
                <div class="row">
                    <div class="col-12 row mt-2 d-flex justify-content-center alert alert-success" v-if="response">
                        <label>{{response}}</label>
                    </div>
                    <div class="col-12 row mt-2 d-flex justify-content-center alert alert-danger" v-if="responseError">
                        <label>{{responseError}}</label>
                    </div>
                    <div class="col-12 row mt-2">
                         <button type="button" class="btn btn-send w-100" @click="submit" :disabled="processing">
                            <span v-if="!processing">{{trl('Send')}}</span>
                            <img class="processing-submit" src="@/img/loading.gif" v-else>
                         </button>
                    </div>
                </div>
             </div>
          </section>
      </div>
      <footer>
      </footer>
    </div>
</template>

<script>
//import '@/css/app.scss';
//import '@/css/sp-style.scss';
//import '@/extra/custom/css/sp-style.scss';
const surveyPaxQuestion = importVueComp('components/tools', 'SurveyPaxQuestion');
export default {
    name: 'survey-pax-view',
    props: ['id', 'hash', 'langCode'],
    data () {
        return {
            record: null,
            processing: false,
            response: null,
            responseError: null,
        }
    },
    components: {
        'survey-pax-question': surveyPaxQuestion
    },
    methods: {
        trl (t) {
            return tr(t, this.langCode);
        },
        async submit () {
            this.response = null;
            this.responseError = null;
            this.processing = true;
            let result = await api.save('/api/surveypax/', this.record, null, 'surveypax', (r) => {
                console.log(r.err)
                this.responseError = this.trl(r.err);
            });
            if (result) {
                this.record = result;
                this.processing = false;
                this.response = this.trl('Record saved');
            }
        },
    },
    async mounted () {
        this.record = await api.get('/api/surveypax/get_hash/' + this.hash + '/' + this.id);
    },
    computed: {
    },
    watch: {
    }
}
</script>

