<template>
    <div>
        <booking-info></booking-info>
        <div class="card no-border-top">
            <div class="card-header">
                <div class="form-row form-material">
                    <div class="form-check col-md-2 pl-3">
                        <div class="form-check form-group form-default">
                            <input v-model="recordStore.ShowFormLink" class="form-check-input" type="checkbox"
                             id="ShowFormLink">
                            <label for="ShowFormLink" class="smaller form-check-label">{{tr('Show Form Link')}}</label>
                        </div>
                    </div>
                    <div class="col-md-4 px-3">
                        <input-field
                            v-if="recordStore.ShowFormLink"
                            :field="recordStore.fieldsObject.FormLinkLabel"
                            :defValue.sync="recordStore.FormLinkLabel"
                            :maxLength="recordStore.fieldsObject.FormLinkLabel.length"
                        ></input-field>
                    </div>
                    <div class="col-md-6 px-3">
                        <input-field
                            v-if="recordStore.ShowFormLink"
                            :field="recordStore.fieldsObject.FormLink"
                            :defValue.sync="recordStore.FormLink"
                            :maxLength="recordStore.fieldsObject.FormLink.length"
                        ></input-field>
                    </div>
                </div>

                <div class="form-row form-material" v-if="recordStore.bookingConfirmed">
                    <div class="form-group form-default col-md-6  section-title">
                        <label class="mx-4">{{tr('Invoice Data')}}</label>
                        <textarea
                          v-model="recordStore.InvoiceData"
                          class="text-control"
                          rows="5"
                        ></textarea>
                        <span class="form-bar"></span>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-1 px-3 mt-3">
                        <div class="form-row">
                            <number-input
                                label="Number"
                                class="col-4"
                                :v.sync="recordStore.InvoiceNumber"
                                :d="0"
                            ></number-input>
                            <number-input
                                label="Year"
                                class="col-4"
                                :v.sync="recordStore.InvoiceYear"
                                :d="0"
                            ></number-input>
                            <input-field
                                class="col-4"
                                :field="recordStore.fieldsObject.InvoiceDate"
                                :defValue.sync="recordStore.InvoiceDate"
                            ></input-field>
                            <input-field
                                class="col-12"
                                :field="recordStore.fieldsObject.CustomerText"
                                :defValue.sync="recordStore.CustomerText"
                                :maxLength="recordStore.fieldsObject.CustomerText.length"
                            ></input-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
const bookingInfo = importVueComp('components/booking', 'BookingInfo', 'custom');
import { mapState } from 'vuex';
export default {
    name: "custom-booking-info",
    components: {
        'booking-info': bookingInfo
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
};
</script>


