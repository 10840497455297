<template>
      <report-window endpoint="/admin/reports/get_credit/"
          :fields="fields"
          :title="cardTitle || title"
          :headers="headers"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          v-if="ready"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :columnsClasses="columnsClasses"
          :columnClick="columnClick"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :slotsFields="slotsFields"
          :callbacks="callbacks"
          :totalsOn="totalsOn"
      >
      </report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
  name: 'on-account-list-report',
  props: ['options', 'cardTitle'],
  components: {
    ReportWindow,
  },
  computed: {
    columnsClasses () {
        return {
            Amount: 'text-right',
            Balance: 'text-right',
            CurrencyRate: 'text-right',
            CreditId: 'cursor-pointer',
        }
    },
    totalsOn () {
        let res = [];
        if (this.current.CurrencyId) {
            return ['Balance'];
        }
        return null;
    }

  },
  mounted () {
    frontTools.setReportValues(this);
    this.ready = true;
  },
  data () {
    return {
        current: {},
        ready: false,
        fields: [
            {
                name: 'FromDate', 
                editor: 'date', 
                label: 'Due Date From', 
                //required: true
            },
            {
                name: 'ToDate', 
                editor: 'date', 
                label: 'Due Date To', 
                //required: true
            },
            { name: 'CurrencyId', label: 'Currency', editor: 'select', optionLabels: 'id', relation: 'currency',
                addBlank: true
            },
            { name: 'SupplierId', relation: 'supplier', optionLabels: 'Name', label: 'Supplier', addBlank: true, editor: 'vue-select'},
            { name: 'Balance', label: 'Balance', editor: 'select', required: true, defValue: '0',
                options: [
                    { value: '0', label: 'Only With Balance'},
                    { value: '1', label: 'All'},
                ]
            },
        ],
        title: 'Credit',
        headers: {
            Balance: 'Balance',
            SupplierId: 'Supplier',
            CurrencyRate: 'Currency Rate',
            PaxName: 'Pax Name',
            Name: 'Name',
            PaymentId: 'Payment Number',
            BookingId: 'File Nr',
            PaymentCurrencyId: 'Payment Currency',
            SupplierCreditId: 'Credit Number',
            CurrencyId: 'Currency',
            payOff: 'Pay Off'
        },
        templates: [
            {
                name: 'CurrencyRate', callback: (row) => {
                    if (!row.CurrencyRate) return '';
                    return tools.toNumber(row.CurrencyRate, 0);
                }
            },
            {
                name: 'Date', callback: (row) => {
                    if (!row.Date) return '';
                    return moment(row.Date).format("DD/MM/YYYY");
                }
            },
            {
                name: 'DueDate', callback: (row) => {
                    if (!row.DueDate) return '';
                    return moment(row.DueDate).format("DD/MM/YYYY");
                }
            },
            {
                name: 'Balance', callback: (row) => {
                    if (row.calculated) {
                        return tools.toNumber(row.Balance, 0);
                    }
                    return tools.toNumber(-row.Balance, 0);
                }
            },
        ],
        slotsFields: [
            {name: 'payOff', editor: 'button', label: 'Ver Saldo', icon: 'plus',
                classes: 'mb-0', inputClass: ' text-center cursor-pointer',
            }
        ],
        columnClick: {
            CreditId: this.showCredit,
        },
        callbacks: {
            'payOff': async (fieldName, row, report) => {
                let paymentType = _.find(api.tables.paymenttype, (c) => c.OnAccount == true)
                let modalRecord = {
                    SupplierId: row.SupplierId,
                    PaymentStatus: 'USED',
                    SupplierCreditId: row.CreditId,
                    Amount: -row.Balance,
                    PaymentAmount: -row.Balance,
                    PaymentCurrencyId: row.CurrencyId,
                    CurrencyId: row.CurrencyId,
                    PaymentDate: moment(new Date()).format("YYYY-MM-DD"),
                    DueDate: moment(new Date()).format("YYYY-MM-DD")
                }
                if (paymentType) {
                    modalRecord.PaymentTypeId = paymentType.id
                    modalRecord.PaymentType = paymentType
                }
                this.$root.$children[0].openModalRecord({
                    modalRecord, modalTable: 'bookingpayment', modalId: "new"
                });
            },
        },
    }
  },
  methods: {
      showCredit (row) {
          this.$root.$children[0].openModalRecord({modalTable: 'suppliercredit', modalId: row.CreditId });
      }
  }
}
</script>

