import DocumentationView from '@/extra/custom/components/documentation/DocumentationView';
const pageBooking = importVueComp('components/pages', 'PageBooking');
const bookingWindow = importVueComp('components/booking', 'BookingWindow');

function getCustomRoutes () {
    let res = [];
    res.push({ path: '/public/documentation/:hash/:id', name: 'documentation', component: DocumentationView, props: true,
      meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}});
    res.push({ path: '/abm/bookingcatalogue', name: 'abm-booking-catalogue', component: pageBooking,
        props: {template: 'NONE', sales: true, title: 'Catalogue', status: 'CATALOGUE', table: 'catalogue'}})
    res.push({ path: '/abm/bookingcatalogue/:id?', name: 'booking-catalogue', component: bookingWindow, props: true})
    return {routes: res, remove: []};
}

export default getCustomRoutes();