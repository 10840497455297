<template>
    <header>
         <div class="container">
            <nav class="navbar navbar-expand-md navbar-dark">
               <a class="navbar-brand" href="#">
                  <img width="150" src="@/extra/custom/img/raja_logo.webp"></img>
               </a>
               <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                   <span class="navbar-toggler-icon"></span>
               </button>
             </nav>
         </div>
    </header>
</template>

<script>
export default {
    name: 'reservation-form-header',
}
</script>