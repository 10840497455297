<template>
  <div class="d-flex justify-content-center">
     <form class="form-inline celeste header-row" action="">
        <div class="form-group col-md-12">
           <div class="label">{{tr('Add Ticket')}}</div>
           <input type="file" class="form-control" @change="uploadTicket($event)" :id="'flight-' + i" multiple>
           <i class="text-white">{{tr('Maximum allowed file size')}} {{maxSize}} Mb</i>
         </div>
    </form>
  </div>
</template>

<script>
const reservationFormFlight = importVueComp('components/reservationform', 'ReservationFormFlight', 'custom');
export default {
    name: 'reservation-form-flight',
    mixins: [reservationFormFlight],
}
</script>