
<script>
const creditListReport = importVueComp('components/reports', 'CreditListReport', 'custom');
export default {
    name: 'custom-credit-list-report',
    mixins: [creditListReport],
    data () {
        let r = creditListReport.data();
        let currency = _.find(r.fields, (c) => c.name == 'CurrencyId')
        if (currency) {
            currency.defValue = 'IDR';
        }
        return {
            fields: r.fields
        }
    },
    methods: {
    }
}
</script>

