<script>
const bookingReceipts = importVueComp('components/booking', 'BookingReceipts', 'custom');
export default {
    name: 'custom-booking-receipts',
    mixins: [bookingReceipts],
    data () {
        return {
            updateExpenses: false,
        }
    },
    methods: {
        async setReceipts () {
            this.processingReceipts = true;
            await api.setReceipts(this.recordStore.id);
            this.receipts = api.receipts;
            this.processingReceipts = false;
            if (this.updateExpenses) {
                let total = this.recordStore.totalExpenses;
                await this.recordStore.setExpenses();
                let newTotal = this.recordStore.totalExpenses;
                if (total != newTotal) {
                    this.recordStore.notifications.warning = tr('Expenses updated. Please save changes on Booking');
                    this.recordStore.notifications = Object.assign({}, this.recordStore.notifications);
                }
            }
        },
        receiptSaved (value, table) {
            if (table == 'bookingreceipt') {
                this.updateExpenses = true;;
            }
        },
        openInvoice (paymentId) {
            let name = 'booking-invoice';
            let receipt = _.find(this.receipts, (r) => r.id == paymentId);
            if (receipt && receipt.ReceiptType == 'REFUND') {
                name = 'refund-invoice';
            }
            let route = this.$router.resolve({
                name,
                params: {
                    hash: this.recordStore.Hash,
                    id: this.recordStore.id,
                    payment: paymentId
                }
            })
            window.open(route.href, '_blank');
        },

    },
}
</script>

