<template>
    <div id="fstyles">
        <div v-if="recordStore && !processing">
            <button type="button" class="btn btn-primary action-button button-fixed" @click="printPDF">
                <font-awesome-icon icon="print"/>
            </button>


            <div class="container title-box">
                <div class="row">
                    <div class="col-12">
                        <div class="box-border-bottom">
                            <!--img src="@/extra/custom/img/iso.png" alt="aotg logo" style="width: 30px"-->
                        </div>
                    </div>
                    <div class="col-12">
                        <h1>Itinerary & Confirmation</h1>
                    </div>
                    <div class="col-12">
                        <div class="box-border-top"></div>
                    </div>
                </div>
            </div>

            <booking-final-preview
            ></booking-final-preview>
            <br>
            <div class="container">
                <div class="col-12 info-box">
                    <h6>{{tr('Terms & Conditions')}}</h6>
                    <div class="info-box-body">
                        <p>Following the
                        <a :href="'https://www.nubbo.io/' + langCode + '/terms-and-conditions/'" target="_blank">
                        link</a>
                        that appears below you will be able to read our terms and conditions</p>
                    </div>
                </div>
            </div>

            <itinerary-footer></itinerary-footer>
        </div>
    </div>
</template>

<script>
let bookingFinalView = importVueComp('components/documentation', 'BookingFinalView', 'custom');
const itineraryFooter = importVueComp('components/itinerary', 'ItineraryFooter');
import '@/extra/custom/css/fstyles.scss';
export default {
    name: 'booking-final-view',
    mixins: [bookingFinalView],
    components: {
        'itinerary-footer': itineraryFooter,
    },
}
</script>

