<script>
const abmBooking = importVueComp('components/tools', 'abmBooking', 'custom')
export default {
    name: 'custom-abm-booking',
    mixins: [abmBooking],
    methods: {
        getRouteName (currentRoute) {
            let routeName = abmBooking.methods.getRouteName(currentRoute);
            if (currentRoute.name == 'abm-booking-catalogue') routeName = 'booking-catalogue';
            return routeName;
        },
    },
}
</script>