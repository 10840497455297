import '@/tools/table2CSV';
const modules = vueTools.importModule('modules', 'tools').default;
import XLSX from 'sheetjs-style';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

//import ExcelJS from 'exceljs';

class FrontTools {

    value (v){
        return v? v: '';
    }

    setReportValues (self) {
        if (!self.fields) return;
        for (let field of self.fields) {
            if (field.defValue) {
                self.current[field.name] = field.defValue;
            } else {
                self.current[field.name] = null;
            }
        }
        if (self.options) {
            for (let field of self.fields) {
                if (self.options[field.name]) {
                    let value = self.options[field.name];
                    if (typeof value === 'object' && field.editor == 'date') {
                        value = moment(new Date()).add(value.days, 'days').format('YYYY-MM-DD');
                    }
                    field.defValue = value;
                    self.current[field.name] = value;
                }
            }
        }
        self.fields = Object.assign([], self.fields);
    }

    importJSON (name, path, module, dict) {
        let extras = Object.assign([], api.EXTRAS).reverse();
        let p = `@/${path}/${name}.json`;
        let m;
        if (!module && extras.length>0) {
            m  = extras[0];
            p = `@/extra/${m}/${path}/${name}`;
        } else if (extras.length>0) {
            let i = extras.indexOf(module);
            m = extras[i+1];
            if (m) p = `@/extra/${m}/${path}/${name}`;
        }
        let res;
        try {
            if (m) {
                res = require(`@/extra/${m}/${path}/${name}`);
            } else {
                res = require(`@/${path}/${name}`);
            }
        } catch (err) {
            if (m) res = this.importJSON(name, path, m);
        }
        if (res && dict) res = Object.assign(res, dict);
        if (m) res = this.importJSON(name, path, m, res);
        return res;
    }

    getFlightsMail (body, booking, bookingForm) {
        body += '<br>';
        body += '<p>Vuelos</p>';
        for (let flight of booking.BookingFlights) {
            body += '<p>' + flight.Output + '</p>';
        }
        body += '<br>';
        if (customTools && customTools.getFlightsMail) body = customTools.getFlightsMail(body, booking, bookingForm);
        return body;
    }

    downloadReportCSV (id, fileName) {
        let table = $('#' + id);
        let csv = table.table2CSV({
            delivery: 'value'
        });
        this.download(fileName,  encodeURIComponent(csv))
    }

    exportToPDF (id, fileName, columnStyles, tableWidth, format) {
        const doc = new jsPDF({
            orientation: 'landscape',
            format
        });
        doc.autoTable({
            html: '#' + id ,
            tableWidth,
            columnStyles,
            useCss: true,
            rowPageBreak: 'avoid'

        });
        doc.save(fileName);
    }


    downloadReport (id, fileName) {
        let table = document.getElementById(id);
        let worksheet = tools.normalizeText(table.innerHTML);
        this.download(fileName, escape(worksheet))
    }

    download (filename, text, format) {
        if (!format) format = 'plain';
        var element = document.createElement('a');
        element.setAttribute('href', `data:text/${format};charset=utf-8,` + text);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    exportToExcel (id, fileName) {

        const table = document.getElementById(id);
        const ws = XLSX.utils.table_to_sheet(table, { cellHTML: true, cellStyles: true });

        let firstRow = table.rows[0]
        var wscols = [];
        var cols = [];
        for (let cell of firstRow.cells) {
            let style = getComputedStyle(cell);
            let cellId = cell.getAttribute('cellcode');
            let c = cellId.substring(0, 1);
            cols.push(c);
            let w = parseInt(style.width.replace('px', '')) / 8;
            wscols.push({wch:w})
        }

        for (let row of table.rows) {
            for (let cell of row.cells) {
                let style = getComputedStyle(cell);
                let cellId = cell.getAttribute('cellcode');
                if (ws[cellId]) {
                    ws[cellId].s = this.getCellStyle(style);
                    ws[cellId].v = cell.innerText;
                    ws[cellId].t = 's';
                    //delete ws[cellId].z;
                }
            }
        }
        let border = {
            style: 'thin',
            color: {rgb: 'DEE2E6'}
        }
        let borders = {
            top: border,
            bottom: border,
            left: border,
            right: border,
        }

        for (let i=1; i<table.rows.length+1; i++) {
            for (let c of cols) {
                let cellId = c + i;
                if (!ws[cellId]) {
                    ws[cellId] = {
                        t: 's',
                        v: '',
                        s: {
                            border: borders
                        }
                    }
                }
            }
        }
        ws['!cols'] = wscols;
        /*ws['!protect'] = {
          password: '12345',
          selectLockedCells: true,
          selectUnlockedCells: true,
          formatCells: true,
          formatColumns: true,
          formatRows: true,
          insertColumns: true,
          insertRows: true,
          insertHyperlinks: true,
          deleteColumns: true,
          deleteRows: true,
          sort: true,
          autoFilter: true,
          pivotTables: true,
          objects: true,
          scenarios: true,
        };*/
        const wb1 = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb1, ws, "report");
        XLSX.writeFile(wb1, fileName);

    }


    toHex (s) {
      if (s == "rgba(0, 0, 0, 0)") return 'FFFFFF';
      let t = s.replace('rgba', '').replace('(', '').replace(')', '').replace('#', '').replace('rgb', '');
      t = t.split(',');
      let r = parseInt(t[0]);
      let g = parseInt(t[1]);
      let b = parseInt(t[2]);
      let res = (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
      return res.toUpperCase();
    }

    getCellStyle (style) {
        let res = {
            alignment: {},
            font: {},
        };
        let attrs = ['color', 'backgroundColor', 'textAlign', 'border', 'verticalAlign'];
        if (style.color) res.font.color = {rgb: this.toHex(style.color)};
        if (style.bold) res.font.bold = true;
        if (style.backgroundColor) {
            res.fill = {fgColor: {rgb: this.toHex(style.backgroundColor)}}
        };

        if (style.textAlign) res.alignment.horizontal = style.textAlign;
        if (style.verticalAlign == 'middle') res.alignment.vertical = 'center';
        let border = {
            style: 'thin',
            color: {rgb: 'DEE2E6'}
        }
        res.border = {
            top: border,
            bottom: border,
            left: border,
            right: border,
        }
        //return { font: { bold: true, color: { rgb: "FF0000" } } }
        return res;
    }

    getModuleByPath (path) {
        for (let moduleId in modules) {
            let f = _.find(modules[moduleId].endpoints, (r) => {
              if (r == path) return true;
              if (r.table && r.table == path && !r.path) return true;
              if (r.report && r.report == path) return true;
              if (r.path && r.path == path) return true;
              //return r == path || r.table == path || r.report == path || r.path == path
            });
            if (f) return modules[moduleId].label;
        }
    }

    routeAccess (t) {
        let access;
        if (t.path) return t.path;
        if (t.table) return t.table;
        if (t.report) return t.report;
        return t;
    }


    findModule (endpoint) {
      for (let key in modules) {
        let i = _.find(modules[key].endpoints, (r) => {
          if (r.name && r.name == endpoint.name && !endpoint.report) return true;
          if (r.report && r.report == endpoint.name && endpoint.report) return true;
          if (endpoint.table && endpoint.table == r) return true;
          return r == endpoint
        });
        if (i) {
          return modules[key];
        }
      }
    }

    getMatchedPath (route) {
        if (route.name == 'report') return route.path.replace('/report', '');
        if (!route.params) return route.replace('/abm', '').replace('/report', '');
        let path = route.matched[0].path;
        for (let param in route.params) {
            let p1 = `/:${param}?`;
            let p2 = `:${param}`;
            path = path.replace(p1, '')
            let isNumber;
            if (route.params[param]) {
                isNumber = !Number.isNaN(parseInt(route.params[param]));
            }
            if (isNumber) {
                path = path.replace(`/:${param}`, '');
            } else {
                path = path.replace(p2, route.params[param]);
            }
        }
        return path.replace('/abm', '').replace('/report', '');

    }

    findPos(obj) {
        var curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
        return [curtop];
        }
    }

    scrollTo (id, d) {
        let el = document.getElementById(id);
        if (!el) return;
        let p =  this.findPos(el);
        if (d) p[0] += d;
        window.scroll(0, p);
    }

    merge (a, b) {
        let r = Object.assign({}, a);
        for (let lang in b) {
            if (!a[lang]) r[lang] = {}
            for (let key in b[lang]) {
                if (!a[lang] || !a[lang][key]) r[lang][key] = []
                for (let i of b[lang][key]) {
                    r[lang][key].push(i)
                }
            }
        }
        return r
    }

    setToIndexDB (data, table, key) {
        let nubbo = indexedDB.open("nubbo-app");
        nubbo.onsuccess = () => {
            var db = nubbo.result;
            var tx = db.transaction(table, "readwrite");
            var store = tx.objectStore(table);
            store.put({id: key, data: data});
            db.close();
        }

    }

    removeIndexDB () {
        var req = indexedDB.deleteDatabase("nubbo-app");
        req.onsuccess = () => {
            console.log("Deleted database successfully");
            frontTools.createIndexDB();
        };
        req.onerror = () => {
            console.log("database not deleted");
        };
    }

    createIndexDB () {
        let nubbo = indexedDB.open("nubbo-app");
        nubbo.onupgradeneeded = () => {
            let db = nubbo.result;
            db.createObjectStore("bookingBkp", {keyPath: "id"});
            db.close();
        };
    }
    //Failed to read the 'result' property from 'IDBRequest': The request has not finished

    dropTable() {
        var request = indexedDB.open("nubbo-app", 4);
        request.onsuccess = async ()  => {
            var db = request.result;
            console.log(31, db.version)
            db.close();
        }

        request.onupgradeneeded = (event) => {
        console.log(4)
          const db = request.result;
        console.log(5)
          db.deleteObjectStore("bookingBkp");
          db.close();
        console.log(6)
        };
    }

    getFromIndexDB (table, key, callback) {
        var nubbo = indexedDB.open("nubbo-app");
        nubbo.onsuccess = async ()  => {
            var db = nubbo.result;
            if (!db.objectStoreNames.contains(table)) {
                db.close();
                frontTools.removeIndexDB();
            } else {
                    let tx = db.transaction(table);
                    let store = tx.objectStore(table);
                    let request = store.get(key);
                    request.onsuccess = async () => {
                        let r;
                        if (request.result) {
                            r = request.result.data;
                        }
                        if (callback)  callback(r);
                    }
                    db.close();
            }
        }
    }

    printScreen (id, fileName, callback, noHash) {
        html2canvas(document.querySelector("#" + id)).then(async (canvas) => {
            let img = canvas.toDataURL("image/png");
            if (img) {
                let res = await api.savePrintScreen(img, fileName, null, noHash);
                if (res) {
                    callback(res)
                }
            }
        });
    }

}

window.frontTools = new FrontTools();
