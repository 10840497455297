<template>
    <div class="row table-voucher" v-if="supplierData">
        <div class="col-12 py-2">
                <div v-if="supplierData.Services">
                    <div class="row d-flex justify-content-center">
                        <span>Services</span>
                    </div>
                    <div v-for="(s, tId) in supplierData.Services">
                        <div class="row form-material">
                            <span class="col-1" v-if="tId==0">{{supplierData.ServiceDate}}</span>
                            <span class="col-1" v-else></span>
                            <span class="col-1 text-right" v-if="edit">
                                <font-awesome-icon icon="trash-alt" class="cursor-pointer" @click="disable(s)"/>
                            </span>
                            <span class="col-1" v-else></span>

                            <span class="col-7" v-if="edit && edit && editing!= sId + '-' + tId && s.extra && !s.VoucherText" @click="editText(tId, s)">
                                <i>{{tr('Edit row text')}}</i>
                            </span>
                            <span class="col-7" v-else-if="!edit">
                                {{getServiceText(s)}}
                            </span>
                            <span v-else-if="edit && editing!= sId + '-' + tId" @click="editText(tId, s)" class="col-7">
                                {{getServiceText(s)}}
                            </span>
                            <div class="form-group form-default col-7 my-0" v-else>
                                <input type="text" class="form-control fill" v-model="s.VoucherText">
                                    <span class="form-bar"></span>
                                </input>
                            </div>
                            <span class="col-3" v-if="s.PickupInfo && editing != tId + '-pickup'" @click="editPickup(tId)">
                                {{s.PickupInfo}}
                            </span>
                            <span class="col-3" v-else-if="edit && editing == tId + '-pickup'">
                                <input type="text" class="form-control fill" v-model="s.PickupInfo">
                                    <span class="form-bar"></span>
                                </input>
                            </span>
                            <span class="col-3" v-else-if="edit" @click="editPickup(tId)">
                                <i>{{tr('Edit pick up')}}</i>
                            </span>
                        </div>
                    </div>
                    <div v-if="supplierData.entrance" class="my-0">
                        <div class="row">
                            <span class="col-7 offset-2">Entrance fee included</span>
                        </div>
                    </div>
                    <div class="row form-material mt-1" v-if="edit">
                        <i class="add-item cursor-pointer col-3 offset-2 text-left" @click="newRow(supplierData)" >
                            <font-awesome-icon icon="plus-circle"/>
                        </i>
                    </div>
                </div>
                <div v-else-if="supplierData.Rooms">
                    <div class="row d-flex justify-content-center">
                        <span>Hotel</span>
                    </div>
                    <div class="" v-for="(room, hId) of supplierData.Rooms">
                        <div class="row form-material">
                            <span class="col-3" v-if="hId==0">IN {{supplierData.DateIn}} - OUT {{supplierData.DateOut}}</span>
                            <span class="col-3" v-else></span>
                            <span class="col-7" v-if="!edit">
                                {{room.VoucherText || `${supplierData.Supplier.Name} - ${room.Category.Name}`}}
                            </span>
                            <span class="col-8" v-else-if="edit && editingRoom!= hId" @click="editRoomText(hId, room)">
                                {{room.VoucherText || `${supplierData.Supplier.Name} - ${room.Category.Name}`}}
                            </span>
                            <div class="form-group form-default col-8 my-0" v-else>
                                <input type="text" class="form-control fill" v-model="room.VoucherText">
                                    <span class="form-bar"></span>
                                </input>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'voucher',
    props: ['supplierData', 'sId', 'edit'],
    data  () {
        return {
            editing: null,
            editingRoom: null,
            roomText: null,
        }
    },
    mounted () {
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        imgPath () {
            let res = 'img';
            if (api.EXTRAS.indexOf('custom')>-1) {
                res = 'extra/custom/img';
            }
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.ImagesPath) {
                return res  + '/' + this.recordStore.Agency.ImagesPath;
            }
            return res;
        },
    },
    methods:  {
        getServiceText (s) {
            if (s.VoucherText) return s.VoucherText;
            if (s.Service && s.Service.VoucherText) return s.Service.VoucherText;
            if (s.Name) return s.Name;
            if (s.Service && s.Service.Name) return s.Service.Name;
        },
        editText (tId, s) {
            if (!s.VoucherText) {
                if (s.Service && s.Service.VoucherText) {
                    s.VoucherText = s.Service.VoucherText
                } else if (s.Name) {
                    s.VoucherText = s.Name
                } else if (s.Service && s.Service.Name) {
                    s.VoucherText = s.Service.Name
                };
            }
            this.editing = this.sId + '-' + tId;
        },
        editRoomText (hId, room) {
            this.editingRoom = hId;
            if (!room.VoucherText) {
                room.VoucherText = `${this.supplierData.Supplier.Name} - ${room.Category.Name}`;
            }
        },
        editPickup (tId) {
            this.editing = tId + '-pickup';
        },
        async disable (s) {
            if (s.extra) {
                let day = _.find(this.recordStore.BookingDays, (r) => r.DayNr == s.DayNr);
                if (day) {
                    await day.removeRow({fieldName: 'BookingDayVouchers', rowNr: s.rowNr});
                }
                return;
            };
            s.VoucherDisabled = true;
        },
        async newRow (supplierData) {
            let day = _.find(this.recordStore.BookingDays, (r) => r.DayNr == supplierData.DayNr);
            if (day) {
                await day.addRow({fieldName: 'BookingDayVouchers'});
            }
        }
    }
}
</script>
