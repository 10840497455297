<template>
    <div class="tab-documentation card no-border-top px-5 pt-4" v-if="ready">
        <div>
            <div class="form-group form-material">
                <div class="form-row">
                      <div class="col-md-4 form-group form-default">
                          <input v-model="recordStore.Documentation.Subject" class="form-control" type="text"
                            :class="{'fill': recordStore.Documentation.Subject}"
                          >
                            <span class="form-bar"></span>
                            <label class="float-label">{{tr('Subject')}}</label>
                          </input>
                      </div>
                      <div class="col-md-2">
                          <select-input :fieldOptions="options"
                          :currentValue.sync="recordStore.Documentation.Email" label="Select Email"></select-input>
                      </div>
                      <div class="col-md-2 form-group form-default" v-if="recordStore.Documentation.Email=='_add'">
                          <input v-model="recordStore.Documentation.AddEmail"  class="form-control" type="text"
                            :class="{'fill': recordStore.Documentation.AddEmail}"
                          >
                            <span class="form-bar"></span>
                            <label class="float-label">{{tr('Add Email')}}: </label>
                          </input>
                      </div>
                      <div class="col-md-2" v-else>
                      </div>
                      <div class="col-md-2">
                          <select-input :fieldOptions="options"
                          :currentValue.sync="recordStore.Documentation.MailCc" label="Copy"></select-input>
                      </div>
                      <div class="col-md-1 d-flex align-items-center justify-content-center">
                          <button type="button" class="btn btn-primary doc-button" @click="save"
                            :disabled="!recordStore.Documentation.Email || !buttonEnabled || sending">
                            <span v-if="!sending">{{tr('Send')}}</span>
                            <i v-else>{{tr('Sending')}}</i>
                          </button>
                      </div>
                      <div class="col-md-1 d-flex align-items-center justify-content-center">
                          <button type="button" class="btn btn-primary doc-button" @click="documentationView">
                            Link
                          </button>
                      </div>
                </div>
                <div class="spacer"></div>
                <div class="form-group">
                  <ul class="nav flex-column md-tabs px-4" id="mail-tabs" role="tablist">
                    <li class="nav-item">
                      <span class="nav-link active" id="text-tab" data-toggle="tab" href="#text-data" role="tab"
                        aria-controls="text-data" aria-selected="true" @click="disableButton">{{tr('Text')}}</span>
                    </li>
                    <li class="nav-item">
                      <span class="nav-link" id="elements-tab" data-toggle="tab" href="#elements-data" role="tab"
                      aria-controls="elements-data" aria-selected="false" @click="disableButton">{{tr('Options')}}</span>
                    </li>
                    <li class="nav-item">
                      <span class="nav-link" id="preview-tab" data-toggle="tab" href="#preview-data" role="tab"
                      aria-controls="preview-data" aria-selected="false" @click="enableButtons">{{tr('Preview')}}</span>
                    </li>
                  </ul>
                  <div class="tab-content" id="mail-tabsContent">
                    <div class="tab-pane fade show active" id="text-data" role="tabpanel" aria-labelledby="text-tab">
                        <div class="form-group form-default">
                          <textarea v-model="recordStore.Documentation.Text" class="text-control textarea-mail mt-3"
                          id="Comment" rows="20" @change="updateBooking">
                          </textarea>
                          <span class="form-bar"></span>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="elements-data" role="tabpanel" aria-labelledby="elements-tab">
                        <div v-for="e of recordStore.Documentation.Elements" class="form-check mt-1 ml-3">
                            <input v-model="e.value" :name="e" class="form-check-input" type="checkbox">
                            <label :for="e" class="form-check-label">{{e.label}}</label>
                        </div>
                        <label class="ml-3 mt-3"><strong>{{tr('Attachments')}}</strong></label>
                        <div v-for="e of recordStore.Documentation.Attachments" class="form-check mt-1 ml-3">
                            <input v-model="e.value" :name="e" class="form-check-input" type="checkbox">
                            <label :for="e" class="form-check-label">
                                <i v-if="e.supplier"><b>{{e.supplier}}:</b></i>
                                <i v-if="e.flight"><b>{{tr('Flight Ticket')}}:</b></i>
                                {{e.label}}
                            </label>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="preview-data" role="tabpanel" aria-labelledby="preview-tab">
                       <documentation v-if="recordStore.Documentation.Text && recordStore.Documentation.Elements">
                            <table-body
                                :id="recordStore.id"
                                :text.sync="recordStore.Documentation.Text"
                                :elements="recordStore.Documentation.Elements"
                                :attachments="recordStore.Documentation.Attachments"
                                slot="body">
                            </table-body>
                        </documentation>
                    </div>
                  </div>
              </div>
            </div>
       </div>
  </div>
</template>

<script>
import documentation from '@/extra/custom/components/documentation/Documentation';
import tableBody from '@/extra/custom/components/documentation/TableBody';
import { mapState } from 'vuex';
export default {
    name: 'documentation-data',
    components: {
      'documentation': documentation,
      'table-body': tableBody
    },
    data () {
        return {
            value: null,
            options: null,
            text: null,
            buttonEnabled: false,
            ready: false,
            sending: false,
            elements: {
                vouchers: {value: false, label: "Voucher"},
            }
        }
    },
    async mounted () {
        this.options = [{value: this.user.Email, label: this.user.Email}];
        if (this.recordStore.PaxEmail) {
            this.options.push({value: this.recordStore.PaxEmail, label: this.recordStore.PaxEmail});
        }
        this.options.push({value: '_add', label: `(${tr('Add Email')})`})
        if (!this.recordStore.Documentation.Subject && !this.recordStore.Documentation.Text && !this.recordStore.Documentation.Elements) {
            let res = await api.get('/api/documentation/', {filters: JSON.stringify({LanguageId: this.recordStore.LanguageId })})
            if (res && res.length>0) {
                this.recordStore.Documentation.Text = res[0].Data.Text;
                this.recordStore.Documentation.Subject = res[0].Data.Subject;
                this.recordStore.Documentation.Elements = this.elements;
            }
        }
        delete this.recordStore.Documentation.Elements['otherInfo'];
        delete this.recordStore.Documentation.Elements['flights'];
        let list = this.recordStore.Documentation.Attachments;
        if (!this.recordStore.Documentation.Attachments) {
            list = [];
        }
        for (let row of this.recordStore.attached) {
            let l = row.name.split('/');
            if (l[2]=='info' || l[2]=='supplier') {
                let f = _.find(this.recordStore.Documentation.Attachments, (r) => r.url == row.url);
                let supplier;
                let supplierId;
                if (l[2]=='supplier') {
                    supplierId = l[3];
                    supplier = _.find(api.tables.supplier, (r) => r.id == l[3]);
                    if (supplier) supplier = supplier.Name;
                }
                if (!f) {
                    list.push({
                        sort: supplierId || -1,
                        url: row.url,
                        label: l[l.length - 1],
                        value: false,
                        supplier
                    })
                } else {
                    f.sort = supplierId || -1;
                }
            } else if (l.length==3) {
                let f = _.find(this.recordStore.Documentation.Attachments, (r) => r.url == row.url);
                if (!f) {
                    list.push({
                        sort: -2,
                        url: row.url,
                        label: l[l.length - 1],
                        flight: true,
                        value: false,
                    })
                } else {
                    f.sort = -2;
                }
            }
            list.sort(function(a, b) {
                if (a.sort > b.sort) return 1;
                if (a.sort < b.sort) return -1;
                return 0;
            })
        }
        this.recordStore.Documentation.Attachments = list;
        this.recordStore.Documentation = Object.assign({}, this.recordStore.Documentation);
        await this.recordStore.setValue({fieldName: 'Documentation', value: this.recordStore.Documentation});
        this.ready = true;
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
        }),
        otherDocs () {
            let res = [];
            for (let row of this.recordStore.attached) {
                let l = row.name.split('/');
                if (l[2]=='info') {
                    res.push({
                        url: row.url,
                        label: l[l.length - 1],
                        value: false,
                    })
                }
            }
            return res;
        }
    },
    methods: {
        async save () {
            this.sending = true;
            let value = this.recordStore.Documentation.Email;
            if (value=='_add' && this.recordStore.Documentation.AddEmail) {
                value = this.recordStore.Documentation.AddEmail;
            }
            let html = $('#documentation-mail-html').html()

            let res = await api.post('/api/booking/send_documentation/' + this.recordStore.id,
                JSON.stringify({
                    MailTo: value,
                    Text: html,
                    MailCc: this.recordStore.Documentation.MailCc,
                    Attachments: true,
                    Subject: this.recordStore.Documentation.Subject,
                    Template: true,
                    extraAttach: this.recordStore.Documentation.Attachments,
                }));
            if (res) {
                alert(tr('Message sent'));
            }
            this.sending = false;
        },
        documentationView () {
            let route = this.$router.resolve({name: 'documentation',
                params: {hash: this.recordStore.Hash, id: this.recordStore.id}})
            window.open(route.href, '_blank');
        },
        disableButton () {
            this.buttonEnabled = false;
        },
        enableButtons () {
            this.buttonEnabled = true;
        },
        updateBooking (){
        }

    },
}
</script>
