<template>
    <footer>
        <div class="container">
          <div class="d-flex justify-content-center d-flex align-items-center">
              <div class="col-md-4 col-xs-12 text-center">
                <img src="@/extra/custom/img/raja_logo.webp" alt="" class="w-100">
              </div>
              <div class="col-md-4 col-xs-12 copyright text-center">  &copy; Copyright <strong><span>2020 di Rajatours Indonesia</span></strong>. All Rights Reserved
              </div>
          </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'reservation-form-footer',
}
</script>