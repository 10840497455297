<script>
const bookingFlights = importVueComp('components/booking', 'BookingFlights', 'custom');
export default {
    name: "custom-booking-flights",
    mixins: [bookingFlights],
    computed: {
        canEdit () {
            return this.salesActions || this.managerActions;
        },
        canAttach () {
            return true;
        },

    },
    methods: {
        getFlightRoute (flight) {
            return flight.Output;
        },
    }
};
</script>


