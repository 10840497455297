<script>
const bookingOperationsFlights = importVueComp('components/booking', 'BookingOperationsFlights', 'custom');
export default {
    name: 'custom-operations-flight',
    mixins: [bookingOperationsFlights],
    methods: {
        getFlightDescription (flight) {
            return flight.Output;
        },
    },
}
</script>
