<template>
    <div class="col-md-12" v-if="ready">
        <div v-for="(variant, variantId) of totalByPaxVariant">
            <div v-for="(value, pType) of totalByPaxVariant[variantId]">
                <div class="form-row">
                    <div class="col-md-1">
                    </div>
                    <div class="col form-variant ">
                        <input v-model="recordStore.QuoteVariants[baseId][variantId].Selected[pType]" type="checkbox"
                            class="form-check-input" :disabled="!canEdit" @change="setSelected"/>
                        <label>{{recordStore.QuoteVariants[baseId][variantId].Name}} {{pType}}</label>
                    </div>
                    <div class="col">
                        <number-input
                            :addClass="overPrice(variantId, pType)? 'border-warning': ''"
                            :currency="recordStore.Currency"
                            :label="recordStore.CurrencyId"
                            :disabled="!canEdit"
                            @change="setOverPrice($event, variantId, pType)"
                            :v.sync="value.Price"></number-input>
                    </div>
                    <div class="col" v-if="recordStore.CurrencyId!=recordStore.PriceCurrencyId">
                        <number-input
                            :label="recordStore.PriceCurrencyId"
                            :v="getTotalInCurrency(value.Price)"
                            :currency="recordStore.PriceCurrency"
                            disabled="true"
                        ></number-input>
                    </div>
                    <div class="col" v-if="recordStore.Agency && recordStore.Agency.Code == 'IND-INF'">
                        <number-input
                            :changeStyle="true"
                            :currency="recordStore.PriceCurrency"
                            :label="recordStore.PriceCurrencyId"
                            :disabled="!canEdit"
                            :v.sync="recordStore.QuoteVariants[baseId][variantId].AgencyTotal[pType]">
                        </number-input>
                    </div>
                    <div class="col-md-5 form-variant form-default ">
                        <div class="form-group form-default my-2">
                            <input v-model="recordStore.QuoteVariants[baseId][variantId].Comments[pType]" class="form-control"
                                :class="{'fill': recordStore.QuoteVariants[baseId][variantId].Comments[pType]}"
                                @change="setComment($event.target.value.Price, variantId, pType)"
                                type="text" :disabled="!canEdit">
                                <span class="form-bar"></span>
                                <label class="float-label ml-2">
                                    {{tr('Comment')}}
                                </label>
                            </input>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteTotalVariant = importVueComp('components/booking', 'BookingQuoteTotalVariant', 'custom');
export default {
    name: 'custom-booking-quote-total-variant',
    mixins: [bookingQuoteTotalVariant],
}
</script>

