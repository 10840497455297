
<script>
const salesByUserReport = importVueComp('components/reports', 'SalesByUserReport', 'custom');
export default {
    name: 'custom-sales-by-user-report',
    mixins: [salesByUserReport],
    methods: {
        getData (self) {
            let r = salesByUserReport.methods.getData(this);
            r.headerColumns.push('Markup');
            r.templates.push(
                    {name: 'Markup', callback: (row) => {
                        if (row.calculated) return '';
                        if (row._Profit && row._TotalCost) {
                            let r = (parseFloat(row._Profit) / parseFloat(row._TotalCost) * 100);
                            row._Markup = r;
                            return r.toFixed(1);
                        }
                      }
                    },
            )
            r.fieldClasses.Markup = (row) => {
                if (row._Markup > 40) {
                    return 'text-warning2';
                } else if (row._Markup <  20) {
                    return 'text-danger';
                }
            };
            return r;
        },
        async beforeRender (report) {
            if (this.current.BookingStatus == 'ALL_CONFIRMED') {
                if (this.headerColumns.indexOf('Passengers') == -1) {
                    this.headerColumns.push('Passengers')
                    this.headerColumns = Object.assign([], this.headerColumns);
                }
            }
            let model = await api.importMixinModule('booking', 'model');
            for (let row of report.dataList) {
                if (row.ALL_PAYMENTS == -1 ) {
                    let booking = await model.get({id: row.BookingId, isTemplate: false, payments: true, receipts: true});
                    booking.SummaryCurrencyId = row.PriceCurrencyId;
                    row.TotalCost = booking.finalCost;
                    row.Profit = row.finalIncomes - row.finalCost;
                }
            }
        },

    }
}
</script>

