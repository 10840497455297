<template>
    <footer id="footer" class="footer">
      <div class="container">
        <div class="row py-2 d-flex justify-content-between justify-content-xs-center">
            <div class="col-md-4 col-xs-12 text-center">
              <async-img :url="imageAgency" v-if="imageAgency" class="h-100"></async-img>
              <img src="@/extra/custom/img/raja_logo.webp" alt="" class="w-100" v-else>
            </div>
            <div class="col-md-4 col-xs-12 copyright text-center">  &copy; Copyright <strong><span>2023
                {{companyName}}</span></strong>. All Rights Reserved</div>
       </div>
      </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-footer',
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        imageAgency () {
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.Image) {
                return this.recordStore.Agency.Image.url;
            }
        },
        companyName () {
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.Name) {
                //return this.recordStore.Agency.Name;
            }
            return 'di Rajatours Indonesia';
        }
    },
}
</script>

