<script>
const bookingPayments = importVueComp('components/booking', 'BookingPayments', 'custom');
export default {
    name: 'custom-booking-payments',
    mixins: [bookingPayments],
    computed: {
        pending () {
            let res = [];
            for (let currency in this.toPay) {
                let paid = 0;
                if (this.paid[currency]) paid = this.paid[currency];
                let c = _.find(api.tables.currency, (r) => r.id == currency);
                let value = this.toPay[currency] - paid;
                if (value < 0) value = 0;
                res.push(`${tools.formatNumber( value, {c})} ${currency}`);
            }
            return res.join('; ');
        },

    }
}
</script>

