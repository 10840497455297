
<script>
const itineraryTerms = importVueComp('components/itinerary', 'ItineraryTerms', 'custom');
import { method } from 'lodash';
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-terms',
    mixins: [itineraryTerms],
    methods: {
      gf (f) {
        return f;
      }
    }
}
</script>


