<template>
    <form class="form-inline celeste header-row" action="">
       <div-input fieldName="pax.FirstName" :v.sync="pax.FirstName" :cols="2"
            label="First Name" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.MiddleName" :v.sync="pax.MiddleName" :cols="2"
            label="Second Name" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.LastName" :v.sync="pax.LastName" :cols="2"
            label="Surname" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.Email" :v.sync="pax.Email" :cols="3"
            label="Email" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div class="form-group col-md-3">
            <date-selector
                :def.sync="pax.BirthDate"
                format="YYYY-MM-DD"
                :id="'BirthDate-' + i"
                :label="tr('Date of Birth') + '*'"
                :langCode="langCode"
                :disableFuture="true"
                :invalid.sync="datesInvalids['BirthDate-' + i]"
          ></date-selector>
       </div>
       <div-input fieldName="pax.Passport" :v.sync="pax.Passport" :cols="3"
            label="Valid Passport Number" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div class="form-group col-md-3">
            <date-selector
                :def.sync="pax.PassportValidTo"
                format="YYYY-MM-DD"
                :id="'PassportValidTo-' + i"
                :label="tr('Passport Expiration Date') + '*'"
                :langCode="langCode"
                :disablePast="true"
                :invalid.sync="datesInvalids['PassportValidTo-' + i]"
          ></date-selector>
       </div>

       <div class="form-group col-md-3">
            <div class="label">{{tr('Country of Origin of Passport')}}*</div>
            <select class="custom-select"
                :class="(!pax.PassportCountry && invalid && isRequired('pax.PassportCountry'))? 'is-invalid': ''"
                :required="isRequired('pax.PassportCountry')"
                v-model="pax.PassportCountry">
                <option
                    v-for="opt of countries"
                    :value="opt.value"
                    :selected="opt.value == pax.PassportCountry">
                    {{tr(opt.label)}}
                </option>
          </select>
       </div>

       <div class="form-group col-md-3">
            <div class="label">{{tr('Room Type?')}}*</div>
            <select class="custom-select"
                :class="(!pax.RoomType && invalid && isRequired('pax.RoomType'))? 'is-invalid': ''"
                :required="isRequired('pax.RoomType')"
                v-model="pax.RoomType">
                <option
                    v-for="opt of fieldOptions.RoomType"
                    :value="opt.value"
                    :selected="opt.value == pax.RoomType">
                    {{tr(opt.label)}}
                </option>
          </select>
       </div>
       <div-input fieldName="pax.Allergies" :v.sync="pax.Allergies" :cols="6"
            label="_Allergies" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.Medication" :v.sync="pax.Medication" :cols="6"
            label="Medication" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.Dietary" :v.sync="pax.Dietary" :cols="6"  :invalid="invalid"
            label="Dietary Restrictions (Celiac, Vegan, Diabetic. etc)" :langCode="langCode"
       ></div-input>
       <div class="form-group col-md-6">
         <div class="label">{{tr('Add Passport')}}
            <i class="text-white ml-2">({{tr('Max. allowed size')}} {{maxSize}} Mb)</i>
         </div>
         <input type="file" class="form-control" @change="uploadPassport($event, i)" :id="'pass-' + i" multiple>

       </div>
    </form>
</template>

<script>
const divInput = importVueComp('components/reservationform', 'ReservationFormInput');
import dateSelector from '@/components/tools/DateSelector';
export default {
    name: 'reservation-form-pax',
    props: ['requiredFields', 'pax', 'i', 'langCode', 'datesInvalids', 'invalid', 'fieldOptions', 'maxSize'],
    components: {
        'div-input': divInput,
        'date-selector': dateSelector,
    },
    data () {
        return {
            countries: [],
            limit: 3000000,
        }
    },
    async mounted () {
        if (this.maxSize) {
            this.limit = this.maxSize * 1000000;
        }
        let countriesJSON = require(`@/languages/countries/${this.langCode.toLowerCase()}/world.json`);
        let countriesValues = [];
        for (let c of countriesJSON) {
            countriesValues.push({label: c.name, value: c.alpha3})
        }
        this.countries = countriesValues;
        if (this.pax.PassportCountry) {
            let find = _.find(this.countries, (p) => p.value == this.pax.PassportCountry);
            if (!find) {
                this.countries.push({label: this.pax.PassportCountry, value: this.pax.PassportCountry});
            }
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        checkFileSize (file, i) {
            console.log('checkFileSize', file.size, file.size>this.limit);
            if (file.size>this.limit) {
                let input = document.getElementById('pass-' + i);
                input.value = null;
                return;
            }
            return true;
        },
        async uploadPassport (event, i) {
            console.log('uploadPassport', event.target.files, i)
            let input = event.target;
            if (input.files) {
                let err;
                for (let j = 0; j < input.files.length; j++) {
                    if (!this.checkFileSize(input.files[j], i)) {
                        err = true;
                    }
                }
                if (err) {
                    let input = document.getElementById('pass-' + this.i);
                    input.value = null;
                    alert(`${this.tr('Maximum allowed file size')} ${this.limit / 1000000} Mb`);
                } else {
                    this.$emit('uploadPassport', input.files, this.i);
                }
            }
        },

        isRequired (fieldName) {
            if (this.requiredFields.indexOf(fieldName)>-1) return true;
        },
    },
}
</script>

