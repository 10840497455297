<template>
        <report-window
            v-if="ready"
            endpoint="/arg/reports/retencionreport"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :templates="templates"
            :hideFilters="options && options.hideFilters"
            :runMounted="options && options.runMounted"
            :cardView="options && options.cardView"
            :current.sync="current"
            :slotsFields="slotsFields"
            :columnsClasses="columnsClasses"
            :callbacks="callbacks"
            :notFilterable="options && options.notFilterable"
        ></report-window>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "retencion-report",
    props: ["options"],
    components: {
        ReportWindow
    },
    computed: {
        columnsClasses () {
            return {
                Amount: 'text-right'
            }
        }
    },
    mounted () {
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                    this.current[field.name] = this.options[field.name];
                }
            }
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    label: "Fecha In Desde",
                    required: true,
                    columns: 6
                },
                {
                    name: "ToDate",
                    editor: "date",
                    label: "Fecha In Hasta",
                    required: true,
                    columns: 6
                },
                {
                    name: "SupplierId",
                    relation: "supplier",
                    optionLabels: "Name",
                    editor: 'vue-select',
                    label: "Proveedor",
                    addBlank: true,
                    required: true,
                    sort: true,
                },

            ],
            title: "Cálculo de Retenciones",
            headers: {
                BookingId: "File Nr",
                PaymentId: "Pago Nr",
                TaxCode: "Impuesto",
            },
            templates: [
                {
                    name: 'Amount',
                    callback: (row) => {
                        if (!row.Amount) return null;
                        return row.Amount.toFixed(2);
                    }
                },
                {
                    name: "PaymentDate",
                    callback: (row) => {
                        if (!row.PaymentDate) return "";
                        return moment(row.PaymentDate).format("DD/MM/YYYY");
                    }
                },
            ],
            slotsFields: [],
            callbacks: {}
        };
    }
};
</script>
