<template>
    <table width="600" cellpadding="0" cellspacing="0" border="0" class="container" bgcolor="#ffffff" style="border-radius:4px">
        <tbody>
            <tr>
                <td class="mobile" height="40" align="center">
                </td>
            </tr>
            <tr>
                <td class="mobile" align="center">
                    <table width="90%" border="0" align="center" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr>
                                <td  style="text-align: justify; font-family: OpenSans, Arial, sans-serif; font-size: 17px; color: #949EB0; line-height:23px; font-weight:400; font-style: italic;">
                                    <text-rows-style :text="text"></text-rows-style>
                                </td>
                            </tr>
                            <tr>
                                <td height="30"> </td>
                            </tr>
                            <tr>
                                <td align="center"  style=" padding: 2% 0; font-family: Montserrat, Arial, sans-serif; font-size:18px; line-height:25px; font-weight:500; color:#AD1F22; background-color: #15361B; color: #fff; text-transform: uppercase; text-align: left;">
                                    <span style="padding-left: 5px; margin-left: 5px ">
                                        {{tr('Documentation for your trip')}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td height="15"> </td>
                            </tr>
                             <tr v-for="(e, i) of renderList" :style="getBGColor(i)">
                                <td align="center"  style="padding: 2% 0; font-family: Montserrat, Arial, sans-serif; font-size:18px; line-height:25px; font-weight:500; border-radius: 2px;  color: #000; text-transform: uppercase; text-align: left; ">
                                    <a :href="e.url" target="_blank" style="font-size:18px; padding: 2% 0; font-family: Montserrat, Arial, sans-serif; font-size:18px; line-height:25px; font-weight:500; border-radius: 2px;  color: #000; text-transform: uppercase; text-align: left; " v-if="e.url">
                                        <span style="padding-left: 20px; text-align: left;">
                                            {{e.title}}
                                        </span>
                                    </a>
                                    <span style=" padding-left: 20px; text-align: left;" v-if="e.values">
                                        {{e.title}}
                                    </span>
                                    <div style="padding-left: 30px; text-decoration: none;" v-for="v of e.values" v-if="e.values">
                                        <br>
                                        <a :href="v.url" target="_blank" style="font-size:18px; font-family: Montserrat, Arial, sans-serif; font-size:18px; line-height:25px; font-weight:500; border-radius: 2px;  color: #000; text-transform: uppercase; text-align: left; " >
                                            <span style="text-align: left;">- {{v.name}}
                                            </span>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td class="mobile" height="40" align="center"></td>
            </tr>
        </tbody>
    </table>

</template>

<script>

const front_url = process.env.FRONT_URL;
import { mapState } from 'vuex';
export default {
    name: 'table-body',
    props: ['text', 'elements', 'id', 'attachments'],
    data () {
        return {
            flights: [],
            counter: 0,
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (!this.recordStore) return;
            if (!this.recordStore.Language) return;
            return this.recordStore.Language.Code;
        },
        divList () {
            return [
                {name: 'vouchers', title: 'Voucher',
                    url: front_url + '/#/public/voucher/' + this.recordStore.Hash + '/' + this.recordStore.id},
                //{name: 'flights', title: this.tr('Flights Tickets'), values: this.flights},
            ]
        },
        renderList () {
            let serverurl = api.serverEndpoint;
            let res = [];
            if (!this.elements) return res;
            for (let e of this.divList) {
                if (!this.elements[e.name]) continue;
                if (this.elements[e.name].value) {
                    res.push(e);
                }
            }
            if (this.attachments) {
                for (let e of this.attachments) {
                    let v = Object.assign({}, e);
                    v.title = e.label;
                    if (v.value) {
                        v._url = e.url;
                        v.url = serverurl + '/api/get_attach/?url=' + e.url,
                        res.push(v);
                    }
                }
            }
            return res;
        }
    },
    async mounted () {
        if (!this.recordStore) return; 
        let res = await api.get('/public/api/get_attach_list_booking/' + this.recordStore.Hash + '/' + this.recordStore.id);
        //let serverurl = api.serverEndpoint;
        if (res) {
            /*let flights = _.filter(res, (r) => {
                let postF = r.name.split(`/${this.id}/`)[1];
                let paths = postF.split('/');
                if (paths.length==1) return true;
            });
            this.flights = [];
            for (let r of flights) {
                let l = r.name.split('/');
                this.flights.push({
                    name: l[l.length-1],
                    _url: r.url,
                    url: serverurl + '/api/get_attach/?url=' + r.url,
                })
            }*/
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getBGColor (i) {
            if (i % 2 == 0) return 'background-color: #F6F6F6'
            return '';
        }
    }
}
</script>


<style scoped>

</style>


