<template>
    <div class="form-row col-md-12 pl-0" v-if="quote" @click="focusOn">
        <div class="form-group service-row col-md-1 mt-0 ml-0 text-center pl-3">
            <span class="text-nowrap">{{date}}</span>&nbsp
            <span class="text-nowrap">{{tr('Day')}} {{dayNr+1}}</span>
        </div>
        <div class="form-group service-row mt-0" :class="hotelCols" @contextmenu="handleClickName($event)">
            <div v-if="salesActions" class="pl-2">
              <a href="#" data-toggle="modal"
                  :id="getId()"
                  class=""
                  :class="'hotel-day-' + roomQuote.dayNr + '-' + baseId"
                  data-target="#select-hotel-modal"
                  @click="editHotel"
              >
                {{roomQuote.hotelName}} {{roomQuote.days}} {{tr('Nights')}} {{rooms}} {{roomName}}
                {{roomQuote.roomCategoryName}}
                <i class="text-danger" v-if="room.TourLeader">TL</i>
                <i class="text-danger" v-if="room.SingleSupplement">SGL SUP</i>
              </a>
            </div>
            <div v-else class="pl-2">
                {{tr('Day')}} {{roomQuote.dayNr+1}}:
                {{roomQuote.hotelName}} {{roomQuote.days}} {{tr('Nights')}} {{rooms}} {{roomName}}
                {{roomQuote.roomCategoryName}}
                <i class="text-danger" v-if="room.TourLeader">TL</i>
                <i class="text-danger" v-if="room.SingleSupplement">SGL SUP</i>
            </div>
            <i class="text-danger" v-if="room.Status=='CANCELED'">
              {{tr('Canceled')}}
            </i>

        </div>
        <div class="form-row d-flex justify-content-center" :class="pricesCols">
            <div class="form-row col-md-12">
                <div class="form-group col col-input service-row">
                    <input v-model="quote.Include" type="checkbox"
                        :disabled="!canEditInclude"
                        @change="setInclude()"
                        @contextmenu="handleClick($event)"
                        class="form-check-input" v-if="edit"/>
                    <i class="valid-to cursor-pointer" v-if="quote.ValidTo" :class="{'text-danger': currentDate > quote.ValidTo}">
                      <font-awesome-icon icon="clock" @click="upOverValidTo = !upOverValidTo"/>
                      <div v-if="upOverValidTo" @click="upOverValidTo = !upOverValidTo" class="cost-value cursor-pointer">
                          {{tr('Valid to')}}: {{quote.ValidTo | toDate}}
                          <span v-if="currentDate > quote.ValidTo" @click="refreshRoom">{{tr('Refresh')}}</span>
                      </div>
                    </i>
                    <number-input :v.sync="quote.Cost"
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="{'border-danger': quote.invalid.Cost, 'border-warning': quote.Modified, 'line-through': room.Status=='CANCELED'}"
                        :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-Cost' "
                        v-if="quote" :currency="recordStore.Currency" :disabled="!canEdit"
                        @change="change('Cost')"
                    ></number-input>
                    <i class="cost-alert cursor-pointer" v-if="costError" >
                      <font-awesome-icon icon="exclamation" @click="setOver"/>
                      <div v-if="upOver" @click="setOverLeave" class="cost-value cursor-pointer">
                          {{tr('Current cost')}}: {{costValue | formatNumber({c: recordStore.CurrencyId})}}
                      </div>
                    </i>

                </div>
                <div class="form-group col col-input service-row">
                    <number-input :v.sync="quote.Markup"
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="quote.MarkupModified? 'border-warning': ''"
                        @change="change('Markup')"
                        v-if="quote" :currency="recordStore.Currency" :disabled="!canEdit"></number-input>
                </div>
                <div class="form-group col col-input service-row">
                    <number-input :v.sync="quote.Price"
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="{'border-danger': roomQuote.isErrorPrice, 'border-warning': quote.PriceModified, 'border-danger': quote.invalid.Price, 'line-through': room.Status=='CANCELED'}"
                        :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-Price' "
                        @change="change('Price')"
                        v-if="quote" :currency="recordStore.Currency" :disabled="!canEdit"></number-input>
                </div>
                <div class="form-group col col-input service-row"
                    v-if="!user.AgencyId && !room.Room.Checkin && roomQuote.hotelType=='NORMAL' && recordStore.QuoteBy!='QUANTITY'">
                    <number-input :v.sync="quote.RoomCost" @change="setRoomCost()"
                        :changeStyle="true"
                        class="my-0 mx-1"
                        v-if="quote" :currency="recordStore.Currency" :disabled="!canEdit"></number-input>
                </div>
                <div class="form-group col col-input service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="{'text-danger font-weight-bold': roomsCapacityError}"
                        :v.sync="Quantity"
                        @change="setQuantity()"
                        :currency="recordStore.Currency"
                        v-if="quote" :disabled="!canEdit"
                    ></number-input>
                </div>
                <div class="form-group col col-input service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v="quote.TotalCost"
                        v-if="quote" :currency="recordStore.Currency" :disabled="true"></number-input>
                </div>
                <div class="form-group col col-input service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v="quote.TotalPrice"
                        v-if="quote" :currency="recordStore.Currency" :disabled="true"></number-input>
                </div>
                <div class="form-group col ml-2 mt-0 service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <div class="d-flex">
                        <select-input
                            class="mx-1 my-0"
                            fieldName="UnitCostBy"
                            :fieldOptions="costByOptions.UnitCostBy"
                            addClass="max-100"
                            :currentValue.sync="quote.UnitCostBy">
                        </select-input>
                        <select-input
                            fieldName="PeriodCostBy"
                            :fieldOptions="costByOptions.PeriodCostBy"
                            addClass="max-100"
                            class="mx-1 my-0"
                            :currentValue.sync="quote.PeriodCostBy">
                        </select-input>
                    </div>
                </div>
            </div>
        </div>
        <context-menu
            v-if="showMenu"
            :options="menuOptions"
            :ref="'quoteMenu' + _uid"
            @clicked="menuClicked"
        ></context-menu>
    </div>
</template>

<script>
const bookingQuoteHotelRoom = importVueComp('components/booking', 'BookingQuoteHotelRoom', 'custom');
export default {
    name: 'custom-booking-quote-hotel-room',
    mixins: [bookingQuoteHotelRoom],
    data () {
        return {
            upOverValidTo: false,
            currentDate: null,
        }
    },
    mounted () {
        this.currentDate = moment(new Date()).format('YYYY-MM-DD');
    },
    methods: {
        async refreshRoom () {
            await this.recordStore.setCostByDate({update: true, baseId: this.baseId, days: [this.dayNr]});
        },
        async setInclude () {
            await this.$nextTick();
            await this.change('Include');
            let hotel = this.recordStore.BookingDays[this.dayId].BookingDayHotels[0];
            let parent = this.$parent;
            for (let roomId in hotel.BookingDayRooms) {
                let refId = this.dayId + '-' + roomId;
                if (this.$parent.$refs && this.$parent.$refs[refId] && this.$parent.$refs[refId][0]) {
                    this.$parent.$refs[refId][0].setQuote()
                }
            }
        }
    }
}
</script>

