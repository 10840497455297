import store from '@/store/store';
import '@/extra/custom/css/app.scss';

let SuperLoadTables = vueTools.importModule('load-tables', 'tools', 'custom').default;

class LoadTables extends SuperLoadTables {

    async getPromises () {
        await super.getPromises();
        if (api.currentUser && !this.currentRoute.meta.public) {
            api.setTable({table: "othercondition"});
            api.setTable({table: "emergencycontact"});
        }

    }

}

export default LoadTables;
