<template>
    <booking-flights :version="version">
        <template v-slot:custom>
            <attach
              title="Other Documents"
              class="pl-2"
              :dataList.sync="recordStore.attachList"
              :attached.sync="recordStore.attached"
              :id="recordStore.id"
              :folder="'info/'"
              :processing="recordStore.processingAttach"
              :canAttach="true"
              :canDelete="true"
              v-if="recordStore.attached"
            ></attach>
        </template>
    </booking-flights>
</template>


<script>
import { mapState } from "vuex";
const bookingFlights = importVueComp('components/booking', 'BookingFlightsCustom');
export default {
    name: "booking-flights-custom",
    props: ['version'],
    components: {
        'booking-flights': bookingFlights
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    }
};
</script>

