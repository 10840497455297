<template>
     <form class="form-inline celeste header-row" action="">
        <div class="form-group col-md-3">
           <div class="label">{{tr('Date and time of flight')}}</div>
           <m-date-time type="datetime" :value.sync="flight.FlightDate" format="YYYY-MM-DDTHH:mm:ss"></m-date-time>
        </div>
        <div :class="getClass">
           <div class="label">{{tr('Airline and flight number')}}</div>
           <input class="form-control" type="text" v-model="flight.FlightNumber">
        </div>
        <div :class="getClass">
           <div class="label">{{tr('Departure city')}}</div>
           <input class="form-control" type="text" v-model="flight.DepartureCity">
        </div>
        <div :class="getClass">
           <div class="label">{{tr('Arrival city')}}</div>
           <input class="form-control" type="text" v-model="flight.ArrivalCity">
        </div>
        <div class="form-group col-md-3" v-if="flight.FlightType!='DOMESTIC'">
           <div class="label">{{tr('Add Ticket')}}
              <i class="text-white ml-2">({{tr('Max. allowed size')}} {{maxSize}} Mb)</i>
           </div>
           <input type="file" class="form-control" @change="uploadTicket($event)" :id="'flight-' + i" multiple>
         </div>

    </form>
</template>

<script>
export default {
    name: 'reservation-form-flight',
    props: ['flight', 'langCode', 'i', 'maxSize'],
    data () {
        return {
            limit: 3000000,
        }
    },
    mounted () {
        if (this.maxSize) {
            this.limit = this.maxSize * 1000000;
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        checkFileSize (file) {
            if (file.size>this.limit) {
                let input = document.getElementById('flight-' + this.i);
                input.value = null;
                return;
            }
            return true;
        },
        async uploadTicket (event) {
            let input = event.target;
            if (input.files) {
                let err;
                for (let i = 0; i < input.files.length; i++) {
                    if (!this.checkFileSize(input.files[i])) {
                        err = true;
                    }
                }
                if (err) {
                    let input = document.getElementById('flight-' + this.i);
                    input.value = null;
                    alert(`${this.tr('Maximum allowed file size')} ${this.limit / 1000000} Mb`);
                } else {
                    this.$emit('uploadTicket', input.files, this.i, this.flight.FlightType);
                }
            }
        },
    },
    computed: {
        getClass () {
            if (this.flight.FlightType=='DOMESTIC') return 'form-group col-md-3';
            return 'form-group col-md-2';
        }
    }
}
</script>