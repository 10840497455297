import Vue from 'vue';
var EventBus = require('@/tools/event-bus').default;
import * as Sentry from '@sentry/browser';
import Router from '@/router/router';
import store from '@/store/store';
const login = importVueComp('components/tools', 'Login');
const logout = importVueComp('components/tools', 'Logout');
const home = importVueComp('components/pages', 'Home');
const modules = vueTools.importModule('modules', 'tools').default;
const abmTable = importVueComp('components/pages', 'AbmTable');
const report = importVueComp('components/pages', 'Report');
const pageBooking = importVueComp('components/pages', 'PageBooking');
const recordWindow = importVueComp('components/tools', 'RecordWindow');
const bookingWindow = importVueComp('components/booking', 'BookingWindow');
const templateWindow = importVueComp('components/booking', 'TemplateWindow');
const myTemplateWindow = importVueComp('components/booking', 'MyTemplateWindow');
const privateTemplateWindow = importVueComp('components/booking', 'PrivateTemplateWindow');
const packageWindow = importVueComp('components/booking', 'PackageWindow');
const supplierServiceWindow = importVueComp('components/supplier', 'SupplierServiceWindow');
const itineraryView = importVueComp('components/itinerary', 'ItineraryView');
const priceBreakdownView = importVueComp('components/booking', 'PriceBreakdownView');
const voucherView = importVueComp('components/voucher', 'VoucherView');
const bookingInvoice = importVueComp('components/documentation', 'BookingInvoice');
const bookingFinalView = importVueComp('components/documentation', 'BookingFinalView');
const reservationForm = importVueComp('components/reservationform', 'ReservationForm');
const optionals = importVueComp('components/service', 'Optionals');

import changePassword from '@/components/pages/ChangePassword';

Vue.use(Router);

let routes = [
        { path: '*', redirect: '/' },
        { path: '/', name: 'home', component: home, props: true },
        { path: '/login', component: login, name: 'logout' },
        { path: '/logout', component: logout, name: 'login' },
        { path: '/changepassword', name: 'change-password', component: changePassword },
        { path: '/abm/booking', name: 'abm-booking', component: pageBooking,
            props: {template: 'NONE', title: 'Quotations'}},
        { path: '/abm/allbooking', name: 'abm-all-booking', component: pageBooking,
            props: {title: 'Bookings'}},
        { path: '/abm/mybookings', name: 'abm-my-booking', component: pageBooking,
            props: {template: 'NONE', byUser: 'SALES', title: 'My Quotations'}},
        { path: '/abm/mysales', name: 'abm-my-sales', component: pageBooking,
            props: {template: 'NONE', byUser: 'SALES', title: 'My Sales', sales: true}},
        { path: '/abm/myfiles', name: 'abm-my-files', component: pageBooking,
            props: {template: 'NONE', byUser: 'OPER', title: 'My Files', sales: true}},
        { path: '/abm/mytemplates', name: 'abm-my-templates', component: pageBooking,
            props: {template: 'PRIVATE', byUser: 'SALES', title: 'My Templates'}},
        { path: '/abm/privatetemplates', name: 'abm-private-templates', component: pageBooking,
            props: {template: 'PRIVATE', title: 'Private Templates'}},
        { path: '/abm/templates', name: 'abm-templates', component: pageBooking,
            props: {template: 'PUBLIC', title: 'Templates'}},
        { path: '/abm/packages', name: 'abm-packages', component: pageBooking,
            props: {template: 'PACKAGE', title: 'Supplier Packages'}},
        { path: '/abm/sales', name: 'abm-sales', component: pageBooking,
            props: {template: 'NONE', byUser: false, title: 'Confirmed Sales', sales: true}},
        { path: '/abm/:table', name: 'abm', component: abmTable, props: true},
        { path: '/abm/booking/:id?', name: 'booking', component: bookingWindow, props: true},
        { path: '/abm/bookingversion/:version/:id?', name: 'view-booking-version', component: bookingWindow, props: true},
        { path: '/abm/mybookings/:id?', name: 'my-booking', component: bookingWindow, props: true},
        { path: '/abm/mysales/:id?', name: 'my-sale', component: bookingWindow, props: true},
        { path: '/abm/myfiles/:id?', name: 'my-file', component: bookingWindow, props: true},
        { path: '/abm/sales/:id?', name: 'sale', component: bookingWindow, props: true},
        { path: '/abm/template/:id?', name: 'template', component: templateWindow, props: true},
        { path: '/abm/mytemplates/:id?', name: 'my-template', component: myTemplateWindow, props: true},
        { path: '/abm/privatetemplate/:id?', name: 'private-template', component: privateTemplateWindow, props: true},
        { path: '/abm/package/:id?', name: 'package', component: packageWindow, props: true},
        { path: '/abm/:table/:id?', name: 'record', component: recordWindow, props: true},
        { path: '/abm/supplierservice/:supId/:table/:id?', name: 'sup-service', component: supplierServiceWindow, props: true},
        { path: '/abm/hotel/:hotelId/:supId/:table/:id?', name: 'hotel-service', component: supplierServiceWindow, props: true},
        { path: '/public/booking/:hash/:id', name: 'itinerary-view', component: itineraryView, props: true,
            meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}
        },
        { path: '/public/breakdown/:hash/:id', name: 'price-breakdown', component: priceBreakdownView, props: true,
            meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}
        },
        { path: '/public/bookingversion/:version/:hash/:id', name: 'booking-version',
            meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true},
            component: itineraryView, props: true },
        { path: '/public/voucher/:hash/:id', name: 'voucher', component: voucherView, props: true,
            meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}
        },
        { path: '/public/bookingfinal/:hash/:id', name: 'booking-final', component: bookingFinalView, props: true,
          meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}},
        { path: '/public/bookingprefinal/:hash/:id', name: 'booking-pre-final', component: bookingFinalView, props: true
          , public: true, meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}},
        { path: '/public/reservationform/:hash/:id/:langCode', name: 'reservation-form',
            meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true},
            component: reservationForm, props: true },
        { path: '/report/:report', name: 'report', component: report, props: true},
        { path: '/public/bookinginvoice/:hash/:id/:payment?', name: 'booking-invoice', component: bookingInvoice,
          meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}, props: true },
        { path: '/public/refundinvoice/:hash/:id/:payment?', name: 'refund-invoice', component: bookingInvoice,
          meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}, props: true },
        { path: '/public/service/optionals/:langCode', name: 'optionals', component: optionals, props: true,
            meta: {title: 'Nubbo', icon: 'custom_favicon.ico', public: true}
        },
]


let extras = api.EXTRAS;
let extraRoutes = [];
for (let i in extras) {
    let extra = extras[i];
    let customRoutes;
    try {
        customRoutes = require(`@/extra/${extra}/router.js`).default;
    } catch(ex) {
        //console.log(ex)
    }

    if (customRoutes) {
        if  (customRoutes.remove) {
            for (let route of customRoutes.remove) {
                let i = _.findIndex(routes, (r) => r.name == route.name && r.menu == route.menu);
                if (i>=0) {
                    routes.splice(i, 1);
                }
            }
        }
        routes = customRoutes.routes.concat(routes);
    }
}

const router = new Router({
    routes: routes
});


function setRouteHistory (path) {
    if (path.includes('bookingversion')) return;
    let p = path.split('/');
    if (p[1] && p[1]=='public') return;
    if (!parseInt(p[p.length-1])) return;

    let routerHistory = localStorage.getItem('router-history');
    if (!routerHistory) {
        routerHistory = JSON.stringify([]);
    }
    routerHistory = JSON.parse(routerHistory);
    let f = _.findIndex(routerHistory, (r) => r.path == path);
    if (f==-1) {
        if (routerHistory.length>=11) {
            routerHistory.splice(0, 1)
        }
    } else {
        routerHistory.splice(f, 1);
    }
    routerHistory.push({path, name: ''});
    localStorage.setItem('router-history', JSON.stringify(routerHistory));
    EventBus.$emit('update-history');
}

function checkAgency (to, from, next, user, isPublic) {
    if (isPublic) return true;
    if (user && user.AgencyId && to.path != '/logout') {
        api.get("/logout");
        api.logout();
        next('/login');
        return;
    }
    return true;

}

router.beforeEach(async function(to, from, next) {
    //console.log(from.path, to.path)
    let changePassword = '/changepassword';
    let modified = store.getters.modified || store.getters.isNew;
    if (modified && to.path != from.path && from.name != 'view-booking-version') {
        store.commit('setCallConfirmClose', true);
        store.commit('setToPath', to);
        return;
    }
    let isPublic = false;
    if (to.path.split('/')[1]=='public') {
        isPublic = true;
    }
    let currentUser = api.currentUser;
    if (!checkAgency(to, from, next, currentUser, isPublic)) return;
    if (isPublic) {
        next();
    } else if (to.path == '/login') {
        if (!currentUser) {
            currentUser = await api.getCurrentUser();
        }
        if (currentUser) {
            if (!checkAgency(to, from, next, currentUser, isPublic)) return;
            next('/');
        } else {
            next();
        }
    } else if (to.path == '/logout') {
        next();
        return;
    } else if (to.name == 'bookingview' || to.name == 'bookingversion') {
        let user = await api.getCurrentUser();
        if (user) {
            store.commit('setUser', user);
        }
        next();
    } else {
        if (!currentUser) {
            let user = await api.getCurrentUser();
            if (!checkAgency(to, from, next, user, isPublic)) return;
            if (!user) {
                next('/login');
                return;
            } else {
                store.commit('setUser', user);
                Sentry.setUser({"name": user.UserName});
                if (user && user.ChangePassword && to.path != changePassword) {
                    router.push(changePassword);
                    return;
                }
            }
        } else if (currentUser && currentUser.ChangePassword && to.path != changePassword) {
            if (to.path != changePassword) {
                if (router.currentRoute.path != changePassword) router.push(changePassword);
            }
            return;
        }
        let matchedPath = frontTools.getMatchedPath(to);
        let userAccess = tools.canAccess(api.currentUser, 'view', 'GET', matchedPath);
        if (to.path==changePassword) {
            next();
            return;
        } else if (to.path=='/') {
            next();
            return;
        } else if (userAccess) {
            let endpoint = {
                path: to.path,
                name: to.path.replace('/abm/', '').replace('/report/', ''),
                report: to.path.includes('report')
            }
            if (to.params && to.params.table) {
                endpoint.table = to.params.table;
            }
            let module = frontTools.findModule(endpoint);
            if (module) {
                if (!tools.canAccessModule(api.currentUser, module)) {
                    //if (router.currentRoute.name != 'home') next('/');
                    return;
                }
            }
            next();
            return;
        } else {
            //if (router.currentRoute.name != 'home') next('/');
            return;
        }
    }
});

router.afterEach((to, from) => {
    setRouteHistory(to.path)
    EventBus.$emit('check-marked', to.path);
    if (to.path.split('/')[1]=='public') {
        document.title = process.env.APP_NAME;
        const link = document.querySelector("[rel='icon']")
        link.setAttribute('href', to.meta.icon)
    }

})

export default router;
