<template>
    <div class="row mb-2">
        <div class="col-12 text-right mb-3" v-if="!payment">
            <b>INVOICE n° {{recordStore.InvoiceNumber}}/{{recordStore.InvoiceYear}}</b>
        </div>
        <div class="col-12">
            <span>Issue Date: {{date}}</span>
        </div>
        <div class="col-12" v-for="row of customerData">
            <span>{{row}}</span>
        </div>
        <div class="col-12 mb-3 mt-3">
            <span>Travel Date {{getDateFormat(recordStore.StartDate)}} – {{getDateFormat(recordStore.EndDate)}}</span>
        </div>
        <div class="col-12">
            <span>Booking / Pax name</span>
        </div>
        <div class="col-12" v-for="pax of paxNames">
            <span>{{pax}}</span>
        </div>
        <div class="col-12" v-for="row of passengerTaxNumber">
            <span>{{row}}</span>
        </div>
        <div class="col-6 mt-3">
            <span>Service Description</span>
        </div>
        <div class="col-6 mt-3 text-right">
            <span>Total Pax. {{totalPax}}</span>
        </div>
        <div class="col-12 mt-3">
          <table class="table table-bordered">
            <tbody>
              <tr v-for="(t, tId) of recordStore.bookingTotals" >
                <td>
                    {{getDateFormat2(recordStore.StartDate)}}
                </td>
                <td>
                    {{getDateFormat2(recordStore.EndDate)}}
                </td>
                <td>
                    <span v-for="(row, i) of customerText">
                        <br v-if="i>0">{{row}}
                    </span>
                </td>
                <td v-if="recordStore.QuoteBy=='PERSON'">
                    Individual
                    {{totalByBasePax(t.Base, t.pType, t.Quote.Price) | formatNumber({c: recordStore.PriceCurrency, s: 4, d: 0})}}
                     <span>x {{getPaxTotalRow(t)}} {{t.pType}}</span>
                </td>
                <td v-else-if="recordStore.QuoteBy=='QUANTITY' && totalsPerPerson && paxBaseByPaxType">
                    Individual price
                    {{getTotalInCurrency(totalsPerPerson[t.Base][t.pType].Price) | formatNumber({c: recordStore.PriceCurrency, s: 4, d: 0})}}
                    <span>x {{paxBaseByPaxType[t.Base][t.pType]}} {{t.pType}}</span>
                </td>
                <td class="text-right">
                    {{getTotalByBasePaxTypeWithFlights(t.Base, t.pType)  | formatNumber({c: recordStore.PriceCurrency, s: 4, d: 0})}}
                </td>
              </tr>
              <tr v-if="refund" >
                <td>
                </td>
                <td>
                </td>
                <td v-if="refundText.length>0">
                    <span v-for="(row, i) of refundText">
                        <br v-if="i>0">{{row}}
                    </span>
                </td>
                <td>
                </td>
                <td class="text-right">
                    {{totalRefund  | formatNumber({c: recordStore.PriceCurrency, s: 4, d: 0})}}
                </td>
              </tr>

            </tbody>
        </table>
        </div>
        <div class="col-12 mt-3 text-right">
            <span>Total: {{finalTotalWithFlights | formatNumber({c: recordStore.PriceCurrency, s: 4, d: 0})}}</span>
        </div>
        <div class="col-12 mt-3 text-right">
            <span>Receipt(s): {{totalReceipts | formatNumber({c: recordStore.PriceCurrency, s: 4, d: 0})}}</span>
        </div>
        <div class="col-12 mt-3 text-right">
            <span>Outstanding: {{pending | formatNumber({c: recordStore.PriceCurrency, s: 4, d: 0})}}</span>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-invoice-data',
    props: ['ready', 'payment', 'receipts'],
    data () {
        return {
            passengerList: null,
            bookingForm: null,
            totalsPerPerson: null,
            paxBaseByPaxType: null,
        }
    },
    async mounted () {
        this.getBookingForm()
        if (this.recordStore.QuoteBy == 'QUANTITY') {
            this.totalsPerPerson = Object.assign({}, this.recordStore.totalsPerPerson);
            this.paxBaseByPaxType = Object.assign({}, this.recordStore.paxBaseByPaxType);
        }
    },
    methods: {
        getDateFormat (d) {
            return moment(d).format('DD/MM');
        },
        getDateFormat2 (d) {
            return moment(d).format('DD/MM/YYYY');
        },
        async getPassengers () {
            if (!this.recordStore) return [];
            let paxList = [];
            let rf = await api.get('/api/bookingform/', {filters: JSON.stringify({BookingId: this.recordStore.id })})
            if (rf && rf.length==1) {
                for (let pax of rf[0].BookingFormPaxs) {
                    let paxName = frontTools.value(pax.FirstName) + ' ' + frontTools.value(pax.LastName);
                    paxList.push(paxName);
                }
            }
            this.passengerList = paxList;
        },
        async getBookingForm () {
            let rf = await api.get('/api/bookingform/bybookingid/' + this.recordStore.id)
            if (rf) {
                this.bookingForm = rf
            }
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        getPaxTotalRow (t) {
            return this.recordStore.getPaxBasePaxType(t.Base, t.pType);
        },
        totalByBasePax (base, pType, value) {
            let t = value;
            if (this.totalFlightsPax && this.totalFlightsPax[base] && this.totalFlightsPax[base][pType]) {
                t += this.totalFlightsPax[base][pType].Price;
            }
            let res = this.getTotalInCurrency(t);
            //if (!this.refund && this.hasRefund && this.recordStore.QuoteBy=='QUANTITY' && this.paxBaseByPaxType) res += -this.totalRefund / this.paxBaseByPaxType[base][pType];
            //if (!this.refund && this.hasRefund && this.getPaxTotalRow) res += -this.totalRefund / this.recordStore.getPaxBasePaxType(base, pType);
            if (this.hasRefund && this.recordStore.QuoteBy=='QUANTITY' && this.paxBaseByPaxType) res += -this.totalRefund / this.paxBaseByPaxType[base][pType];
            if (this.hasRefund && this.getPaxTotalRow) res += -this.totalRefund / this.recordStore.getPaxBasePaxType(base, pType);
            return res;
        },
        getTotalByBasePaxTypeWithFlights (base, pType) {
            let res = this.totalByBasePaxTypeWithFlights[base][pType].Price;
            if (this.refund) {
                //res += this.totalRefund;
            }
            return res;
        } 
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        hasRefund () {
            if (this.receipts) {
                return _.find(this.receipts, (r) => r.ReceiptType == 'REFUND')
            };

        },
        refund () {
            if (this.receipts) {
                let r =  _.find(this.receipts, (r) => r.id == this.payment)
                if (r && r.ReceiptType == 'REFUND') return r;
            };
        },
        date () {
            if (this.recordStore && this.recordStore.InvoiceDate) {
                return moment(this.recordStore.InvoiceDate).format("DD MMM YYYY");
            }
            return moment().format("DD MMM YYYY");
        },
        receipt () {
            if (this.receipts) {
                let r =  _.find(this.receipts, (r) => r.id == this.payment)
                if (r && r.ReceiptType == 'REFUND') return false;
                return r;
            };
        },
        type () {
            if (this.receipt) return this.receipt.ReceiptType;
        },
        customerText () {
            let t = 'Tours, transfers and hotels stays';
            if (this.receipt && this.receipt.CustomerText) t = this.receipt.CustomerText;
            if (!this.receipt && this.recordStore.CustomerText) t = this.recordStore.CustomerText;
            return t.split('\n')
        },
        refundText () {
            let t = 'REFUND';
            if (this.refund && this.refund.CustomerText) t = this.refund.CustomerText;
            return t.split('\n')
        },
        customerData () {
            if (this.recordStore && this.recordStore.AgencyId) {
                let res = [];
                if (this.recordStore.Agency.CompanyName) res.push(this.recordStore.Agency.CompanyName);
                if (this.recordStore.Agency.Address) res.push(this.recordStore.Agency.Address);
                if (this.recordStore.Agency.TaxNumber) res.push(this.recordStore.Agency.TaxNumber);
                if (res.length>0) return res;
            }
            if (this.recordStore && this.recordStore.InvoiceData) {
                return this.recordStore.InvoiceData.split('\n');
            }
            return [];
        },
        passengerTaxNumber () {
            if (this.recordStore && this.recordStore.AgencyId) {
                let res = [];
                return res;
            }
            let res = [];
            if (this.receipt && this.receipt.Address) {
                res.push(this.receipt.Address);
            } else if (this.bookingForm && this.bookingForm.Address) {
                res.push(this.bookingForm.Address);
            }
            if (this.receipt && this.receipt.TaxNumber) {
                res.push(this.receipt.TaxNumber);
            } else if (this.bookingForm && this.bookingForm.TaxNumber) {
                res.push(this.bookingForm.TaxNumber);
            }
            return res;
        },
        paxNames () {
            if (this.passengerList) {
                let res = [];
                for (let pax of this.passengerList) {
                    res.push(pax);
                }
                if (res.length>0) return res;
            }
            if (this.recordStore) return [this.recordStore.PaxName];
        },
        totalRefund () {
            let res = 0;
            if (this.receipts) {
                this.receipts.forEach(r => {
                    if (r.ReceiptType == 'REFUND') res += r.Amount;
                });
            }
            return res;
        },
        totalPax () {
            if (this.recordStore) {
                let res = {ADT: 0, CHD: 0};
                for (let t of this.recordStore.bookingTotals) {
                    let pax = this.recordStore.getPaxBasePaxType(t.Base, t.pType);
                    if (pax) res[t.pType] += pax;
                }
                let r = res.ADT + ' ADT';
                if (res.CHD) r += ', ' + res.CHD + ' CHD';
                return r;
            }
            if (this.passengerList) return this.passengerList.length  + ' ADT';
        },
        totalByBasePaxTypeWithFlights () {
            if (this.recordStore) {
                return this.recordStore.totalByBasePaxTypeWithFlights;
            }
        },
        finalTotalWithFlights () {
            if (this.recordStore) {
                let res = this.getTotalInCurrency(this.recordStore.finalTotalWithFlights);
                if (this.hasRefund && !this.refund) {
                    res += -this.totalRefund;
                }
                return res;
            }
            return 0;
        },
        totalFlightsPax () {
            if (this.recordStore) {
                return this.recordStore.totalFlightsNotIncludedAll;;
            }
        },
        totalReceipts () {
            let res = 0;
            if (this.type == 'DEPOSIT') return res;
            for (let row of this.receipts) {
                if (this.type == 'BALANCE' && row.ReceiptType == 'BALANCE') continue;
                if (!this.refund && row.ReceiptType == 'REFUND') continue;
                res += travelTools.currencyConvert(row.CurrencyId, this.recordStore.PriceCurrencyId,
                    parseFloat(row.Amount), this.recordStore.CurrencyRates);
            }
            return res;
        },
        pending () {
            return Math.abs(Math.round(this.finalTotalWithFlights - this.totalReceipts, 0));
        }

    },
    watch: {
        ready () {
            if (this.ready) {
                this.getPassengers();
                this.setReceipts();
            }
        }
    }
}
</script>

<style scoped>
  .table-bordered {
      border: 2px solid #000;
  }
  .table-bordered td {
      border: 2px solid #000;
  }
</style>