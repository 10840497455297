<template>
  <div class="modal fade" id="paymentsHelp" tabindex="-1" role="dialog" aria-labelledby="paymentsHelp"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
              <div class="col-12 font-weight-bold h5 border-bottom pb-3">
                  Balance = To Pay (<i>invoiced</i>) + Credits - Paid
              </div>
              <!--div class="col-12 font-weight-bold h5 border-bottom pb-3">
                  <span class="font-weight-bold">Total cost of services</span> = {{totalCost | formatNumber({c: bookingCurrency})}} {{bookingCurrency}}
              </div-->
              <div v-for="currencyId of currencies" class="border-bottom mt-2 col-12">
                  <div class="col-12 font-weight-bold h5">
                      Values in {{currencyId}}
                  </div>
                  <div class="col-12 h5">
                      <span class="font-weight-bold">To Pay</span> = {{toPay[currencyId] | formatNumber({c: currencyId})}}
                      <span class="cursor-pointer text-small ml-3" @click="detailToPay = true" v-if="toPay[currencyId]">show detail</span>
                  </div>
                  <div v-if="detailToPay" class="col-12 pl-5">
                      <div class="row" v-for="(row, rowNr) of payments" v-if="(supplierId==row.SupplierId || !supplierId) && row.Amount">
                          <div class="col-1">
                            <label v-if="row.DueDate">{{getDate(row.DueDate)}}</label>
                          </div>
                          <div class="col-3 text-right">
                            <label>{{row.Amount | formatNumber({c: row.Currency})}} {{row.CurrencyId}}</label>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 h5">
                      <span class="font-weight-bold">Credits</span> = {{credits[currencyId] | formatNumber({c: currencyId})}}
                      <span class="cursor-pointer text-small ml-3" @click="detailCredits = true" v-if="credits[currencyId]">show detail</span>
                  </div>
                  <div v-if="detailCredits" class="col-12 pl-5">
                      <div class="row" v-for="(row, rowNr) of supplierCredits" v-if="(supplierId==row.SupplierId || !supplierId)">
                          <div class="col-1">
                            <label>{{getDate(row.TransDate)}}</label>
                          </div>
                          <div class="col-3 text-right">
                            <label>{{row.Amount | formatNumber({c: row.Currency})}} {{row.CurrencyId}}</label>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 h5">
                      <span class="font-weight-bold">Paid</span> = {{paid[currencyId] | formatNumber({c: currencyId})}}
                      <span class="cursor-pointer text-small ml-3" @click="detailPaid = true" v-if="paid[currencyId]">show detail</span>
                  </div>
                  <div v-if="detailPaid" class="col-12 pl-5">
                      <div class="row" v-for="(row, rowNr) of payments" v-if="(supplierId==row.SupplierId || !supplierId) && row.PaymentAmount">
                          <div class="col-1">
                            <label v-if="row.DueDate">{{getDate(row.PaymentDate)}}</label>
                          </div>
                          <div class="col-3 text-right">
                            <label>{{row.PaymentAmount  | formatNumber({c: row.PaymentCurrency})}} {{row.PaymentCurrencyId}}</label>
                          </div>
                      </div>
                  </div>

                  <div class="col-12 font-weight-bold h5">
                      Balance =
                      {{toPay[currencyId] | formatNumber({c: currencyId})}} +
                      {{credits[currencyId] | formatNumber({c: currencyId})}} -
                      {{paid[currencyId] | formatNumber({c: currencyId})}} =
                      {{pending[currencyId] | formatNumber({c: currencyId})}}
                  </div>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'payments-help',
    mixins: [escape],
    props: ['show', 'pending', 'toPay', 'paid', 'credits', 'payments', 'supplierCredits', 'supplierId', 'totalCost', 'bookingCurrency'],
    mounted () {
        $('#paymentsHelp').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    data () {
        return {
            detailPaid: false,
            detailToPay: false,
            detailCredits: false,
        }
    },
    methods: {
        close () {
            this.$emit('update:show', false);
            $('#paymentsHelp').modal('hide');
        },
        getDate (d) {
            return tools.getDate(d);
        },
    },
    computed: {
        currencies () {
            return Object.keys(this.toPay);
        }
    }
}
</script>

<style scoped>
.text-small {
    color: blue;
}
</style>