
<script>
const expenseListReport = importVueComp('components/reports', 'ExpenseListReport', 'custom');
export default {
    name: 'custom-expense-list-report',
    mixins: [expenseListReport],
    data () {
        let r = expenseListReport.data();
        let currency = _.find(r.fields, (c) => c.name == 'CurrencyId')
        if (currency) {
            currency.required = false;
            currency.addBlank = true;
        }
        r.fields.push({ name: 'ToCurrencyId', label: 'Convert to', editor: 'select', optionLabels: 'id', relation: 'currency'});
        return {
            fields: r.fields
        }
    },
    methods: {
    }
}
</script>

