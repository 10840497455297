<template>
  <div class="modal fade" id="change-markup" tabindex="-1" role="dialog" aria-labelledby="change-markup"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content form-material">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Change Markup')}}</h5>
        </div>
        <div class="modal-body form-group form-default form-row">
          <select-input
              class="col-6"
              label="From Value"
              :fieldOptions="options"
              :currentValue.sync="fromValue"
          ></select-input>
          <div class="col-6 form-group form-default">
            <input type="number" v-model="toValue" class="form-control text-right">
              <span class="form-bar"></span>
              <label class="float-label">{{tr('To Value')}}</label>
            </input>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">{{tr('Done')}}</button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
import { mapState } from 'vuex';
export default {
    name: 'change-markup',
    mixins: [escape],
    props: ['show'],
    data () {
        return {
            fromValue: null,
            toValue: null
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        options () {
            let res = [];
            for (let key in this.markups) {
                res.push({label: parseFloat(key).toFixed(2), value: key})
            }
            return res;
        },
        markups () {
            let res = {};
            for (let baseId in this.recordStore.BaseQuotes.bases) {
                for (let day of this.recordStore.BookingDays) {
                    for (let s of day.BookingDayServices) {
                        if (!s.Quote) continue;
                        if (!s.Quote.QuoteList) continue;
                        if (!s.Quote.QuoteList[baseId]) continue;
                        for (let pType of this.recordStore.BaseQuotes[baseId].paxTypes) {
                            if (!s.Quote.QuoteList[baseId][pType]) continue;
                            if (!s.Quote.QuoteList[baseId][pType].Cost) continue;
                            if (!s.Quote.QuoteList[baseId][pType].Markup) continue;
                            if (!res[s.Quote.QuoteList[baseId][pType].Markup]) {
                                res[s.Quote.QuoteList[baseId][pType].Markup] = true;
                            }
                        }
                    }
                    for (let hotel of day.BookingDayHotels) {
                        for (let room of hotel.BookingDayRooms) {
                            if (!room.QuoteList) continue;
                            if (!room.QuoteList[baseId]) continue;
                            if (room.QuoteList[baseId].Markup) {
                                if (!res[room.QuoteList[baseId].Markup]) {
                                    res[room.QuoteList[baseId].Markup] = true;
                                }
                            }
                        }
                    }
                }
            }
            return res;
        },
    },
    mounted () {
        $('#change-markup').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        save () {
            this.$emit('save', this.fromValue, this.toValue);
            this.close();
        },
        close () {
            this.$emit('update:show', false);
            $('#change-markup').modal('hide');
        },
    },
}
</script>
