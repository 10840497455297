<template>
    <div id="documentation-mail-html">
        <div marginwidth="0" marginheight="0" leftmargin="0" topmargin="0" style="background-color:#ffffff;  font-family: Montserrat, OpenSans, Arial, sans-serif; color:#ffffff; text-decoration:none; margin:0; padding:0; min-width: 100%; -webkit-text-size-adjust:none; -ms-text-size-adjust:none;">

            <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
                <tbody>
                    <tr>
                        <td align="center" height="20">
                            <table align="center" style="width: 100%; min-height: 180px; max-width: 800px" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td width="100%" align="center">
                                            <a href="#">
                                                <img src="https://raja-app.s3.amazonaws.com/prod/documentation/logo-full.png" width="100%"/></img>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table width="100%" align="center" cellpadding="0" cellspacing="0" border="0" bgcolor="#Fff" class="" style="border-top: 20px solid #F0F0F0;">
                <tbody>
                    <tr>
                        <td align="center">
                            <slot name="body"></slot>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" height="40">
                        </td>
                    </tr>
                </tbody>
            </table>

            <table width="100%" align="center" cellpadding="0" cellspacing="0" border="0" class="" bgcolor="#15361B">
                <tbody>
                    <tr>
                        <td height="20"></td>
                    </tr>
                <tr>
                    <td align="center">
                        <table-footer></table-footer>
                    </td>
                </tr>
                    <tr>
                        <td height="20"> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import tableFooter from '@/extra/custom/components/documentation/TableFooter';
import tableHeader from '@/extra/custom/components/documentation/TableHeader';
export default {
    name: 'documentation',
    props: ['text', 'elements'],
    components: {
        'table-footer': tableFooter,
        'table-header': tableHeader
    },
}
</script>




<style scoped>

a {
text-decoration: none;
}

.ReadMsgBody { width: 100%; background-color: #D2D7D3; }
.ExternalClass { width: 100%; background-color: #D2D7D3; }
.a { text-decoration:none; }
body { width: 100%; background-color: #d8eaf8; margin: 0; padding: 0; -webkit-font-smoothing: antialiased; font-family:OpenSans ,Arial, Helvetica Neue, Helvetica, sans-serif }
@-ms-viewport{ width: device-width; }


@media only screen and (max-width: 639px){
    .wrapper{ width:100%;  padding: 0 !important; }
}

@media only screen and (max-width: 480px){
    .centerClass{ margin:0 auto !important; }
    .imgClass{ width:100% !important; height:auto; }
    .img{ text-align:center}
    .hide{ display:none;}
    .wrapper{ width:100%; padding: 0 !important; }
    .header{ width:100%; padding: 0 !important; background-image: url(http://placehold.it/320x400) !important; }
    .container{ width:300px;  padding: 0 !important; }
    .box{ width:250px;  padding: 0 !important; }
    .mobile{ width:300px; display:block; padding: 0 !important; text-align:center !important;}
    .mobile50{ width:300px; padding: 0 !important; text-align:center; }
    *[class="mobileOff"] { width: 0px !important; display: none !important; }
    *[class*="mobileOn"] { display: block !important; max-height:none !important; }
}

.MsoNormal {font-family:Montserrat, OpenSans ,Arial, Helvetica Neue, Helvetica, sans-serif !important;}

</style>

