<template>
    <div v-if="readMore || textLength.start < limit">
        <text-rows-style
            class="italic text-justify"
            tag="p"
            :text="text"
            v-if="s && ifText && !edit"
            @edit="setEdit"
        ></text-rows-style>
        <text-area-edit-style
            tag="p"
            v-else-if="s && edit"
            :text.sync="s.Description"
        ></text-area-edit-style>

        <p v-if="s.Service && s.Service.ServiceClass && s.Service.ServiceClass.ShowLanguage">
            <strong v-if="!languageSupplement">
                <i>{{tr('This service includes an english speaking guide')}}</i>
            </strong>
        </p>
        <p v-if="s.Service && s.Service.ServiceClass && s.Service.ServiceClass.ShowDriver">
            <strong v-if="!languageSupplement">
                <i>{{tr('This service includes an english speaking driver')}}</i>
            </strong>
        </p>

        <p v-if="!recordStore.typeOfServices[day.DayNr] && s.Service.ServiceType && s.Description && s.Description.length>0 && ifText && !s.Service.NotPrice">
            <i>
                {{tr('Type of service')}}: {{tr(s.Service.ServiceType.Name)}}
            </i>
        </p>
        <br v-if="!recordStore.typeOfServices[day.DayNr] && s.Service.ServiceType && s.Description && s.Description.length>0">

    </div>
</template>

<script>
const itineraryBodyService = importVueComp('components/itinerary', 'ItineraryBodyService', 'custom');
import { mapState } from "vuex";

export default {
    name: 'custom-itinerary-body-service',
    mixins: [itineraryBodyService],
    computed: {
        languageSupplement () {
            for (let s of this.day.BookingDayServices) {
                if (s.Service && s.Service.ServiceClass && s.Service.ServiceClass.LanguageSupplement) {
                    return s.Service.Name;
                }
            }
        }
    }
}
</script>


