<template>
    <div>
        <div v-if="!processing">
           <documentation v-if="recordStore && recordStore.Documentation && recordStore.Documentation.Text && recordStore.Documentation.Elements">

            <table-body
                :id="recordStore.id"
                :text.sync="recordStore.Documentation.Text"
                :elements="recordStore.Documentation.Elements"
                :attachments="recordStore.Documentation.Attachments"
                slot="body">
            </table-body>

            </documentation>
        </div>
    </div>
</template>

<script>
import documentation from '@/extra/custom/components/documentation/Documentation';
import tableBody from '@/extra/custom/components/documentation/TableBody';
import { mapState } from 'vuex';
export default {
  name: 'documentation-view',
  props: ['id', 'hash'],
  components: {
    'documentation': documentation,
    'table-body': tableBody
  },
  computed: {
    ...mapState({
      recordStore: state => state.main.record,
      processing: state => state.main.processing,
    }),
  },
  async mounted () {
    api.setProcessing(true)
    let model = await api.importMixinModule('booking', 'model');
    let r = await api.get('/public/api/booking/' + this.hash + '/' + this.id);
    if (r) {
      r._public = true;
      let record = await new model({record: r});
      this.$store.commit('initRecord', {record});
      api.setProcessing(false)
    }
  },
}
</script>
