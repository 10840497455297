<template>
    <table width="600" cellpadding="0" cellspacing="0" border="0" class="container">
        <tbody>
            <tr>
                <td width="400" height="130" align="center" class="mobile" style="font-family: OpenSans, Arial, sans-serif; font-size:12px; line-height:24px;">
                    <table width="380" border="0" align="center" cellpadding="0" cellspacing="0" class="container">
                        <tbody>
                            <tr>
                                <td align="center" height="120">
                                    <table align="center" width="150" border="0" cellspacing="0" cellpadding="0" class="container" style="height: 100%">
                                        <table-footer-body></table-footer-body>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import tableFooterBody from '@/extra/custom/components/documentation/TableFooterBody';
export default {
    name: 'table-footer',
    components: {
        'table-footer-body': tableFooterBody
    },
}
</script>

