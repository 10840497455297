
<template>
    <tbody>
        <tr>
            <td align="center" height="20">
                <table align="center" style="max-width: 300px; width: 100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                        <tr>
                            <td width="100%" align="center">
                                <a href="#">
                                    <img src="https://raja-app.s3.amazonaws.com/prod/documentation/logo-voucher.png" width="100%"/></img>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</template>


<script>
export default {
    name: 'table-footer-body',
}
</script>

<style scoped>

footer {
  background: #15361B !important;
  color: #fff !important;
}
</style>

