<template>
    <div class="col-12" v-if="recordStore.anyQuoteSelected">
        <div class="box">
            <div class="box-header">
                <h3 v-if="recordStore.ShowPriceBy=='TOTAL_TRIP'">{{tr('Total Trip Price')}}</h3>
                <h3 v-else>{{tr('Price per Person')}}</h3>
            </div>
            <div class="col-12 py-0" v-for="(hotelCategory, hotelCategoryId) of hotelCategoriesNames" :key="hotelCategoryId">
                <div class="col-12" v-if="showCategory(hotelCategoriesNames, hotelCategoryId)">
                    <span v-if="recordStore.Language.Code!='en'">{{tr('Accommodation')}}</span>
                    <span> {{hotelCategory}}</span>
                    <span v-if="recordStore.Language.Code=='en'">{{tr('Accommodation')}}</span>
                </div>
                <div v-if="recordStore.ShowPriceBy=='TOTAL_TRIP'">
                  <div class="row py-0 d-flex align-items-center justify-content-center" v-for="(t, tId) of recordStore.bookingTotalsTrip" :key="tId">
                      <span class="price" v-if="recordStore.SumByType=='ONE_TOTAL'">
                          {{t.Price | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                      </span >
                      <span class="h5 mb-0 col-6" v-if="recordStore.SumByType=='QUOTE'">
                          {{t.PaxText}} {{t.Comment}}
                      </span>
                      <span class="price col-6" v-if="recordStore.SumByType=='QUOTE'">
                          {{t.Price | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                      </span>
                      <span class="price" v-if="recordStore.SumByType=='HOTEL_CATEGORY' && isHotelCategory(t, hotelCategoryId)">
                          {{t.Price | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                      </span>
                  </div>
                </div>
                <div v-else>
                  <div v-for="(t, tId) of bookingTotals" class="row py-3 justify-content-center" :key="tId" v-if="isHotelCategory(t, hotelCategoryId)">
                      <div class="col-12 row" v-if="recordStore.QuoteBy=='QUANTITY' & recordStore.ShowPriceBy=='PERSON' && showBySingleSup(tId, t.pType) && totalsPerPerson">
                          <div v-for="room of totalsPerPerson[tId][t.pType].Rooms" class="col-12 row">
                              <div class="col-6 d-flex align-items-center justify-content-center">
                                  <div v-if="t.Room" class="h5">
                                      {{tr(t.pType)}} <span v-if="!recordStore.HideRoomType">{{getRoomTypeNames2(room.Room.Name)}}</span>
                                      <span v-if="t.Quote.Comment"> - {{t.Quote.Comment}}</span>
                                  </div>
                                  <div v-else class="h5 mb-0">{{tr(t.pType)}} <br>
                                      <p v-if="t.Quote.Comment">{{t.Quote.Comment}}</p>
                                  </div>
                              </div>
                              <div class="col-6">
                                <div class="price">
                                   {{getTotalInCurrency(room.Price) | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                                </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-12 row" v-else>
                          <div class="col-6 d-flex align-items-center justify-content-center">
                              <div v-if="t.Room" class="h5">
                                  {{tr(t.pType)}} <span v-if="!recordStore.HideRoomType">{{getRoomTypeNames(t)}}</span>
                                  <span v-if="t.Quote.Comment"> - {{t.Quote.Comment}}</span>
                              </div>
                              <div v-else class="h5 mb-0">{{tr(t.pType)}} <br>
                                  <p v-if="t.Quote.Comment">{{t.Quote.Comment}}</p>
                              </div>
                          </div>
                          <div class="col-6" v-if="t.Quote.Price">
                            <div class="price" v-if="recordStore.Agency && recordStore.Agency.Code == 'IND-INF' && t.Quote.AgencyTotal">
                               {{t.Quote.AgencyTotal | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                            </div>
                            <div class="price" v-else-if="recordStore.QuoteBy=='QUANTITY' & recordStore.ShowPriceBy=='PERSON'">
                               {{getTotalInCurrency(totalsPerPerson[t.Base][t.pType].Price) | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                            </div>
                            <div class="price" v-else>
                               {{getTotalInCurrency(t.Quote.Price) | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="row py-3 justify-content-center" v-if="recordStore.hasVariants">
                      <div class="col-12 row">
                          <div v-for="row of totalVariants" class="col-12 row">
                              <div class="col-6 d-flex align-items-center justify-content-center">
                                  <div class="h5">
                                      <span v-if="row.Name"> {{row.Name}}- </span>
                                      {{tr(row.pType)}} <span v-if="!recordStore.HideRoomType">{{getRoomTypeNames2(roomsTypeNamesByVariant[row.baseId][row.variantId][row.pType])}}</span>
                                  </div>
                              </div>
                              <div class="col-6" v-if="row.Quote.Price">
                                <div class="price" v-if="recordStore.Agency && recordStore.Agency.Code == 'IND-INF' && row.AgencyTotal">
                                   {{row.AgencyTotal | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                                </div>
                                <div class="price" v-else>
                                   {{getTotalInCurrency(row.Quote.Price) | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <h5 v-if="finalTotalFlightsNotIncluded && recordStore.ShowPriceBy=='TOTAL_TRIP'" class="mt-4">
                    <span>
                        {{tr('Total Flights')}}
                    </span>
                    <span>
                        {{finalTotalFlightsNotIncluded | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                    </span>
                </h5>
                <div v-if="recordStore.ShowPriceBy!='TOTAL_TRIP'" class="mt-4">
                    <h5 v-for="(flight, tFlight) of recordStore.flightsNotIncluded" :key="tFlight">
                        <span v-if="flight.FlightType=='LOCAL'">
                            {{tr('Internal Flights')}} {{tr(flight.pType)}}
                            <p v-if="flight.Comment">{{flight.Comment}}</p>
                        </span>
                        <span v-if="flight.FlightType=='INTERNATIONAL'">
                            {{tr('International Flights')}} {{tr(flight.pType)}}
                            <p v-if="flight.Comment">{{flight.Comment}}</p>
                        </span>
                        <span>
                            {{getTotalInCurrency(flight.Price) | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                        </span>
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const itineraryPrices = importVueComp('components/itinerary', 'ItineraryPrices', 'custom');
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-prices',
    mixins: [itineraryPrices],
    data () {
        return {
            totalByPaxVariant: null,
            roomsTypeNamesByVariant: null
        }
    },
    mounted () {
        if (this.recordStore) {
            this.totalByPaxVariant = this.recordStore.totalByPaxVariant;
            this.roomsTypeNamesByVariant = this.recordStore.roomsTypeNamesByVariant;
        }
    },
    computed: {
        anySingleSup () {
            return this.recordStore.anySingleSup;
        },
        singleSupplementValue () {
            return this.recordStore.singleSupplementValue;
        },
        totalsPerPerson () {
            return this.recordStore.totalsPerPerson;
        },
        totals () {
            return this.recordStore.totals;
        },
        bookingTotals () {
            return _.filter(this.recordStore.bookingTotals, (r) => {
                if (this.recordStore.isVariant(r.Base)) return false;
                return true;
            });
        },
        totalVariants () {
            let res = [];
            if (!this.totalByPaxVariant) return res;
            for (let baseId in this.totalByPaxVariant) {
                for (let variantId in this.totalByPaxVariant[baseId]) {
                    for (let pType in this.totalByPaxVariant[baseId][variantId]) {
                        res.push({
                            baseId, variantId, pType,
                            Name: this.recordStore.QuoteVariants[baseId][variantId].Name,
                            Quote: this.totalByPaxVariant[baseId][variantId][pType],
                            AgencyTotal: this.recordStore.QuoteVariants[baseId][variantId].AgencyTotal[pType]
                        })
                    }
                }
            }
            return res;
        }
    },
    methods: {
        showCategory (hotelCategoriesNames, hotelCategoryId) {
            if (hotelCategoryId == '__unique_category__') return;
            if (Object.keys(hotelCategoriesNames).length>0 && Object.keys(hotelCategoriesNames)[0]!='_no_hotel_category_') return true;
        },
        showBySingleSup (baseId, pType) {
            if (this.singleSupplementValue && this.singleSupplementValue[baseId] && this.totalsPerPerson && this.totalsPerPerson[baseId] &&
              this.totalsPerPerson[baseId][pType] && Object.keys(this.totals[baseId][pType].Rooms).length > 1) return true;
        },
        getRoomTypeNames2 (name) {
            let rooms = [name];
            let res = [];
            for (let r of rooms) {
                res.push(tr(r))
            }
            if (this.recordStore.Language.Code!='en') return this.tr('Room') + ' ' + res.join(' / ');
            return res.join(' / ') + ' ' + this.tr('Room');
        },
        isVariant (baseId) {
            return this.recordStore.isVariant(baseId);
        }

    },
}
</script>


