<template>
      <report-window endpoint="/custom/reports/pax_calendar"
        v-if="ready"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
        :slotsFields="[]"
        :current.sync="current"
        :columnsClasses="columnsClasses"
        :beforeRender="beforeRender"
        :componentFields="componentFields"
        :displayFilterValues="displayFilterValues"
        :headerSticky="true"
      ></report-window>
</template>

<script>
import Vue from 'vue';
let calendarRow = Vue.component('calendar-row', {
    props: ['row', 'name'],
    template: `
        <div class="d-flex flex-column">
            <div class="border-bottom" v-if="cities"><b><i>{{cities}}</i></b></div>
            <div class="border-bottom" v-if="hotel"><b>Hotel: </b>{{hotel}}</div>
            <div class="border-bottom" v-if="flight">{{flight}}</div>
            <div class="calendar-services">
                <span v-for="(s, i) of services">
                    {{s.name}}<b v-if="s.supplier"> ({{s.supplier}})</b>
                    <span v-if="i < services.length-1">;</span>
                </span>
            </div>
        </div>
    `,
    computed: {
        flight () {
            if (!this.row) return '';
            return this.row.flight;
        },
        cities () {
            if (!this.row) return '';
            if (this.row.city) return `${this.row.city.join(' / ')}`;
        },
        hotel () {
            if (!this.row) return '';
            if (this.row.hotel) return this.row.hotel;
        },
        services () {
            if (!this.row) return '';
            if (this.row.services) {
                let list = [];
                for (let row of this.row.services) {
                    if (!list[row.supplier]) list[row.supplier] = [];
                    list[row.supplier].push(row.name);
                }
                let res = [];
                for (let sup in list) {
                    res.push({
                        name: list[sup].join('; '),
                        supplier: sup,
                    })
                }
                return res;
            };
            return []
        }
    }
})


import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'pax-calendar-report',
    props: ['options'],
    components: {
        ReportWindow,
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            ready: false,
            current: {},
            fields: [
                { name: 'FromDate', editor: 'date', label: 'From', required: true},
                { name: 'ToDate', editor: 'date', label: 'To', required: true},
                { name: 'SalesUserId', label: 'Seller', editor: 'select',
                    optionLabels: 'UserName', relation: 'user', addBlank: true,
                    filters: (row) => {
                        if (row.Closed) return false;
                        if (row.Department && row.Department.Sales) return true;
                    }
                },

            ],
            title: 'Passengers Calendar',
            headers: {},
            templates: [],
            columnsClasses: {
                PaxName: 'head-col',
            },
            componentFields: [],
            displayFilterValues: {},
        }
    },
    methods: {
        beforeRender (report) {
            if (report.serverColumnsHeader) {
                for (let column of report.serverColumnsHeader) {
                    if (column != 'PaxName') {
                        let f = _.find(this.componentFields, (r) => r.name == column);
                        if (!f) {
                            this.componentFields.push({
                                name: column, component: calendarRow
                            })
                            this.columnsClasses[column] = 'calendar-column';
                        }
                        if (!this.displayFilterValues[column]) {
                            this.displayFilterValues[column] = (data) => {
                                if (!data) return '';
                                let res = [];
                                if (data.city) res.push(data.city.join(' '));
                                if (data.hotel) res.push(data.hotel);
                                if (data.services) {
                                    for (let row of data.services) {
                                        res.push(row.name);
                                        res.push(row.supplier);
                                    }
                                };
                                return res.join(' ');
                            }
                        }

                        this.headers[column] = moment(column).format('DD MMM');
                    }
                }
                this.componentFields = Object.assign([], this.componentFields);
                this.columnsClasses = Object.assign({}, this.columnsClasses);
                this.headers = Object.assign({}, this.headers);
            }
        },
    }
}
</script>

