<script>
const bookingQuoteSummary = importVueComp('components/booking', 'BookingQuoteSummary', 'custom');
export default {
    name: 'custom-booking-quote-summary',
    mixins: [bookingQuoteSummary],
    methods: {
        setSingleSupplementValue () {
            this.singleSupplementValue = Object.assign({}, this.recordStore.singleSupplementValue);
            /*for (let baseId in this.singleSupplementValue) {
                if (!this.recordStore.Totals[baseId]) continue;
                if (!this.recordStore.Totals[baseId].ADT) continue;
                if (this.recordStore.Totals[baseId].ADT.SetPriceSingleSup) {
                    this.singleSupplementValue[baseId] = this.recordStore.Totals[baseId].ADT.SetPriceSingleSup;
                }
            }*/
        },
    }
}
</script>

